var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"elevation-3",attrs:{"dense":"","headers":_vm.headers,"items":_vm.history,"sort-by":"version","sort-desc":true,"hide-default-footer":true},scopedSlots:_vm._u([{key:"item.version",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"data-table-items"},[_vm._v(" "+_vm._s(item.version)+" ")])]}},{key:"item.credentials.password",fn:function(ref){
var item = ref.item;
return [_c('custom-text',{attrs:{"textValue":item.credentials.password,"tooltip":'Copy Password',"isSoloInverted":true,"customClass":'data-table-items',"allowCopy":true,"isReadonly":true,"hideDetails":true}})]}},{key:"item.credentials.user_name",fn:function(ref){
var item = ref.item;
return [_c('custom-text',{attrs:{"textValue":item.credentials.user_name,"tooltip":'Copy User Name',"isSoloInverted":true,"customClass":'data-table-items',"allowCopy":true,"isReadonly":true,"hideDetails":true}})]}},{key:"item.credentials.notes",fn:function(ref){
var item = ref.item;
return [_c('v-textarea',{staticClass:"data-table-items",attrs:{"dense":true,"readonly":"","outlined":"","no-resize":"","rows":"1","hideDetails":true,"value":item.credentials.notes}})]}},{key:"item.epoch_time",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"data-table-items"},[_vm._v(" "+_vm._s(new Date(item.epoch_time * 1000))+" ")])]}},{key:"no-data",fn:function(){return [_c('v-btn',{attrs:{"color":"primary"}},[_vm._v(" No password history for selected app ")])]},proxy:true}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }