<template>
  <v-data-table
    class="elevation-3"
    dense
    :headers="headers"
    :items="history"
    sort-by="version"
    :sort-desc="true"
    :hide-default-footer="true"
  >
    <!-- Data Tables items styling-->
    <template v-slot:[`item.version`]="{ item }">
      <span class="data-table-items">
        {{ item.version }}
      </span>
    </template>
    <template v-slot:[`item.credentials.password`]="{ item }">
      <custom-text
        :textValue="item.credentials.password"
        :tooltip="'Copy Password'"
        :isSoloInverted="true"
        :customClass="'data-table-items'"
        :allowCopy="true"
        :isReadonly="true"
        :hideDetails="true"
      >
      </custom-text>
    </template>
    <template v-slot:[`item.credentials.user_name`]="{ item }">
      <custom-text
        :textValue="item.credentials.user_name"
        :tooltip="'Copy User Name'"
        :isSoloInverted="true"
        :customClass="'data-table-items'"
        :allowCopy="true"
        :isReadonly="true"
        :hideDetails="true"
      >
      </custom-text>
    </template>
    <template v-slot:[`item.credentials.notes`]="{ item }">
      <v-textarea
        :dense="true"
        readonly
        outlined
        no-resize
        rows="1"
        class="data-table-items"
        :hideDetails="true"
        :value="item.credentials.notes"
      >
      </v-textarea>
    </template>
    <template v-slot:[`item.epoch_time`]="{ item }">
      <span class="data-table-items">
        {{ new Date(item.epoch_time * 1000) }}
      </span>
    </template>

    <template v-slot:no-data>
      <v-btn color="primary"> No password history for selected app </v-btn>
    </template>
  </v-data-table>
</template>
<script>
import CustomText from "./CustomText.vue";
export default {
  props: {
    history: Array,
  },
  components: {
    "custom-text": CustomText,
  },
  data: () => ({
    headers: [
      {
        text: "Version",
        value: "version",
        class: "blue-grey darken-3 white--text",
      },
      {
        text: "Password",
        value: "credentials.password",
        class: "blue-grey darken-3 white--text",
      },
      {
        text: "User Name",
        value: "credentials.user_name",
        class: "blue-grey darken-3 white--text",
      },
      {
        text: "Notes",
        value: "credentials.notes",
        class: "blue-grey darken-3 white--text",
      },
      {
        text: "Updated On",
        value: "epoch_time",
        class: "blue-grey darken-3 white--text",
      },
    ],
  }),
};
</script>
<style scoped>
.data-table-items {
  font-family: "Roboto", sans-serif;
  color: #263238;
}
</style>