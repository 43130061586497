// Imports
import Vue from 'vue'
import Router from 'vue-router'
import store from "../store";
import Login from "../views/Login.vue";
import Vault from "@/views/VaultRetail";
import VaultCards from "@/views/VaultCards.vue";
import VaultBank from "@/views/VaultBank.vue";
import EnableMFA from "../views/EnableMFA";
import VerifyMFACode from "../views/VerifyMFACode";
import AmIPwned from "../views/AmIPwned";
import Pastes from "../views/Pastes";
import CompromisedPasswords from "../views/CompromisedPasswords";
import VaultServiceAccounts from "../views/VaultServiceAccounts";
//import VaultFiles from "../views/VaultFiles"
// import About from "../views/About";
// import HowItWorks from "../views/HowItWorks";
import UpdateProfile from "../views/UpdateProfile";
import TermsOfUse from "@/components/Terms.vue";
import PrivacyPolicy from "@/components/PrivacyPolicy.vue";
import Blogs from "@/views/resources/Index.vue";
import BlogNeedForDataSecurity from "@/components/Blogs/BlogNeedForDataSecurity";
import BlogWaysToPreventDataBreach from "@/components/Blogs/BlogWaysToPreventDataBreach"
import BlogHackersTarget from "@/components/Blogs/BlogHackersTarget"
import BlogCorporationsTargeted from "@/components/Blogs/BlogCorporationsTargeted"

Vue.use(Router)

const router = new Router({
  // mode: 'history',
  // base: process.env.VUE_APP_BASE_URL,
  scrollBehavior: (to, from, savedPosition) => {
    if (to.hash) return { selector: to.hash }
    if (savedPosition) return savedPosition

    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      component: () => import('@/layouts/home/Index.vue'),
      children: [
        {
          path: '',
          name: 'Home',
          component: () => import('@/views/home/Index.vue'),
        },
        {
          path: 'features',
          name: 'Features',
          component: () => import('@/views/features/Index.vue'),
          // meta: { src: require('@/assets/about.jpg') },
        },
        {
          path: 'pricing',
          name: 'Pricing',
          component: () => import('@/views/pricing-plans/Index.vue'),
          // meta: { src: require('@/assets/pricing.jpg') },
        },
        {
          path: 'contact-us',
          name: 'Contact',
          component: () => import('@/views/contact-us/Index.vue'),
          // meta: { src: require('@/assets/contact.jpg') },
        },
        {
          path: "termsofuse",
          name: "TermsOfUse",
          component: TermsOfUse,
          meta: {
            title: 'Terms Of Use',
          }
        },
        {
          path: "privacypolicy",
          name: "PrivacyPolicy",
          component: PrivacyPolicy,
          meta: {
            title: 'Privacy Policy',
          }
        },
        {
          path: "login",
          name: "Login",
          component: Login,
        },
        {
          path: "blogs",
          name: "Resources",
          component: Blogs,
          // meta: {
          //   title: 'Resources',
          // }
        },
        {
          path: "blogs/need-for-data-security",
          name: "BlogNeedForDataSecurity",
          component: BlogNeedForDataSecurity,
          meta: {
            title: 'Need for data security',
            metaTags: [
              {
                name: 'description',
                content: ' Data security is an essential part of everyones life, especially in modern times with the exponential increase in the number of personal computers, internet users, and various forms of data stored on the cloud. When you think about it, there has been a change in basic assumptions from physical to digital and from internal to external threats posed by malicious entities.'
              },
              {
                name: 'keywords',
                content: 'Data security, Importance of data security, Need for data security in 2022'
              },

            ]
          }

        },
        {
          path: "blogs/ways-to-prevent-data-breach",
          name: "BlogWaysToPreventDataBreach",
          component: BlogWaysToPreventDataBreach,
          meta: {
            title: 'Ways to prevent data breach',
            metaTags: [
              {
                name: 'description',
                content: 'Hackers are setting a trap for large organizations that have an entirely separate department to handle the data,' +
                  'So why not take such precautionary steps so that you can protect your business from being stolen or hacked? The following are some precautionary steps to build a protective layer around your business'
              },
              {
                name: 'keywords',
                content: 'data breach, information security'
              },

            ]
          }

        },
        {
          path: "blogs/hackers-targeting-individuals",
          name: "BlogHackersTarget",
          component: BlogHackersTarget,
          meta: {
            title: 'Hackers are targeting individuals more than ever',
            metaTags: [
              {
                name: 'description',
                content: 'It is a matter of concern how hackers are building their game day by day. On an average, one cyber-attack is done every hour' +
                  'in the world, and its threat is looming not only on the businesses but also on the individuals who have personal data.'
              },
              {
                name: 'keywords',
                content: 'data breach, information security'
              },

            ]
          }

        },
        {
          path: "blogs/corporations-targeted",
          name: "BlogCorporationsTargeted",
          component: BlogCorporationsTargeted,
          meta: {
            title: 'Hacking: Which corporations are targeted and how',
            metaTags: [
              {
                name: 'description',
                content: 'Talking about cyber threats, some people think whether hackers can target any organization, given they (organizations) have' +
                  ' a systematic plan for everything. The answer is a definitive yes. To a substantial extent, even big organizations are trying'
              },
              {
                property: 'og:description',
                content: 'The home page of our example app.'
              },
              {
                name: 'keywords',
                content: 'data breach, information security'
              },
              {
                property: 'og:keywords',
                content: 'data breach, information security'
              },

            ]
          }

        },
        {
          path: "verifycode",
          name: "Verify Code",
          component: VerifyMFACode,
          meta: {
            requiresVerifiedPassword: true,
            title: 'Verify MFA Code'
          },
        },
        {
          path: "vault",
          name: "Password Wallet",
          component: Vault,
          meta: {
            requiresAuth: true,
            title: 'FirstVault'
          },
          icon: 'mdi-wallet'
        },
        {
          path: "cards",
          name: "Credit/Debit Cards",
          component: VaultCards,
          meta: {
            requiresAuth: true,
            title: 'FirstVault'
          },
          icon: 'mdi-credit-card-multiple'
        },
        {
          path: "banks",
          name: "Bank Accounts",
          component: VaultBank,
          meta: {
            requiresAuth: true,
            title: 'FirstVault'
          },
          icon: 'mdi-bank'
        },
        {
          path: "vaultserviceaccounts",
          name: "Services Wallet",
          component: VaultServiceAccounts,
          meta: {
            requiresEnterpriseAuth: true,
            title: 'FirstVault'
          },
          icon: 'mdi-wallet-plus'
        },
        // {
        //   path: "vaultfiles",
        //   name: "Files Wallet",
        //   component: VaultFiles,
        //   meta: {
        //     requiresAuth: true,
        //     title: 'FirstVault'
        //   },
        //   icon: 'mdi-wallet-plus'
        // },

        {
          path: "amipwned",
          name: "Compromised Accounts",
          component: AmIPwned,
          meta: {
            requiresAuth: true,
            isPremiumUser: true,
            title: 'FirstVault'
          },
          icon: 'mdi-bug-check'
        },
        {
          path: "compromisedpasswords",
          name: "Compromised Passwords",
          component: CompromisedPasswords,
          meta: {
            requiresAuth: true,
            isPremiumUser: true,
            title: 'FirstVault'
          },
          icon: 'mdi-lock-open-alert'
        },
        {
          path: "pastes",
          name: "Pastes Accounts",
          component: Pastes,
          meta: {
            requiresAuth: true,
            isPremiumUser: true,
            title: 'FirstVault'
          },
          icon: 'mdi-content-copy'
        },
        {
          path: "enablemfa",
          name: "Enable MFA",
          component: EnableMFA,
          meta: {
            requiresAuth: true,
            title: 'FirstVault'
          },
          icon: 'mdi-two-factor-authentication'

        },
        {
          path: "UpdateProfile",
          name: "Update Profile",
          component: UpdateProfile,
          meta: {
            requiresAuth: true,
            title: 'FirstVault'
          },
          icon: 'mdi-account-edit'
        },
      ],
    },
    {
      path: '*',
      name: 'FourOhFour',
      component: () => import('@/views/404/Index.vue'),
    },
  ],
})

// This callback runs before every route change, including on page load
// Vue-router has a beforeEach method that is called before each route is processed. 
// This is where we can define our checking condition and restrict user access. 
// The method takes three parameters — to, from, and next. to is where the user wishes to go, from is where the user is coming from,
// and next is a callback function that continues the processing of the user request.
router.beforeEach((to, from, next) => {
  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);
  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);
  const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);
  // If a route with a title was found, set the document (page) title to that value.
  if (nearestWithTitle) {
    document.title = nearestWithTitle.meta.title;
  } else if (previousNearestWithMeta) {
    document.title = previousNearestWithMeta.meta.title;
  }
  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));
  if (nearestWithMeta) {
    // Turn the meta tag definitions into actual elements in the head.
    nearestWithMeta.meta.metaTags.map(tagDef => {
      const tag = document.createElement('meta');
      Object.keys(tagDef).forEach(key => {
        tag.setAttribute(key, tagDef[key]);
      });
      // We use this to track which meta tags we create so we don't interfere with other ones.
      tag.setAttribute('data-vue-router-controlled', '');
      return tag;
    })
      // Add the meta tags to the document head.
      .forEach(tag => document.head.appendChild(tag));
  }
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (store.getters.StateAuthenticated) {
      next();
      return;
    }
    next("/");
  }
  else if (to.matched.some((record) => record.meta.requiresVerifiedPassword)) {
    if (store.getters.StateUser.password_verified) {
      next();
      return;
    }
    next("/");
  }
  else if (to.matched.some((record) => record.meta.requiresEnterpriseAuth)) {
    if (store.getters.StateAuthenticated && store.getters.StateEnterprise) {
      next();
      return;
    }
    next("/");
  }
  else {
    //for guest roles no auth is required
    next();
  }
});

export default router;
