<template>
  <v-card flat color="white" tile :loading="loading">
    <v-card-title>
      <span class="headline">{{ cardTitle }} </span>
    </v-card-title>
    <v-container>
      <qrcode
        :qrCodeText="mfa_uri"
        :showDialog="showDialog"
        @qrCodeSubmitted="onQrCodeSubmit"
        @closeQrDialog="onCloseQrDialog"
        :dialogMessage="dialogMessage"
        :mfaAlreadyEnabled="mfaAlreadyEnabled"
      ></qrcode>
    </v-container>
  </v-card>
</template>
<script>
import axios from "axios";
import { mapGetters, mapActions } from "vuex";
import QrCode from "../components/QrCode.vue";

export default {
  name: "EnableMFA",
  components: {
    qrcode: QrCode,
  },
  computed: {
    ...mapGetters(["StateUser"]),
  },
  created() {
    this.initialize();
  },
  data() {
    return {
      loading: false,
      mfa_uri: "No Uri Retreived",
      mfa_key: "",
      showDialog: false,
      cardTitle: "",
      dialogMessage: "",
      mfaAlreadyEnabled: false,
    };
  },

  methods: {
    ...mapActions(["MutateUser"]),
    async initialize() {
      try {
        if (this.StateUser.enable_mfa === true) {
          this.mfaAlreadyEnabled = true;
          this.dialogMessage =
            "Multi-Factor authentication for the user is already enabled.";
          this.showDialog = true;
        } else {
          this.loading = true;
          const User = new URLSearchParams();
          User.append("id", this.StateUser.id);
          User.append("email_id", this.StateUser.email_id);
          let response = await axios
            .post("GetQrUri", User)
            .then(function (response) {
              if (response !== undefined && response.data !== undefined) {
                return response.data;
              }
            });
          this.mfa_uri = response.mfa_uri;
          this.mfa_key = response.userProfile.mfa_key;
          this.loading = false;
          // this.showDialog = true;
        }
      } catch (error) {
        this.cardTitle = error;
        this.loading = false;
      }
    },
    async onQrCodeSubmit(verificationCode) {
      this.loading = true;
      const code = new URLSearchParams();
      code.append("verification_code", verificationCode);
      code.append("mfa_key", this.mfa_key);
      code.append("id", this.StateUser.id);
      try {
        let response = await axios
          .post("EnableMfaTotp", code)
          .then(function (response) {
            if (response !== undefined) {
              return response.data;
            }
          });
        this.dialogMessage = response.message;
        this.showDialog = true;
        //Update User State
        if (response.message_type !== "Error") {
          await this.MutateUser(response);
        }
        this.loading = false;
        this.mfa_uri = "";
        // this.onCloseQrDialog();
      } catch (error) {
        this.dialogMessage =
          "Error in verifying OTP. please try again or enable later";
        this.showDialog = true;
        this.onCloseQrDialog();
        this.mfa_key = "";
        this.loading = false;
      }
    },
    onCloseQrDialog() {
      this.mfa_uri = "";
      this.showDialog = false;
    },
  },
};
</script>
