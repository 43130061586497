<template>
  <v-container fluid class="bottomPadding">
    <div class="refreshButton">
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            elevation="3"
            color="indigo"
            fab
            dark
            absolute
            bottom
            right
            small
            v-bind="attrs"
            v-on="on"
            @click="initialize"
          >
            <v-icon>mdi-refresh</v-icon>
          </v-btn>
        </template>
        Refresh Items
      </v-tooltip>
    </div>
    <v-row>
      <v-col>
        <v-card color="white" flat hover :loading="loading">
          <v-card-title>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Search Cards"
                  hide-details
                ></v-text-field>
              </v-col>
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn
                    elevation="3"
                    color="indigo"
                    dark
                    fab
                    absolute
                    small
                    bottom
                    right
                    v-on="on"
                    @click="addItem"
                  >
                    <v-icon dark>mdi-plus</v-icon>
                  </v-btn>
                </template>
                Add New Item
              </v-tooltip>
            </v-row>
          </v-card-title>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        v-for="item in filteredList"
        :key="item.credentials_id"
        cols="12"
        sm="12"
        md="2"
        lg="2"
        xl="2"
      >
        <v-card :color="color">
          <v-card-subtitle class="cardColor white--text ma-0 pa-2" align="left">
            {{ item.credentials.bank_name }} &nbsp;-&nbsp;
            {{ item.credentials.card_type }}
          </v-card-subtitle>
          <v-card-text align="left" class="pa-1">
            <v-row dense>
              <v-col cols="12">
                <custom-text
                  :textValue="item.credentials.card_number"
                  :tooltip="'Copy Card Number'"
                  :isSoloInverted="true"
                  :customClass="'data-table-items'"
                  :allowCopy="true"
                  :isCard="true"
                  :isReadonly="true"
                  :showPassword="true"
                  :hideDetails="true"
                >
                </custom-text>
              </v-col>
              <v-col cols="6">
                <custom-text
                  :textValue="item.credentials.expiration_date"
                  :isSoloInverted="true"
                  :customClass="'data-table-items'"
                  :isReadonly="true"
                  :hideDetails="true"
                >
                </custom-text>
              </v-col>
              <v-col cols="6">
                <custom-text
                  :textValue="item.credentials.security_code"
                  :tooltip="'Copy CVV'"
                  :isSoloInverted="true"
                  :customClass="'data-table-items'"
                  :allowCopy="true"
                  :isPassWord="true"
                  :isReadonly="true"
                  :showPassword="true"
                  :hideDetails="true"
                >
                </custom-text>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions align="left" class="pa-1">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn icon @click="editItem(item)" v-on="on">
                  <v-icon right dark> mdi-pencil </v-icon>
                </v-btn>
              </template>
              Edit
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn icon @click="deleteItem(item)" v-on="on">
                  <v-icon right dark> mdi-delete </v-icon>
                </v-btn>
              </template>
              Delete
            </v-tooltip>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog
      v-model="dialogItem"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
      scrollable
      persistent
    >
      <v-card :loading="loading">
        <v-card flat tile>
          <v-toolbar dark color="#000000">
            <v-btn icon dark @click="closeItem">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>{{ formTitle }}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
              <v-btn dark text @click="save(false)" :disabled="!valid">
                Save
              </v-btn>
            </v-toolbar-items>
          </v-toolbar>
        </v-card>
        <v-form ref="formSave" v-model="valid" lazy-validation>
          <v-container dense>
            <v-row>
              <v-col sm="12" md="4">
                <v-text-field
                  v-model="editedItem.credentials.bank_name"
                  label="Issuing Bank Name"
                  required
                  :rules="userNameRule"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col sm="12" md="4">
                <v-text-field
                  v-model="editedItem.credentials.card_name"
                  label="Name on Card"
                  required
                  :rules="userNameRule"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col sm="6" md="4">
                <v-text-field
                  v-model="editedItem.credentials.card_number"
                  :rules="cardNumberRule"
                  label="Card Number"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col sm="6" md="2">
                <v-select
                  :items="cardTypes"
                  v-model="editedItem.credentials.card_type"
                  label="Card Type"
                ></v-select>
              </v-col>
              <v-col cols="3" sm="6" md="2">
                <v-text-field
                  v-model="editedItem.credentials.security_code"
                  label="Security Code/CVV"
                  :rules="securityCodeRule"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col sm="4" md="1">
                <v-select
                  :items="months"
                  v-model="editedItem.credentials.month"
                  label="Month"
                ></v-select>
              </v-col>
              <v-col sm="4" md="1">
                <v-select
                  :items="years"
                  v-model="editedItem.credentials.year"
                  label="Year"
                ></v-select>
              </v-col>
              <v-col sm="4" md="2">
                <v-text-field
                  :value="expirationDate"
                  label="Expiration Date"
                  readonly
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col sm="12" md="4">
                <v-textarea
                  outlined
                  label="Notes"
                  v-model="editedItem.credentials.notes"
                  :rules="notesRule"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogDelete" :max-width="dialogWidth">
      <v-card>
        <v-card-title class="headline"
          >Are you sure you want to delete this item?</v-card-title
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="error" text @click="closeDelete">Cancel</v-btn>
          <v-btn class="success" text @click="deleteItemConfirm">OK</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogAlert" :max-width="dialogWidth" persistent>
      <v-alert :type="alertType">
        <v-row align="center">
          <v-col class="grow">{{ alert_message }}</v-col>
          <v-col class="shrink">
            <v-btn @click="closeAlert">Exit</v-btn>
          </v-col>
        </v-row>
      </v-alert>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import axios from "axios";
import CustomText from "@/components/Custom/CustomText.vue";
import cryptoHelper from "../services/webcrypto.js";
import common from "../services/common.js";
export default {
  name: "VaultCards",
  mixins: [cryptoHelper, common],
  components: {
    "custom-text": CustomText,
  },

  data: () => ({
    color: "#EFEBE9",
    valid: true,
    cardNumberRule: [
      (v) => !!v || "Required field",
      (v) => /^[0-9]+$/.test(v) || "only numbers are allowed",
      (v) => (v && v.length === 16) || "Must be 16 characters",
    ],
    securityCodeRule: [
      (v) => !!v || "Required field",
      (v) => /^[0-9]+$/.test(v) || "only numbers are allowed",
      (v) =>
        (v && v.length <= 4 && v.length >= 3) || "Must be 3 or 4 characters",
    ],
    userNameRule: [
      (v) => !!v || "Required field",
      (v) => (v && v.length <= 256) || "Must be less than 256 characters",
    ],
    notesRule: [
      (v) =>
        (v && v.length) > 1000
          ? "We currently allow 1000 characters in notes"
          : true,
    ],
    loading: false,
    currentCredentials: [],
    dialogItem: false,
    dialogWidth: "600px",
    paymentCard: "payment_card",
    dialogDelete: false,
    dialogAlert: false,
    alertType: "",
    search: "",
    cardTypes: ["Credit", "Debit"],
    months: [
      "01",
      "02",
      "03",
      "04",
      "05",
      "06",
      "07",
      "08",
      "09",
      "10",
      "11",
      "12",
    ],
    years: [
      "00",
      "01",
      "02",
      "03",
      "04",
      "05",
      "06",
      "07",
      "08",
      "09",
      "10",
      "11",
      "12",
      "13",
      "14",
      "15",
      "16",
      "17",
      "18",
      "19",
      "20",
      "21",
      "22",
      "23",
      "24",
      "25",
      "26",
      "27",
      "28",
      "29",
      "30",
      "31",
      "32",
      "33",
      "34",
      "35",
      "36",
      "37",
      "38",
      "39",
      "40",
      "41",
      "42",
      "43",
      "44",
      "45",
      "46",
      "47",
      "48",
      "49",
      "50",
      "51",
      "52",
      "53",
      "54",
      "55",
      "56",
      "57",
      "58",
      "59",
      "60",
      "61",
      "62",
      "63",
      "64",
      "65",
      "66",
      "67",
      "68",
      "69",
      "70",
      "71",
      "72",
      "73",
      "74",
      "75",
      "76",
      "77",
      "78",
      "79",
      "80",
      "81",
      "82",
      "83",
      "84",
      "85",
      "86",
      "87",
      "88",
      "89",
      "90",
      "91",
      "92",
      "93",
      "94",
      "95",
      "96",
      "97",
      "98",
      "99",
    ],
    editedIndex: -1,
    editedItem: {
      id: "",
      user_id: "",
      credential_type: "payment_card",
      credentials_id: "",
      credentials: {
        bank_name: "",
        card_name: "",
        card_type: "",
        card_number: "",
        security_code: "",
        expiration_date: "",
        month: "",
        year: "",
        notes: "",
      },
      is_active: false,
      version: 0,
    },
    defaultCredentials: {
      bank_name: "",
      card_name: "",
      card_type: "",
      card_number: "",
      security_code: "",
      expiration_date: "",
      month: "",
      year: "",
      notes: "",
    },
    defaultItem: {
      id: "",
      user_id: "",
      credential_type: "payment_card",
      credentials_id: "",
      is_active: false,
      version: 0,
    },
    alert_message: "",
  }),

  computed: {
    ...mapGetters([
      "StateUser",
      "StateCryptoKey",
      "StateCryptoKeyInstanceVersion",
      "StateCredentialsRetail",
    ]),
    expirationDate() {
      return this.getExpirationDate(
        this.editedItem.credentials.month,
        this.editedItem.credentials.year
      );
    },
    formTitle() {
      return this.editedIndex === -1 ? "New Item" : "Edit Item";
    },
    filteredList() {
      return this.currentCredentials.filter((x) => {
        return (
          x.credentials.bank_name
            .toLowerCase()
            .includes(this.search.toLowerCase()) ||
          x.credentials.card_type
            .toLowerCase()
            .includes(this.search.toLowerCase())
        );
      });
    },
  },
  watch: {
    StateCryptoKeyInstanceVersion: function (newV, oldV) {
      if (oldV !== newV && newV > 1) {
        this.initialize();
      }
    },
    StateCredentialsRetail: function (newV, oldV) {
      if (oldV !== newV) {
        this.initialize();
      }
    },
  },
  mounted() {
    this.initialize();
  },
  methods: {
    ...mapActions(["MutateCredentialsRetail"]),
    async initialize() {
      try {
        this.loading = true;
        var credsArray = JSON.parse(this.StateCredentialsRetail);
        let creds = credsArray.filter(
          (x) => x.credential_type === this.paymentCard
        );

        var activeCreds = creds.filter((x) => x.is_active);
        this.customSort(activeCreds);
        this.currentCredentials = activeCreds;
        this.loading = false;
      } catch (error) {
        this.loading = false;
        this.alert_message =
          "Error retreiving payment card details. Please try again in some time. " +
          error;
        this.alertType = "error";
        this.dialogAlert = true;
      }
    },
    addItem() {
      this.valid = true;
      this.dialogItem = true;
    },
    editItem(item) {
      this.valid = true;
      this.setEditItems(item);
      this.dialogItem = true;
    },
    deleteItem(item) {
      this.setEditItems(item);
      this.dialogDelete = true;
    },
    async deleteItemConfirm() {
      await this.save(true);
      this.closeDelete();
    },
    closeItem() {
      this.dialogItem = false;
      this.refreshEditItems();
    },
    closeDelete() {
      this.dialogDelete = false;
      this.refreshEditItems();
    },
    closeAlert() {
      this.dialogAlert = false;
      this.alert_message = "";
    },
    setEditItems(item) {
      this.editedIndex = this.currentCredentials.indexOf(item);
      this.editedItem.id = item.id;
      this.editedItem.credentials_id = item.credentials_id;
      this.editedItem.credentials = item.credentials;
      this.editedItem.is_active = item.is_active;
      this.editedItem.version = item.version;
    },
    refreshEditItems() {
      this.editedItem.credentials = Object.assign({}, this.defaultCredentials);
      this.editedItem.id = "";
      this.editedItem.credentials_id = "";
      this.editedItem.is_active = false;
      this.editedItem.version = 0;
      this.editedIndex = -1;
    },
    async save(isDelete) {
      if (!isDelete && !this.$refs.formSave.validate()) return;
      this.valid = false;
      var item = this.editedItem;
      await this.storeOrDeleteCredentials({ item, isDelete });
    },
    async storeOrDeleteCredentials({ item, isDelete }) {
      try {
        this.loading = true;
        var user_id = this.StateUser.id;
        let payload = new URLSearchParams();
        payload.append("user_id", user_id);
        payload.append("id", item.id);
        payload.append("credential_type", item.credential_type);
        payload.append("credentials_id", item.credentials_id);
        payload.append("is_active", item.is_active);
        payload.append("version", item.version);
        //epoch time in seconds for azure compatibility
        payload.append("epoch_time", Math.floor(Date.now() / 1000));
        var response = "";

        if (isDelete) {
          response = await axios.post("DeleteCredentials", payload);
        } else {
          // this is only in case of storing the password
          item.credentials.expiration_date = this.getExpirationDate(
            item.credentials.month,
            item.credentials.year
          );
          var creds = JSON.stringify(item.credentials);
          if (!this.tryParseJSON(creds)) {
            alert("Invalid credentials json");
            return false;
          }

          var encryptedCreds = await this.encrypt(this.StateCryptoKey, creds);
          payload.append("credentials", encryptedCreds);

          response = await axios.post("StoreCredentialsUsingPBKDF2", payload);
        }

        await this.refreshItems();
        if (response) {
          this.alert_message = response.data.message;
          this.alertType = "success";
          this.dialogAlert = true;
        }
        this.loading = false;
        if (isDelete) {
          this.closeDelete();
        } else {
          this.closeItem();
        }
      } catch (error) {
        this.loading = false;
        this.alert_message = "Error during save/delete" + "| " + error;
        this.alertType = "error";
        this.closeItem();
        this.dialogAlert = true;
      }
    },
    async refreshItems() {
      try {
        var userId = this.StateUser.id;
        let payload = new URLSearchParams();
        payload.append("userId", userId);
        var response = await axios.post("GetCredentialsUsingPBKDF2", payload);

        if (response !== undefined && response.data !== undefined) {
          if (
            response.data.error_type !== undefined &&
            response.data.error_type === "AuthError"
          ) {
            this.alert_message = response.data.error_message;
            this.alertType = "error";
            this.dialogAlert = true;
            this.loading = false;
            return;
          }

          for (const element of response.data) {
            let decrypted = await this.decrypt(
              this.StateCryptoKey,
              element.credentials
            );
            element.credentials = JSON.parse(decrypted);
          }
          await this.MutateCredentialsRetail(JSON.stringify(response.data));
        }
      } catch (error) {
        this.loading = false;
        this.alert_message =
          "Error retreiving credentials. Please try again in some time. " +
          error;
        this.alertType = "error";
        this.dialogAlert = true;
      }
    },
    customSort(itemsArray) {
      itemsArray.sort(function (a, b) {
        var x = a.credentials.bank_name.toLowerCase();
        var y = b.credentials.bank_name.toLowerCase();
        if (x < y) {
          return -1;
        }
        if (x > y) {
          return 1;
        }
        return 0;
      });
    },
    getExpirationDate(month, year) {
      return month + "/" + year;
    },
  },
};
</script>
<style scoped>
.data-table-items {
  font-family: "Roboto", sans-serif;
  color: #424242;
}

.cardColor {
  background-color: #424242;
}

.refreshButton {
  position: fixed;
  bottom: 35px;
  right: 1px;
  z-index: 9;
}

.bottomPadding {
  padding-bottom: 75px;
}
</style>
