<template>
  <V-Card flat :loading="loading">
    <v-card-title>
      <h4>
        Check if the entered password was compromised in any breaches happened
      </h4>
    </v-card-title>
    <v-card-subtitle align="left">
      <h4>
        To keep your password secure, we do not transmit the entire password,
        but the first five characters of SHA-1 hash of the password inputted.
      </h4>
    </v-card-subtitle>
    <v-card-text>
      <v-row>
        <v-col cols="12" sm="12" md="4" lg="4" xl="4">
          <v-text-field
            v-model="passwordText"
            label="Enter password here"
            :rules="passwordRules"
            required
          >
            <template v-slot:append>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    class="success"
                    @click="initialize"
                    v-bind="attrs"
                    v-on="on"
                  >
                    mdi-magnify-scan
                  </v-icon>
                </template>
                Check password
              </v-tooltip>
            </template>
          </v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <span v-if="show">
          <v-badge v-if="compromised" class="error">
            <h4>{{ result }}</h4>
          </v-badge>

          <v-badge v-else class="success">
            <h4>{{ result }}</h4>
          </v-badge>
        </span>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <a
        target="_blank"
        href="https://haveibeenpwned.com"
        rel="noopener"
        align="right"
        ><h6>Courtsey: Have I Been Pwned</h6>
      </a>
    </v-card-actions>
  </V-Card>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";
import cryptoHelper from "../services/webcrypto.js";
export default {
  name: "Pastes",
  data: () => ({
    loading: false,
    passwordText: "",
    result: "",
    show: false,
    compromised: false,
    passwordRules: [(v) => !!v || "Password is required"],
    count: 0,
  }),
  mixins: [cryptoHelper],
  computed: {
    ...mapGetters(["StateUser"]),
  },

  methods: {
    async initialize() {
      try {
        this.loading = true;
        this.result = "";
        var val = await this.sha1Hash(this.passwordText);
        var valToSend = val.substring(0, 5);
        var hashToCompare = val.substring(5, val.length).toLocaleLowerCase();
        var param = new URLSearchParams();
        param.append("first_five_hash", valToSend);
        let responseData = await axios
          .post("CheckCompromisedPasswords", param)
          .then(function (response) {
            if (response !== undefined && response.data !== undefined) {
              return response.data;
            } else return [];
          });
        responseData.forEach((str) => {
          if (
            hashToCompare ==
            str.substring(0, str.indexOf(":")).toLocaleLowerCase()
          ) {
            this.count = str.split(":")[1];
            this.result =
              "this password has appeared " +
              this.count +
              " times in the breach";
            this.compromised = true;
            return;
          }
        });
        this.show = true;

        if (this.result === "") {
          (this.compromised = false),
            (this.result = "Password does not appear in any breaches");
        }
        this.loading = false;
      } catch {
        this.loading = false;
        this.matchedPassword = "Encountered error while processing request.";
      }
    },
  },
};
</script>
