import axios from 'axios';

const state = {
    user: {
        id: "",
        email_id: "",
        first_name: "",
        last_name: "",
        mobile_number: "",
        password: "",
        re_password: "",
        salt: "",
        enable_mfa: false,
        mfa_key: "",
        authentication_app: "",
        authentication_app_id: "",
        picture: "",
        email_verified: "",
        locale: "",
        login_time: "",
        password_verified: false,
        user_category: "",
        subscription_startdate: "",
        subscription_frequency: "",
        subscription_enddate: "",
        subscription_enabled: ""
    },
    defaultUser: {
        id: "",
        email_id: "",
        first_name: "",
        last_name: "",
        mobile_number: "",
        password: "",
        re_password: "",
        salt: "",
        enable_mfa: false,
        mfa_key: "",
        authentication_app: "",
        authentication_app_id: "",
        picture: "",
        email_verified: "",
        locale: "",
        login_time: "",
        password_verified: false,
        user_category: "",
        subscription_startdate: "",
        subscription_frequency: "",
        subscription_enddate: "",
        subscription_enabled: ""
    },
    credentialsRetail: [],
    cryptoKey: Array,
    cryptoKeyInstanceVersion: 0,
    isAuthenticated: false,
    isEnterprise: false,
    defaultPasswordLength: 16,
    excludeCharacters: '',
    message: ''
};

const getters = {
    StateUser: state => state.user,
    StateAuthenticated: state => state.isAuthenticated,
    StateCredentialsRetail: state => state.credentialsRetail,
    StateCryptoKey: state => state.cryptoKey,
    StateCryptoKeyInstanceVersion: state => state.cryptoKeyInstanceVersion,
    StateEnterprise: state => state.isEnterprise,
    StateAuthenticationMessage: state => state.message,
    StateDefaultPasswordLength: state => state.defaultPasswordLength,
    StateExcludeCharacters: state => state.excludeCharacters
};

const actions = {
    async LogIn({
        commit
    }, responseData) {
        //Vuex mutations take up to two arguments: state and payload. The current state of the store is passed in by Vuex automatically as the first argument and the second argument holds any parameters you pass in.
        //The easiest way to pass a number of parameters is to destruct them, ensure oject properties name are exactly the same
        commit('setUser', { responseData })
        commit('loginUser', { responseData })
    },
    async MutateUser({ commit }, responseData) {
        commit('setUser', { responseData })
    },
    async MutatePassword({
        commit
    }) {
        commit('mutatePassword')
    },
    async MutateAuthentication({
        commit
    }, otpValidated) {
        commit('mutateAuthentication', otpValidated)
    },
    async MutateCryptoKey({
        commit
    }, cryptoKey) {
        commit('mutatecryptoKey', cryptoKey)
    },
    async MutateCredentialsRetail({ commit }, credentials) {
        commit('setCredentialsRetail', credentials);
    },
    async LogOut({
        commit
    }) {
        const user = new URLSearchParams();
        user.append("userId", state.user.id);
        await axios.post("SignOutUser", user)
        //await axios.get('SignOutUser')
        await commit('logout')
    },
};
const mutations = {
    loginUser(state, { responseData }) {
        if (!responseData.userProfile.enable_mfa) {
            state.isAuthenticated = responseData.password_verified
        }
    },

    setUser(state, { responseData }) {
        state.user.id = responseData.userProfile.id,
            state.user.user_category = responseData.userProfile.user_category,
            state.user.picture = responseData.userProfile.picture,
            state.user.first_name = responseData.userProfile.first_name,
            state.user.last_name = responseData.userProfile.last_name,
            state.user.enable_mfa = responseData.userProfile.enable_mfa,
            state.user.mobile_number = responseData.userProfile.mobile_number,
            state.user.password_verified = responseData.password_verified,
            state.user.salt = responseData.salt,
            state.message = responseData.message_type + "! " + responseData.message,
            state.user.subscription_startdate = responseData.userProfile.subscription_startdate,
            state.user.subscription_frequency = responseData.userProfile.subscription_frequency,
            state.user.subscription_enddate = responseData.userProfile.subscription_enddate,
            state.user.subscription_enabled = responseData.userProfile.subscription_enabled
    },

    mutateAuthentication(state, otpValidated) {
        state.isAuthenticated = otpValidated
    },
    mutatePassword(state) {
        state.user.password = "",
            state.user.re_password = ""
    },
    mutatecryptoKey(state, cryptoKey) {
        state.cryptoKey = cryptoKey,
            state.cryptoKeyInstanceVersion = state.cryptoKeyInstanceVersion + 1
    },
    setCredentialsRetail(state, credentials) {
        state.credentialsRetail = credentials;
    },
    logout(state) {
        sessionStorage.clear(),
            localStorage.clear(),
            Object.assign(state.user, state.defaultUser),
            state.isAuthenticated = false,
            state.defaultPasswordLength = 16,
            state.excludeCharacters = '',
            state.message = '',
            state.cryptoKey = new ArrayBuffer(),
            state.credentialsRetail = [],
            state.cryptoKeyInstanceVersion = 0
    },
};

export default {
    state,
    getters,
    actions,
    mutations
};