<template>
  <verifyCode @otpVaidated="otpValidated"></verifyCode>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import VerifyCode from "../components/VerifyCode.vue";
import cryptoHelper from "../services/webcrypto.js";

export default {
  name: "VerifyMFACode",
  components: {
    verifyCode: VerifyCode,
  },
  computed: {
    ...mapGetters(["StateUser"]),
  },
  mixins: [cryptoHelper],
  methods: {
    ...mapActions([
      "MutatePassword",
      "MutateAuthentication",
      "MutateCryptoKey",
    ]),

    async otpValidated() {
      await this.setCryptoKey();
      await this.MutatePassword();
      var otpValidated = true;
      await this.MutateAuthentication(otpValidated);
      this.$router.push("/vault");
    },
    async setCryptoKey() {
      let cryptokey = await this.getKeyUsingPBKDF2(
        this.StateUser.password,
        this.StateUser.salt
      );
      await this.MutateCryptoKey(cryptokey);
    },
  },
};
</script>
