export default {
    methods: {
        //this validates a valid JSON Object 
        tryParseJSON(jsonString) {
            try {
                var o = JSON.parse(jsonString);

                // Handle non-exception-throwing cases:
                // Neither JSON.parse(false) or JSON.parse(1234) throw errors, hence the type-checking,
                // but... JSON.parse(null) returns null, and typeof null === "object", 
                // so we must check for that, too. null is falsey, so this suffices:
                if (o && typeof o === "object") {
                    return true;
                }
            }
            catch (e) {
                console.log("Invalid JSON object")
            }
            return false;
        },
        //Converts a array to respective file, with last modified date 
        arrayToFile(fileArray, fileName, type) {
            return new File(fileArray, fileName, { lastModified: new Date().getTime(), type: type })
        },
        //Converts a file to blob 
        async fileToArray(theFile) {
            return new Uint8Array(await theFile.arrayBuffer());
        },
        getFileBytes(fileSizeBytes) {
            let sOutput = fileSizeBytes + " bytes";
            // optional code for multiples approximation
            const aMultiples = ["KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
            //For KB, MB divide by 1000, for KiB, MiB divide by 1024
            for (let nMultiple = 0, nApprox = fileSizeBytes / 1000; nApprox > 1; nApprox /= 1000, nMultiple++) {
                //sOutput = nApprox.toFixed(2) + " " + aMultiples[nMultiple] + " (" + fileSizeBytes + " bytes)";
                sOutput = nApprox.toFixed(2) + " " + aMultiples[nMultiple];
            }
            // end of optional code
            return sOutput
        },
        getFileSize(fileList) {
            let nBytes = 0,
                //Get the total count of files uploaded                
                numberOfFiles = fileList.length;
            for (let nFileId = 0; nFileId < numberOfFiles; nFileId++) {
                //Add size of each file to get total size of uploaded files
                nBytes += fileList[nFileId].size;
            }
            let sOutput = nBytes + " bytes";
            // optional code for multiples approximation
            const aMultiples = ["KiB", "MiB", "GiB", "TiB", "PiB", "EiB", "ZiB", "YiB"];

            for (let nMultiple = 0, nApprox = nBytes / 1024; nApprox > 1; nApprox /= 1024, nMultiple++) {
                sOutput = nApprox.toFixed(3) + " " + aMultiples[nMultiple] + " (" + nBytes + " bytes)";
            }
            // end of optional code
            return { numberOfFilesUploaded: numberOfFiles, totalSize: sOutput }

        },
    }
}
