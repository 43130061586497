<template>
  <v-container fluid class="py-0">
    <AutoLogout></AutoLogout>
    <div class="refreshButton">
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            elevation="3"
            color="indigo"
            fab
            dark
            bottom
            right
            small
            v-bind="attrs"
            v-on="on"
            @click="initialize"
          >
            <v-icon>mdi-refresh</v-icon>
          </v-btn>
        </template>
        Refresh Items
      </v-tooltip>
    </div>
    <v-row class="my-0 px-0 py-0">
      <v-col cols="12">
        <v-card flat color="white" hover :loading="loading">
          <v-card-title>
            <!-- <v-row>
              <v-col > -->
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search App"
              hide-details
            ></v-text-field>
            <!-- </v-col> -->
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-btn
                  elevation="3"
                  color="indigo"
                  dark
                  fab
                  absolute
                  small
                  bottom
                  right
                  v-on="on"
                  @click="addItem"
                >
                  <v-icon dark>mdi-plus</v-icon>
                </v-btn>
              </template>
              Add New Item
            </v-tooltip>
            <!-- </v-row> -->
          </v-card-title>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        v-for="item in filteredList"
        :key="item.credentials_id"
        cols="12"
        sm="12"
        md="2"
        lg="2"
        xl="2"
      >
        <v-card flat hover :color="color">
          <v-card-subtitle
            class="cardColor darken-3 white--text ma-0 pa-2"
            align="left"
          >
            {{ item.credentials.app_name }}
          </v-card-subtitle>
          <v-card-text align="left" class="pa-1">
            <v-row dense>
              <v-col cols="12">
                <custom-text
                  :textValue="item.credentials.user_name"
                  :tooltip="'Copy User Name'"
                  :isSoloInverted="true"
                  :customClass="'data-table-items'"
                  :allowCopy="true"
                  :isReadonly="true"
                  :hideDetails="true"
                >
                </custom-text>
              </v-col>
              <v-col cols="12">
                <custom-text
                  :textValue="item.credentials.password"
                  :tooltip="'Copy Password'"
                  :isSoloInverted="true"
                  :customClass="'data-table-items'"
                  :allowCopy="true"
                  :isPassWord="true"
                  :isReadonly="true"
                  :showPassword="true"
                  :hideDetails="true"
                >
                </custom-text>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions align="left" class="pa-1">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn icon @click="launch(item)" v-on="on">
                  <v-icon right dark> mdi-cloud-upload </v-icon>
                </v-btn>
              </template>
              Launch
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn icon @click="editItem(item)" v-on="on">
                  <v-icon right dark> mdi-pencil </v-icon>
                </v-btn>
              </template>
              Edit
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn icon @click="deleteItem(item)" v-on="on">
                  <v-icon right dark> mdi-delete </v-icon>
                </v-btn>
              </template>
              Delete
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn icon @click="viewHistory(item)" v-on="on">
                  <v-icon right dark> mdi-history </v-icon>
                </v-btn>
              </template>
              View Password History
            </v-tooltip>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
      scrollable
      persistent
    >
      <v-card :loading="loading">
        <v-card flat tile>
          <v-toolbar dark color="#000000">
            <v-btn icon dark @click="close">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>{{ formTitle }}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
              <v-btn dark text @click="save(false)" :disabled="!valid">
                Save
              </v-btn>
            </v-toolbar-items>
          </v-toolbar>
        </v-card>
        <v-form ref="formSave" v-model="valid" lazy-validation>
          <v-container dense>
            <v-row v-if="StateEnterprise">
              <v-combobox
                v-model="editedItem.shared_with"
                chips
                clearable
                label="Enter email to share"
                multiple
              >
                <template v-slot:selection="{ attrs, item, selected }">
                  <v-chip
                    v-bind="attrs"
                    :input-value="selected"
                    close
                    @click:close="removeChip(item)"
                  >
                    <strong>{{ item }}</strong
                    >&nbsp;
                  </v-chip>
                </template>
              </v-combobox>
            </v-row>
            <v-row>
              <v-col cols="6" sm="6" md="6">
                <v-text-field
                  v-model="editedItem.credentials.app_name"
                  label="App Name"
                  required
                  :rules="appNameRule"
                ></v-text-field>
              </v-col>
              <v-col cols="6" sm="6" md="6">
                <v-text-field
                  multi-line
                  v-model="editedItem.credentials.app_url"
                  label="App Url"
                  :rules="appUrlRule"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-text-field
                  v-model="editedItem.credentials.user_name"
                  label="User Name"
                  required
                  :rules="userNameRule"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="12" md="6">
                <v-text-field
                  multi-line
                  v-model="editedItem.credentials.password"
                  label="Click SHIELD to generate new or enter your own password"
                  required
                  :rules="passwordRule"
                >
                  <template v-slot:append>
                    <v-tooltip right>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          @click="getPassword"
                          color="blue"
                          v-bind="attrs"
                          v-on="on"
                        >
                          mdi-shield-refresh
                        </v-icon>
                      </template>
                      Generate New Password
                    </v-tooltip>
                  </template>
                </v-text-field>
              </v-col>
              <!-- </v-row>
            <v-row> -->
              <v-col cols="6" sm="6" md="3">
                <v-text-field
                  v-model="editedItem.length"
                  label="Length(16 default)"
                ></v-text-field>
              </v-col>
              <v-col cols="6" sm="6" md="3">
                <v-text-field
                  v-model="editedItem.excludeCharacters"
                  label="Exclude Characters"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row v-if="serviceCredentials">
              <v-col cols="12" sm="12" md="12">
                <v-text-field
                  v-model="editedItem.credentials.consumer_key"
                  label="Consumer Key or Id"
                  required
                  :rules="enterpriseRule"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="12">
                <v-text-field
                  v-model="editedItem.credentials.consumer_secret"
                  label="Consumer Secret or Key File"
                  required
                  :rules="enterpriseRule"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-textarea
                  outlined
                  label="Notes"
                  v-model="editedItem.credentials.notes"
                  :rules="notesRule"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogDelete" :max-width="dialogWidth">
      <v-card>
        <v-card-title class="headline"
          >Are you sure you want to delete this item?</v-card-title
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="error" text @click="closeDelete">Cancel</v-btn>
          <v-btn class="success" text @click="deleteItemConfirm">OK</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogAlert" :max-width="dialogWidth" persistent>
      <v-alert :type="alertType">
        <v-row align="center">
          <v-col class="grow">{{ alert_message }}</v-col>
          <v-col class="shrink">
            <v-btn @click="closeAlert">Exit</v-btn>
          </v-col>
        </v-row>
      </v-alert>
    </v-dialog>
    <v-dialog
      v-model="dialogHistory"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
      scrollable
      persistent
    >
      <v-card :loading="loading">
        <v-card flat tile>
          <v-toolbar dark color="#000000">
            <v-btn icon dark @click="closeHistory">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>History: {{ appName }}</v-toolbar-title>
          </v-toolbar>
        </v-card>
        <password-history :history="historicPasswords"></password-history>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import axios from "axios";
import AutoLogout from "@/components/AutoLogout.vue";
import CustomText from "@/components/Custom/CustomText.vue";
import PasswordHistory from "@/components/Custom/HistoricView.vue";
export default {
  name: "Vault",
  props: {
    serviceCredentials: { type: Boolean, default: false },
    closeDialog: { type: Boolean },
    closeDeleteDialog: { type: Boolean },
    loading: { type: Boolean, default: false },
    currentCredentials: { type: Array, default: () => [] },
    allCredentials: { type: Array, default: () => [] },
    credentialType: { type: String },
  },

  components: {
    AutoLogout,
    "custom-text": CustomText,
    "password-history": PasswordHistory,
  },
  data: () => ({
    color: "#EFEBE9",
    valid: true,
    appNameRule: [
      (v) => !!v || "Required field",
      (v) =>
        (v && v.length <= 30) || "App name must be less than 30 characters",
    ],
    appUrlRule: [
      (v) =>
        (v && v.length) > 2048
          ? "App url must be less than 2048 characters"
          : true,
    ],
    userNameRule: [
      (v) => !!v || "Required field",
      (v) =>
        (v && v.length <= 256) || "User name must be less than 256 characters",
    ],
    passwordRule: [
      (v) => !!v || "Required field",
      (v) =>
        (v && v.length <= 50) ||
        "Password length must be less than 50 characters",
    ],
    enterpriseRule: [
      (v) =>
        (v && v.length) > 200
          ? "Consumer Key and Secret should be less than 200. Let us know if this size need to be increased"
          : true,
    ],
    notesRule: [
      (v) =>
        (v && v.length) > 1000
          ? "We currently allow 1000 characters in notes"
          : true,
    ],

    dialog: false,
    dialogWidth: "600px",
    dialogDelete: false,
    dialogAlert: false,
    dialogHistory: false,
    alertType: "",
    search: "",
    appName: "",
    historicPasswords: [],
    editedIndex: -1,
    editedItem: {
      id: "",
      user_id: "",
      credential_type: "",
      credentials_id: "",
      credentials: {
        app_name: "",
        app_url: "",
        user_name: "",
        password: "",
        notes: "",
      },
      is_active: false,
      version: 0,
      length: 16,
      excludeCharacters: "",
      shared_with: [],
    },
    defaultCredentials: {
      app_name: "",
      app_url: "",
      user_name: "",
      password: "",
      notes: "",
    },
    alert_message: "",
  }),

  computed: {
    ...mapGetters([
      "StateUser",
      "StateDefaultPasswordLength",
      "StateExcludeCharacters",
      "StateEnterprise",
    ]),
    formTitle() {
      return this.editedIndex === -1 ? "New Item" : "Edit Item";
    },
    filteredList() {
      return this.currentCredentials.filter((x) => {
        return x.credentials.app_name
          .toLowerCase()
          .includes(this.search.toLowerCase());
      });
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    closeDialog: function () {
      this.initialize();
      this.valid = true;
      this.close();
    },
    closeDeleteDialog: function () {
      this.initialize();
      this.closeDelete();
    },
  },
  methods: {
    ...mapActions(["GeneratePassword"]),

    launch(item) {
      var app_url = item.credentials.app_url;
      if (app_url.startsWith("http://") || app_url.startsWith("https://")) {
        window.open(app_url);
      } else {
        var url = "http://" + app_url;
        window.open(url);
      }
    },
    initialize() {
      this.$emit("refresh");
    },
    addItem() {
      this.editedItem.credential_type = this.credentialType;
      this.valid = true;
      this.dialog = true;
    },
    editItem(item) {
      this.valid = true;
      this.setEditItems(item);
      this.dialog = true;
    },
    deleteItem(item) {
      this.setEditItems(item);
      this.dialogDelete = true;
    },
    async deleteItemConfirm() {
      await this.save(true);
      this.closeDelete();
    },
    close() {
      this.dialog = false;
      this.refreshEditItems();
    },
    closeDelete() {
      this.dialogDelete = false;
      this.refreshEditItems();
    },
    closeAlert() {
      this.dialogAlert = false;
      this.alert_message = "";
    },
    closeHistory() {
      this.dialogHistory = false;
      this.historicPasswords = [];
      this.appName = "";
    },
    setEditItems(item) {
      this.editedIndex = this.currentCredentials.indexOf(item);
      this.editedItem.id = item.id;
      this.editedItem.credentials_id = item.credentials_id;
      this.editedItem.credentials = item.credentials;
      this.editedItem.is_active = item.is_active;
      this.editedItem.version = item.version;
      this.editedItem.shared_with = item.shared_with;
      this.editedItem.credential_type = this.credentialType;
    },
    refreshEditItems() {
      this.editedItem.credentials = Object.assign({}, this.defaultCredentials);
      this.editedItem.id = "";
      this.editedItem.credential_type = "";
      this.editedItem.credentials_id = "";
      this.editedItem.is_active = false;
      this.editedItem.version = 0;
      this.editedItem.length = 16;
      this.editedItem.excludeCharacters = "";
      this.editedItem.shared_with = [];
      this.editedIndex = -1;
    },
    removeChip(item) {
      this.editedItem.shared_with.splice(
        this.editedItem.shared_with.indexOf(item),
        1
      );
      this.editedItem.shared_with = [...this.editedItem.shared_with];
    },
    save(isDelete) {
      if (!isDelete && !this.$refs.formSave.validate()) return;
      this.valid = false;
      var item = this.editedItem;
      this.$emit("save", { item, isDelete });
    },

    async getPassword() {
      try {
        const length = this.editedItem.length;
        const excludeCharacters = this.editedItem.excludeCharacters;
        let payload = new URLSearchParams();
        payload.append("length", length ?? this.StateDefaultPasswordLength);
        payload.append(
          "exclude_characters",
          excludeCharacters ?? this.StateExcludeCharacters
        );
        let pass = await axios
          .post("GeneratePassword", payload)
          .then(function (response) {
            return response.data;
          });
        this.editedItem.credentials.password = pass;
      } catch (error) {
        this.alert_message = "Error generating new password" + "| " + error;
        this.alertType = "error";
        this.dialogAlert = true;
      }
    },
    viewHistory(item) {
      this.historicPasswords = this.allCredentials.filter(
        (x) => x.credentials_id === item.credentials_id && !x.is_active
      );
      this.appName = item.credentials.app_name;
      this.dialogHistory = true;
    },
  },
};
</script>
<style scoped>
.data-table-items {
  font-family: "Roboto", sans-serif;
  color: #424242;
}

.refreshButton {
  position: fixed;
  bottom: 35px;
  right: 1px;
  z-index: 9;
}

.cardColor {
  background-color: #424242;
}
</style>
