<template>
  <V-Card flat>
    <v-card-title>
      <h4>Total Pastes items found: {{ pastesCount }}</h4>
    </v-card-title>
    <v-card-subtitle align="left">
      <h4>
        A paste is nothing more than text quite literally pasted onto a website
        whereupon it receives its own unique URL so that it can then be shared
        with others who may want to view the paste. The contents of a paste
        could be anything - a recipe, a block of code or of particular interest
        here, a dump of breached accounts. If you see no pastes here it means
        your breached account, if any, haven't landed to pastes site.
      </h4>
    </v-card-subtitle>
    <v-data-table
      v-if="pastesCount > 0"
      class="elevation-0"
      dense
      :headers="headers"
      :items="pastes"
      sort-by="Source"
      :search="search"
      :loading="loading"
      loading-text="Loading... Please wait"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-btn
            height="30px"
            tile
            color="#424242"
            class="white--text"
            @click="initialize"
          >
            Refresh
          </v-btn>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search Vault"
            single-line
            hide-details
          ></v-text-field>
        </v-toolbar>
      </template>

      <!-- Data Tables items styling-->
      <template class="" v-slot:[`item.Source`]="{ item }">
        <span class="data-table-items">
          {{ item.Source }}
        </span>
      </template>

      <template class="" v-slot:[`item.Id`]="{ item }">
        <span class="data-table-items">
          {{ item.Id }}
        </span>
      </template>

      <template class="" v-slot:[`item.Title`]="{ item }">
        <span class="data-table-items">
          {{ item.Title }}
        </span>
      </template>

      <template class="" v-slot:[`item.Date`]="{ item }">
        <span class="data-table-items">
          {{ item.Date }}
        </span>
      </template>
      <template class="" v-slot:[`item.EmailCount`]="{ item }">
        <span class="data-table-items">
          {{ item.EmailCount }}
        </span>
      </template>
    </v-data-table>
    <v-card-actions>
      <a
        target="_blank"
        href="https://haveibeenpwned.com"
        rel="noopener"
        align="right"
        ><h6>Courtsey: Have I Been Pwned</h6>
      </a>
    </v-card-actions>
  </V-Card>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";
export default {
  name: "Pastes",
  data: () => ({
    loading: false,
    dialogAlert: false,
    alertType: "",
    alert_message: "",
    search: "",
    headers: [
      {
        text: "Source",
        align: "start",
        sortable: true,
        value: "Source",
        class: "secondary white--text",
      },
      {
        text: "Source Id",
        value: "Id",
        sortable: false,
        class: "secondary white--text",
      },
      {
        text: "Title",
        value: "Title",
        sortable: false,
        class: "secondary white--text",
      },
      {
        text: "Date Pasted",
        value: "Date",
        sortable: false,
        class: "secondary white--text",
      },
      {
        text: "Email Count",
        value: "EmailCount",
        sortable: false,
        class: "secondary white--text",
      },
    ],
    pastes: [],
  }),

  computed: {
    ...mapGetters(["StateUser"]),
    pastesCount() {
      return this.pastes.length;
    },
  },

  created() {
    this.initialize();
  },
  methods: {
    async initialize() {
      try {
        if (this.StateUser.id !== "") {
          this.loading = true;
          var url = "GetPastesForUser/" + this.StateUser.email_id;
          var data = await axios.get(url).then((resp) => {
            return resp.data;
          });
          this.pastes = data;
          this.loading = false;
        }
      } catch {
        this.loading = false;
      }
    },
  },
};
</script>
<style scoped>
.data-table-items {
  font-family: Arial, Helvetica, sans-serif;
  color: #424242;
  font-size: 14px;
}
</style>