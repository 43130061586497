<template>
  <div class="text-center">
    <v-dialog v-model="dialog" width="500">
      <v-card elevation="3" outlined tile>
        <br />
        <v-card-title v-if="invalidOtp">{{ invalidMessage }}</v-card-title>
        <v-card-text>
          <v-container>
            <v-card flat class="img-container">
              <v-img dark src="@/assets/firstvaultwhitebg - small.png" />
            </v-card>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-text-field
                  v-model="verificationCode"
                  label="Please enter 6 digit code here"
                  required
                  :rules="codeRules"
                >
                </v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-btn text @click="submitCode">Submit</v-btn>
          <v-btn text @click="close">Exit</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import axios from "axios";
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters(["StateUser"]),
  },
  data() {
    return {
      dialog: true,
      verificationCode: "",
      invalidOtp: false,
      invalidMessage: "Invalid Otp, please exit and login again",
      codeRules: [
        (v) => !!v || "Required",
        (v) => (v && v.length === 6) || "Code must be 6 digits",
      ],
    };
  },
  methods: {
    async submitCode() {
      const code = new URLSearchParams();
      code.append("verification_code", this.verificationCode);
      code.append("email_id", this.StateUser.email_id);
      code.append("id", this.StateUser.id);
      try {
        let response = await axios
          .post("ValidateLoginOtp", code)
          .then(function (response) {
            if (response !== undefined) {
              return response.data;
            }
          });
        if (response === true) {
          this.$emit("otpVaidated");
        } else {
          this.invalidOtp = true;
        }
      } catch (Error) {
        this.invalidOtp = true;
      }
    },
    close() {
      this.$router.push("/");
    },
  },
};
</script>
<style scoped>
.img-container {
  text-align: center;
  display: block;
}
</style>
