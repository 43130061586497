<template>
  <v-container fluid class="bottomPadding">
    <v-row justify-md="center" justify-sm="start">
      <v-col>
        <div class="c29">
          <p class="c24 c6"><span class="c0"></span></p>
          <p class="c24"><span class="c0">TERMS OF USE</span></p>
          <p class="c5 c6"><span class="c2"></span></p>
          <p class="c5">
            <span class="c3">Welcome to the portal </span
            ><span class="c22 c33">&nbsp;</span
            ><span class="c3 c30"
              ><a class="c15" href="https://firstvault.net"
                >https://firstvault.net</a
              ></span
            ><span class="c3">&nbsp; (the &ldquo;</span
            ><span class="c3 c10">Website</span
            ><span class="c3"
              >&rdquo;) and the mobile application, First Vault.&nbsp; The
              content of this Website is the property of HEDGE VISION SYSTEMS
              PRIVATE LIMITED, having its registered office at 603, GM IT Park,
              Sector 142, Noida, Uttar Pradesh 201305 (the &ldquo;</span
            ><span class="c3 c10">Company</span
            ><span class="c3"
              >&rdquo;).&nbsp; The Company is in the business of providing data
              storage and data security services via this Website and the
              Application (&ldquo;</span
            ><span class="c3 c10">Services</span
            ><span class="c2">&rdquo;). </span>
          </p>
          <p class="c5 c6"><span class="c2"></span></p>
          <p class="c5">
            <span class="c17 c3 c10"
              >For the purposes of the Terms of Use, the Website and the
              Application shall be collectively referred to as the Portal.
            </span>
          </p>
          <p class="c5 c6"><span class="c2"></span></p>
          <p class="c5">
            <span class="c3"
              >Your use of the Website and services and tools are governed by
              the following&nbsp;terms and conditions (&ldquo;</span
            ><span class="c3 c10">Terms of Use</span
            ><span class="c3"
              >&rdquo;). If you do not agree to these Terms of Use, you may not
              use this Website. By using this Website, you signify your
              agreement to these Terms of Use as well as the Website&#39;s
              privacy policy (which is hereby incorporated by reference herein)
              (the &ldquo;</span
            ><span class="c3 c10">Privacy Policy</span
            ><span class="c2"
              >&rdquo;). These Terms of Use set out the legally binding terms of
              the Services available on the Website as well as the terms of use
              of this Website.</span
            >
          </p>
          <p class="c5 c6"><span class="c2"></span></p>
          <p class="c5">
            <span class="c3"
              >For the purpose of these Terms of Use, wherever the context so
              requires,</span
            ><span class="c3 c10">&nbsp;</span><span class="c3">&ldquo;</span
            ><span class="c3 c10">you</span
            ><span class="c3">&rdquo;&nbsp;or&nbsp;&ldquo;</span
            ><span class="c3 c10">your</span
            ><span class="c3"
              >&rdquo;&nbsp;shall mean any natural or legal person who visits
              the website and/or transacts business on the Website by providing
              registration information. The Company allows you to surf
              the&nbsp;Website without registering on the Website. The
              terms&nbsp;&ldquo;</span
            ><span class="c3 c10">we</span><span class="c3">&rdquo;,</span
            ><span class="c3 c10">&nbsp;</span><span class="c3">&ldquo;</span
            ><span class="c3 c10">us</span><span class="c3">&rdquo;,</span
            ><span class="c3 c10">&nbsp;</span><span class="c3">&ldquo;</span
            ><span class="c3 c10">our</span
            ><span class="c2">&rdquo;&nbsp;shall mean the Company.</span>
          </p>
          <p class="c5 c6"><span class="c2"></span></p>
          <a id="id.gjdgxs"></a>
          <p class="c5">
            <span class="c1"
              >PLEASE READ THESE TERMS OF USE CAREFULLY BEFORE USING THIS
              WEBSITE</span
            >
          </p>
          <p class="c5 c6"><span class="c1"></span></p>
          <p class="c5">
            <span class="c2"
              >These Terms of Use along with the Privacy Policy extend to users
              who visit the Website but do not transact business on the Website
              as well as users who subsequently register on the Website to
              transact business on the Website. The Company reserves the right
              to modify or terminate any portion of the Website or the Services
              offered by the Company for any reason, without notice and without
              liability to you or any third party. You are responsible for
              regularly reviewing these Terms of Use so that you will be
              apprised of any changes. Nothing in these Terms of Use should be
              construed to confer any rights on any third party beneficiaries. </span
            ><a id="id.30j0zll"></a>
          </p>
          <p class="c5 c6"><span class="c2"></span></p>
          <ol class="c7 lst-kix_list_1-0 start" start="1">
            <li class="c5 c9 li-bullet-0">
              <span class="c1">Your Account and Registration</span
              ><span class="c2">.</span>
            </li>
          </ol>
          <p class="c5 c6 c20"><span class="c2"></span></p>
          <ol class="c7 lst-kix_list_1-1 start" start="1">
            <li class="c5 c9 li-bullet-0">
              <span class="c2"
                >You will be required to register on the Website and create an
                account prior to the completion of any transaction / purchase on
                the Website.</span
              >
            </li>
          </ol>
          <p class="c4"><span class="c2"></span></p>
          <ol class="c7 lst-kix_list_1-1" start="2">
            <li class="c5 c9 li-bullet-0">
              <span class="c2"
                >Use of the Website is available only to persons who can form
                legally binding&nbsp;contracts under Indian Contract Act, 1872.
                Persons who are &quot;incompetent to contract&quot; within the
                meaning of the Indian Contract Act, 1872 including
                minors,&nbsp;un-discharged insolvents etc. are not eligible to
                use the Website. If you are&nbsp;a minor i.e. under the age of
                18 years, you shall not register as a user of the Website and
                shall not transact on or use the Website. As a minor if you wish
                to use or transact on the Website, such use or transaction may
                be made by your&nbsp;legal guardian or parents. The Company
                reserves the right to terminate&nbsp;your membership and / or
                refuse to provide you with access to the Website if it
                is&nbsp;brought to the Company&#39;s notice or if it is
                discovered that you are under the age of 18&nbsp;years.</span
              >
            </li>
          </ol>
          <p class="c4"><span class="c2"></span></p>
          <ol class="c7 lst-kix_list_1-1" start="3">
            <li class="c5 c9 li-bullet-0">
              <span class="c2"
                >To register onto the Website you will have to provide your
                Personal Information (as defined in the Privacy Policy) and
                choose a user name and password.</span
              >
            </li>
          </ol>
          <p class="c4"><span class="c2"></span></p>
          <ol class="c7 lst-kix_list_1-1" start="4">
            <li class="c5 c9 li-bullet-0">
              <span class="c2"
                >Registration is only a one time process and if you have
                previously registered, you shall login /sign in to your
                registered account.</span
              >
            </li>
          </ol>
          <p class="c4"><span class="c2"></span></p>
          <ol class="c7 lst-kix_list_1-1" start="5">
            <li class="c5 c9 li-bullet-0">
              <span class="c2"
                >Membership on the Website is free for all users. However, a fee
                may be charged for subscribing to any new/additional services
                offered by the Company. The Company reserves the right to change
                the fee policy applicable to such paid membership. Changes to
                the fee policy will be posted on the Website and such changes
                shall become effective immediately after they are posted on the
                Website.
              </span>
            </li>
          </ol>
          <p class="c5 c6"><span class="c2"></span></p>
          <ol class="c7 lst-kix_list_1-0" start="2">
            <li class="c5 c9 li-bullet-0"><span class="c1">Access.</span></li>
          </ol>
          <p class="c5 c6 c20"><span class="c2"></span></p>
          <ol class="c7 lst-kix_list_1-1 start" start="1">
            <li class="c5 c9 li-bullet-0">
              <!-- <p class="c4"><span class="c1"></span></p>
    <ol class="c7 lst-kix_list_38-0 start" start="1">
      <li class="c5 c9 li-bullet-0"> -->
              <span class="c2"
                >You are responsible for maintaining the confidentiality of your
                account and password and for restricting and preventing
                unauthorized access to your account. You agree to accept
                responsibility for all activities that occur under your account
                or password.
              </span>
            </li>
          </ol>
          <p class="c5 c6 c12"><span class="c2"></span></p>
          <ol class="c7 lst-kix_list_1-1" start="2">
            <li class="c5 c9 li-bullet-0">
              <span class="c2"
                >You should take all necessary steps to ensure that the password
                is kept confidential and secure and should inform the Company
                immediately if you have any reason to believe that your password
                has become known to anyone else, or if the password is being, or
                is likely to be, used in an unauthorized manner.
              </span>
            </li>
          </ol>
          <p class="c5 c6 c12"><span class="c2"></span></p>
          <ol class="c7 lst-kix_list_1-1" start="3">
            <li class="c5 c9 li-bullet-0">
              <span class="c2"
                >You can access and update account details on the Website. You
                shall ensure that the account details provided to the Website
                are correct and complete at all times. The Website reserves the
                right to refuse access to the Website, terminate accounts,
                remove or edit content at any time without notice to you.</span
              >
            </li>
          </ol>
          <p class="c4"><span class="c2"></span></p>
          <ol class="c7 lst-kix_list_1-1" start="4">
            <li class="c5 c9 li-bullet-0">
              <span class="c2"
                >The Company will endeavor to ensure that access to and
                availability of the Website remains uninterrupted and error
                free. However, access to the Website may occasionally be
                suspended or restricted to allow for repairs, maintenance, or
                for the introduction of new facilities and services.</span
              >
            </li>
          </ol>
          <p class="c5 c6"><span class="c2"></span></p>
          <a id="id.1fob9te"></a>
          <ol class="c7 lst-kix_list_1-0" start="3">
            <li class="c5 c9 li-bullet-0">
              <span class="c1">Limited License for Access.</span>
            </li>
          </ol>
          <p class="c4"><span class="c1"></span></p>
          <ol class="c7 lst-kix_list_38-0 start" start="1">
            <li class="c5 c9 li-bullet-0">
              <span class="c2"
                >The Company grants you a limited license to access and make
                personal use of this website, but not to download (other than
                page caching) or modify it(or any portion thereof), except with
                prior written consent of the Company and / or its affiliates, as
                may be applicable.</span
              >
            </li>
          </ol>
          <p class="c4"><span class="c2"></span></p>
          <ol class="c7 lst-kix_list_38-0" start="2">
            <li class="c5 c9 li-bullet-0">
              <span class="c2"
                >This license does not include any resale or commercial use of
                this website or its contents; any collection and use of any
                product listings, descriptions, or prices, any derivative use of
                this Website or its contents, any downloading or copying of
                account information, or any use of data mining, robots, or
                similar data gathering and extraction tools. You will not
                advertise or use any designs, diagrams, data or any other symbol
                (&ldquo;</span
              ><span class="c0">Content</span
              ><span class="c2"
                >&rdquo;) uploaded by the Company or by any other user of the
                Website for any purposes including commercial purposes.
              </span>
            </li>
          </ol>
          <p class="c4"><span class="c2"></span></p>
          <ol class="c7 lst-kix_list_38-0" start="3">
            <li class="c5 c9 li-bullet-0">
              <span class="c2"
                >This Website or any portion thereof including but not limited
                to any Content, &nbsp;copyrighted material, trademarks, or other
                proprietary information may not be reproduced, duplicated,
                copied, sold, resold, visited, distributed or otherwise
                exploited for any purpose without prior written consent of the
                Company and / or its affiliates, as may be applicable.</span
              >
            </li>
          </ol>
          <p class="c4"><span class="c2"></span></p>
          <ol class="c7 lst-kix_list_38-0" start="4">
            <li class="c5 c9 li-bullet-0">
              <span class="c2"
                >You will not frame or use framing techniques to enclose any
                Content, trademark, logo, or other proprietary information
                (including images, text, page layout, or form) of the Company
                and its affiliates without prior written consent. You will not
                use any meta tags or any other &quot;hidden text&quot; utilizing
                the Company&rsquo;s or its affiliates&#39; names or trademarks
                without the prior written consent of the Company and / or its
                affiliates, as may be applicable. Any unauthorized use will
                terminate the permission or license granted by the Company and /
                or its affiliates, as may be applicable.</span
              >
            </li>
          </ol>
          <p class="c4"><span class="c2"></span></p>
          <ol class="c7 lst-kix_list_38-0" start="5">
            <li class="c5 c9 li-bullet-0">
              <span class="c2"
                >You have been granted a limited, revocable, and non-exclusive
                right to create a hyperlink to the home page of the Website as
                long as the link does not portray the Company, its affiliates,
                or their products or services in a false, misleading,
                derogatory, or otherwise offensive manner. You will not use any
                logo or other proprietary graphic or trademark of the
                Company/Website as part of the link without prior written
                consent of the Company and / or its affiliates, as may be
                applicable.</span
              >
            </li>
          </ol>
          <p class="c4"><span class="c2"></span></p>
          <ol class="c7 lst-kix_list_38-0" start="6">
            <li class="c5 c9 li-bullet-0">
              <span class="c2"
                >You shall not use the Website in any way that causes, or may be
                likely to cause damage or impairment to the Website or in any
                manner harms the Company or any other person or entity (as
                determined by the Company in its sole discretion) or interrupt
                free access to it in any way. You shall not use the Website to
                host, display, upload, post, submit, distribute, modify,
                publish, transmit, update or share any information that:</span
              >
            </li>
          </ol>
          <p class="c5 c6"><span class="c2"></span></p>
          <ol class="c7 lst-kix_list_45-0 start" start="1">
            <li class="c5 c18 li-bullet-1">
              <span class="c2"
                >Belongs to another person and to which You do not have a legal
                or proprietary right;</span
              >
            </li>
            <li class="c5 c18 li-bullet-1">
              <span class="c2"
                >Is in violation of the intellectual property rights of a third
                person;
              </span>
            </li>
            <li class="c5 c18 li-bullet-1">
              <span class="c2">Is harmful to minors;</span>
            </li>
            <li class="c5 c18 li-bullet-2">
              <span class="c2"
                >Deceiving or misleading the receiver about the origin of the
                Content or which communicates any Content which is grossly
                offensive and menacing in nature;
              </span>
            </li>
            <li class="c5 c18 li-bullet-2">
              <span class="c2"
                >Contains software viruses or other computer code, files or
                programs designed to interrupt, destroy or limit the
                functionality of any computer resource;</span
              >
            </li>
            <li class="c5 c18 li-bullet-1">
              <span class="c2"
                >Threatens the unity, integrity, defence, security or
                sovereignty of India, friendly relations with foreign states, or
                to public order or causes incitement to the commission of any
                cognizable offence or prevents investigation of any offence or
                is insulting to another nation;</span
              >
            </li>
            <li class="c5 c18 li-bullet-2">
              <span class="c2"
                >Is deemed to be information which is grossly harmful,
                blasphemous, defamatory, pornographic, pedophilic, invasive of
                another&rsquo;s privacy, hateful, false, misleading, offensive,
                obscene, sexually explicit, menacing, unlawful, libelous,
                threatening, racially and ethnically &nbsp;objectionable,
                disparaging, relating or encouraging money laundering or
                gambling or harassing for the purposes of the Terms;</span
              >
            </li>
            <li class="c5 c18 li-bullet-2">
              <span class="c2"
                >May impediment the technical functioning of the Site such as
                computer viruses, trojans, worms or any other malicious
                software;
              </span>
            </li>
            <li class="c5 c18 li-bullet-1">
              <span class="c2"
                >Directly or indirectly promotes illegal distribution of the
                copyrighted works belonging to a third party;
              </span>
            </li>
            <li class="c5 c18 li-bullet-1">
              <span class="c2"
                >Contains access to restricted pages of the Site;</span
              >
            </li>
            <li class="c5 c18 li-bullet-2">
              <span class="c2"
                >Provides material which exploits people under the age of 18
                years in a sexual or violent manner, and which solicits personal
                information from people under the age of 18;</span
              >
            </li>
            <li class="c5 c18 li-bullet-1">
              <span class="c2">Promotes any kind of illegal activity; or</span>
            </li>
            <li class="c5 c18 li-bullet-2">
              <span class="c2"
                >Which is in contravention of the applicable laws of the country
                and in violation of these Terms.</span
              >
            </li>
          </ol>
          <p class="c4"><span class="c2"></span></p>
          <ol class="c7 lst-kix_list_38-0" start="7">
            <li class="c5 c9 li-bullet-0">
              <span class="c2"
                >In the event that a user has posted any of the content
                mentioned above, the Company reserves the right to cause the
                user to disable any of the Content within 36 hours of posting
                such Content and the user shall be obligated to disable the same
                without raising it as a matter of dispute. The Company also
                reserves the right to store such content in its servers for at
                least 90 days for any investigative purpose. You shall not
                attempt to gain unauthorized access to the Site or the servers
                on which the Site is stored. If we trace any such attempt to any
                user, we reserve the right to disable the Login with immediate
                effect and take appropriate legal action.</span
              >
            </li>
          </ol>
          <p class="c4"><span class="c2"></span></p>
          <ol class="c7 lst-kix_list_38-0" start="8">
            <li class="c5 c9 li-bullet-0">
              <span class="c2"
                >You will not engage in any form of antisocial, disrupting, or
                destructive acts, including &quot;flaming,&quot;
                &quot;spamming,&quot; &quot;flooding,&quot;
                &quot;trolling,&quot; &quot;phishing&quot; and
                &quot;griefing&quot; as those terms are commonly understood and
                used on the Internet.</span
              >
            </li>
          </ol>
          <p class="c4"><span class="c2"></span></p>
          <ol class="c7 lst-kix_list_38-0" start="9">
            <li class="c5 c9 li-bullet-0">
              <span class="c2"
                >You will not delete or modify any Content of the Website and/or
                Services including but not limited to, legal notices,
                disclaimers or proprietary notices such as copyright or
                trademark symbols, logos, that you do not own or have express
                permission to modify.</span
              >
            </li>
          </ol>
          <p class="c5 c6"><span class="c2"></span></p>
          <a id="id.3znysh7"></a>
          <ol class="c7 lst-kix_list_1-0" start="4">
            <li class="c5 c9 li-bullet-0">
              <span class="c1">Platform for Transaction and Communication</span
              ><span class="c8 c3">.</span>
            </li>
          </ol>
          <p class="c4"><span class="c1"></span></p>
          <ol class="c7 lst-kix_list_44-0 start" start="1">
            <li class="c5 c9 li-bullet-0">
              <span class="c2"
                >We expressly waive all liability arising out of claims filed by
                a third party for infringement of the rights in their data
                &nbsp;arising out of Content uploaded by a user on the Website
                and We shall not defend or join any proceeding, in any capacity
                before any forum in relation to such claims.</span
              >
            </li>
          </ol>
          <p class="c4"><span class="c2"></span></p>
          <ol class="c7 lst-kix_list_44-0" start="2">
            <li class="c5 c9 li-bullet-0">
              <span class="c2"
                >The Company does not undertake any investigative process for
                ascertaining the rights of the user on any Content uploaded on
                the Website by the user. The user represents that any Content
                uploaded by him/her on the Website is the original content
                developed by the user and is not reproduced or copied and does
                not contain elements from any content belonging to any third
                party.
              </span>
            </li>
          </ol>
          <p class="c5 c6"><span class="c2"></span></p>
          <ol class="c7 lst-kix_list_44-0" start="3">
            <li class="c5 c9 li-bullet-0">
              <span class="c2"
                >The Company does not make any representation or warranty as to
                specifics (such&nbsp;as quality, value, salability, etc) of the
                products or services proposed to be offered on the Website.
              </span>
            </li>
          </ol>
          <p class="c5 c6"><span class="c2"></span></p>
          <ol class="c7 lst-kix_list_44-0" start="4">
            <li class="c5 c9 li-bullet-0">
              <span class="c2"
                >Save and except the Content uploaded by the Company, the
                Company does not at any point of time during any transaction
                between you&nbsp;and the Company on the Website gain title to or
                have any rights&nbsp;or claims over the Content uploaded by you.
                The Company is not responsible for unsatisfactory
                or&nbsp;delayed performance of services or damages or delays as
                a result of products&nbsp;which are out of stock, unavailable or
                back ordered.</span
              >
            </li>
          </ol>
          <p class="c5 c6"><span class="c2"></span></p>
          <a id="id.2et92p0"></a>
          <ol class="c7 lst-kix_list_1-0" start="5">
            <li class="c5 c12 c27 li-bullet-3">
              <span class="c1">Payment.</span>
            </li>
          </ol>
          <p class="c5 c6 c20"><span class="c2"></span></p>
          <p class="c5 c25">
            <span class="c2"
              >As and when the Company decides to offer Services involving
              remittance of payment, the Company may offer multiple payment
              options to you on the Website including but not limited to:</span
            >
          </p>
          <p class="c5 c6"><span class="c2"></span></p>
          <ol class="c7 lst-kix_list_32-0 start" start="1">
            <li class="c5 c14 li-bullet-0">
              <span class="c0">Online Payment:</span
              ><span class="c2"
                >&nbsp;You can make online payments using your credit card or
                debit card or through net banking (RTGS/NEFT/IMPS);</span
              >
            </li>
          </ol>
          <p class="c5 c6 c32"><span class="c2"></span></p>
          <ol class="c7 lst-kix_list_32-0" start="2">
            <li class="c5 c14 li-bullet-0">
              <span class="c0">Offline Payment:</span
              ><span class="c2"
                >&nbsp;You can complete the order online and then make payments
                to the Company by cash on delivery to the delivery personnel.
              </span>
            </li>
          </ol>
          <p class="c5 c6"><span class="c2"></span></p>
          <ol class="c7 lst-kix_list_1-0" start="6">
            <li class="c5 c9 li-bullet-0">
              <span class="c1">Copyright and Database Rights</span
              ><span class="c2">.</span>
            </li>
          </ol>
          <p class="c4"><span class="c1"></span></p>
          <ol class="c7 lst-kix_list_1-1 start" start="1">
            <li class="c5 c9 li-bullet-0">
              <span class="c2"
                >All content included on the Website, such as photographs, text,
                graphics, logos, button icons, images and software, is the
                exclusive property of the Company, its affiliates or its Content
                suppliers. You shall not use the Content uploaded by other users
                or the Company without the prior written permission of the
                Company which may be denied at its discretion.</span
              >
            </li>
          </ol>
          <p class="c4"><span class="c1"></span></p>
          <ol class="c7 lst-kix_list_1-1" start="2">
            <li class="c5 c9 li-bullet-0">
              <span class="c2"
                >The compilation of all content on this Website is the exclusive
                property of the Company and You expressly grant the Company an
                exclusive, world-wide perpetual license to all Content posted by
                you to the Website.</span
              >
            </li>
          </ol>
          <p class="c4"><span class="c1"></span></p>
          <ol class="c7 lst-kix_list_1-1" start="3">
            <li class="c5 c9 li-bullet-0">
              <span class="c2"
                >All software used on this Website is the property of the
                Company or its software suppliers. You shall not systematically
                extract and/or re-utilize parts of the contents of the Website
                without the Company&rsquo;s express written consent. In
                particular, you shall not utilize any data mining, robots, or
                similar data gathering and extraction tools to extract (either
                once or a number of times) for re-utilization of any substantial
                parts of this Website, without the Company&rsquo;s prior and
                express written consent.</span
              >
            </li>
          </ol>
          <p class="c4"><span class="c1"></span></p>
          <ol class="c7 lst-kix_list_1-1" start="4">
            <li class="c5 c9 li-bullet-0">
              <span class="c2"
                >You shall not create and/or publish your own database that
                features substantial parts of this Website (e.g. prices and
                product listings) without the Company&rsquo;s express written
                consent. Your personal and non-commercial use of this Website
                shall be subjected to the following restriction (i) you may not
                modify any content of the Website, including but not limited to,
                any public display, description, performance, sale, rental,
                pricing of the product; (ii) you may not decompile, reverse
                engineer, or disassemble the content, or (c) remove any
                copyright, trademark registration, or other proprietary notices
                from the content. You further agree not to access or use this
                Website in any manner that may be harmful to the operation of
                this Website or its content.</span
              >
            </li>
          </ol>
          <p class="c4"><span class="c1"></span></p>
          <ol class="c7 lst-kix_list_1-0" start="7">
            <li class="c5 c9 li-bullet-0">
              <span class="c1">Trademarks</span><span class="c2">.</span>
            </li>
          </ol>
          <p class="c4"><span class="c1"></span></p>
          <p class="c5 c29">
            <span class="c2"
              >Any marks indicated on the Website are trademarks or registered
              trademarks of the Company in the Indian jurisdictions. All other
              trademarks not owned by the Company that appear on this Website
              are the property of their respective owners, who may or may not be
              affiliated with, connected to, or sponsored by the Company</span
            ><span class="c3 c10 c17">.</span>
          </p>
          <p class="c5 c6"><span class="c3 c23"></span></p>
          <ol class="c7 lst-kix_list_1-0" start="8">
            <li class="c5 c9 li-bullet-0">
              <span class="c1">Communications</span><span class="c8 c3">.</span>
            </li>
          </ol>
          <p class="c4"><span class="c1"></span></p>
          <ol class="c7 lst-kix_list_1-1 start" start="1">
            <li class="c5 c9 li-bullet-0">
              <!-- <ol class="c7 lst-kix_list_1-1" start="4">
      <li class="c5 c9 li-bullet-0"> -->
              <!-- <ol class="c7 lst-kix_list_44-0 start" start="1">
      <li class="c5 c9 li-bullet-0"> -->
              <span class="c2"
                >Visiting the Website or sending emails to the Company
                constitutes electronic communication by you with the Company.
                The Company communicates with you by email or by posting notices
                on the Website. For contractual purposes, you consent to receive
                communications from the Company electronically and agree that
                all agreements, notices, disclosures and other communications
                that are provided to you electronically satisfy any legal
                requirement stipulating that those communications be in writing.
                This condition does not affect your statutory rights.</span
              >
            </li>
          </ol>
          <p class="c4"><span class="c2"></span></p>
          <ol class="c7 lst-kix_list_1-1" start="2">
            <li class="c5 c9 li-bullet-0">
              <span class="c2"
                >You understand that once you register on the Website, you will
                receive short message service (&ldquo;</span
              ><span class="c0">SMS</span
              ><span class="c2"
                >&rdquo;) messages from the Company on your registered mobile
                number. These messages could relate to your registration, or any
                updates and promotions that are undertaken by the Company.
                Please note that the Company will send these SMS messages only
                to the registered mobile number or such other number that you
                may designate for any particular purpose.
              </span>
            </li>
          </ol>
          <p class="c5 c6 c11"><span class="c0"></span></p>
          <ol class="c7 lst-kix_list_1-0" start="9">
            <li class="c5 c9 li-bullet-0">
              <span class="c1">Termination.</span>
            </li>
          </ol>
          <p class="c4"><span class="c0"></span></p>
          <ol class="c7 lst-kix_list_1-1 start" start="1">
            <li class="c5 c9 li-bullet-0">
              <span class="c2"
                >In the event that you breach these Terms of Use or in the event
                that any other user or a third party reports violation of any of
                its rights as a result of your use of the Services, the Company
                reserves the right, to suspend or terminate such your access to
                the Website with or without notice to you, and to exercise any
                other remedy available under the law.
              </span>
            </li>
          </ol>
          <p class="c5 c6 c16"><span class="c2"></span></p>
          <ol class="c7 lst-kix_list_1-1" start="2">
            <li class="c5 c9 li-bullet-0">
              <span class="c2"
                >The Company shall have the right to indefinitely suspend your
                access on the Website and/or refuse to provide you access to the
                Website if:</span
              >
            </li>
          </ol>
          <p class="c5 c6"><span class="c2"></span></p>
          <ol class="c7 lst-kix_list_37-0 start" start="1">
            <li class="c5 c14 li-bullet-0">
              <span class="c2"
                >If you engage in or are suspected of engaging in any illegal,
                fraudulent or abusive activity; or</span
              >
            </li>
          </ol>
          <p class="c5 c6 c21"><span class="c2"></span></p>
          <ol class="c7 lst-kix_list_37-0" start="2">
            <li class="c5 c14 li-bullet-0">
              <span class="c2"
                >If you provide any information that is untrue, inaccurate, not
                current or incomplete in any respect or we have reasonable
                grounds to suspect that such information is untrue, inaccurate,
                not current or incomplete, or not in accordance with the Terms
                of Use.</span
              >
            </li>
          </ol>
          <p class="c5 c6"><span class="c2"></span></p>
          <ol class="c7 lst-kix_list_1-1" start="3">
            <li class="c5 c9 li-bullet-0">
              <span class="c2"
                >Upon suspension or termination, your right to avail the
                Services on the Website shall immediately cease and the Company
                reserves the right to remove or delete information regarding you
                that is available with the Company, including but not limited to
                login, account information and information posted by you.</span
              >
            </li>
          </ol>
          <p class="c5 c29">
            <span class="c0"
              >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span
            >
          </p>
          <ol class="c7 lst-kix_list_1-0" start="10">
            <li class="c5 c9 li-bullet-0">
              <span class="c1">Amendments.</span>
            </li>
          </ol>
          <p class="c4"><span class="c0"></span></p>
          <p class="c5 c29">
            <span class="c2"
              >The Company reserves the right to make changes to our Website,
              policies, and these Terms of Use at any time. You will be subject
              to the Terms of Use in force at the time that you use the website
              or at the time you place orders on our Website, unless any change
              to those policies or these conditions is required to be made by
              law or government authority (in which case it will apply to orders
              previously placed by you). If any of these conditions are deemed
              invalid, void, or for any reason unenforceable, such condition
              will be deemed severable and will not affect the validity and
              enforceability of any remaining condition.</span
            >
          </p>
          <p class="c5 c6 c11"><span class="c0"></span></p>
          <ol class="c7 lst-kix_list_1-0" start="11">
            <li class="c5 c9 li-bullet-0">
              <span class="c1"
                >Disclaimer, Indemnity and Limitation of Liability</span
              ><span class="c8 c3">.</span>
            </li>
          </ol>
          <p class="c4"><span class="c0"></span></p>
          <ol class="c7 lst-kix_list_1-1 start" start="1">
            <li class="c5 c9 li-bullet-0">
              <span class="c3 c8"
                >You expressly understand and agree that:</span
              >
            </li>
          </ol>
          <p class="c5 c6 c12"><span class="c0"></span></p>
          <ol class="c7 lst-kix_list_36-0 start" start="1">
            <li class="c5 c14 li-bullet-0">
              <span class="c2"
                >The information, Content and materials on this website and / or
                services are provided on an &quot;as is</span
              ><span class="c3">&#39;&#39; and</span
              ><span class="c2"
                >&nbsp;&quot;as available&quot; basis. The Company and all its
                subsidiaries, affiliates, officers, employees, agents, partners
                and licensors disclaim all warranties of any kind, either
                express or implied, including but not limited to, implied
                warranties on merchantability, fitness for a particular purpose
                and non-infringement.</span
              >
            </li>
          </ol>
          <p class="c5 c6 c26"><span class="c2"></span></p>
          <ol class="c7 lst-kix_list_36-0" start="2">
            <li class="c5 c14 li-bullet-0">
              <span class="c2"
                >The Company shall not be liable for any deficient Services if
                the deficiency is on account of a breach by You or your
                employees or end users of the Platform.
              </span>
            </li>
          </ol>
          <p class="c6 c29 c34"><span class="c2"></span></p>
          <ol class="c7 lst-kix_list_36-0" start="3">
            <li class="c5 c14 li-bullet-0">
              <span class="c2"
                >Whilst the Company shall take all reasonable measures to
                provide qualitative Services, the Company does not warrant that
                the Services provided will be error free or virus free on
                account of the various errors, bugs, viruses, trojans etc. on
                the internet.
              </span>
            </li>
          </ol>
          <p class="c5 c6"><span class="c2"></span></p>
          <ol class="c7 lst-kix_list_36-0" start="4">
            <li class="c5 c14 li-bullet-0">
              <span class="c2"
                >The Company does not warrant that the functions contained in
                Content, information and materials on the Website and / or
                services, including, without limitation any third party sites or
                services linked to the website and / or services will be
                uninterrupted, timely or error-free, that the defects will be
                rectified, or that the Website or the servers that make such
                content, information and materials available are free of viruses
                or other harmful components.</span
              >
            </li>
          </ol>
          <p class="c4"><span class="c2"></span></p>
          <ol class="c7 lst-kix_list_36-0" start="5">
            <li class="c5 c14 li-bullet-0">
              <span class="c2"
                >Any material downloaded or otherwise obtained through the
                Website and / or services are accessed at your own risk, and you
                will be solely responsible for any damage or loss of data that
                results from such download to your computer system.</span
              >
            </li>
          </ol>
          <p class="c4"><span class="c2"></span></p>
          <ol class="c7 lst-kix_list_36-0" start="6">
            <li class="c5 c14 li-bullet-0">
              <span class="c2"
                >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;You acknowledge
                that when you access a link that leaves the Website, the site
                you will enter into is not controlled by the Company and
                different terms of use and privacy policy may apply. By
                accessing links to other sites, you acknowledge that the Company
                is not responsible for those sites. The Company reserves the
                right to disable links from third-party sites to the Website,
                although the Company is under no obligation to do so.</span
              >
            </li>
          </ol>
          <p class="c4"><span class="c2"></span></p>
          <ol class="c7 lst-kix_list_36-0" start="7">
            <li class="c5 c14 li-bullet-0">
              <span class="c2"
                >You hereby indemnify, defend, and hold the Company, the
                Company&#39;s distributors, agents, representatives and other
                authorized users, officers, directors, owners, employees,
                agents, representatives, harmless from and against any and all
                losses, damages, liabilities and costs arising from (i) your use
                of the Website; (ii) uploading data &nbsp;or Content which
                infringes the intellectual property rights of another user or
                third party; (iii) third party claims in relation to suspected
                infringement of intellectual property belonging to third
                parties, breach of privacy, defamation etc.</span
              >
            </li>
          </ol>
          <p class="c4"><span class="c2"></span></p>
          <ol class="c7 lst-kix_list_36-0" start="8">
            <li class="c5 c14 li-bullet-0">
              <span class="c2"
                >You expressly understand that under no circumstances,
                including, but not limited to, negligence, shall the Company be
                liable to you or any other person or entity for any direct,
                indirect, incidental, special, or consequential damages,
                including, but not limited to damages for loss of profits,
                goodwill, use, data or other intangible losses, resulting from
                circumstances, including but not limited to: (i) the use or the
                inability to use the Website and / or services; (ii)
                unauthorized access to or alteration of the users&rsquo;
                transmissions or data; (iii) statements or conduct of any third
                party on the Website and / or services; or (iv) any other matter
                relating to the Website and / or services.</span
              >
            </li>
          </ol>
          <p class="c4"><span class="c2"></span></p>
          <ol class="c7 lst-kix_list_36-0" start="9">
            <li class="c5 c14 li-bullet-0">
              <span class="c2"
                >The Company shall be relieved of all its responsibilities, if
                any, in the event of failure of performance resulting directly
                or indirectly from an act of force majeure or causes beyond the
                Company&#39;s reasonable control including, without limitation,
                acts of god, war, equipment and technical failures, electrical
                power failures or fluctuations, strikes, labor disputes, riots,
                civil disturbances, shortages of labor or materials, natural
                disasters, orders of domestic or foreign courts or tribunals,
                non-performance of third parties, or any reasons beyond the
                reasonable control of the Company. You further acknowledge and
                agree that the Company is not liable for (a) any incompatibility
                between the Website and / or services and any other website,
                service, software or hardware or (b) any delays or failures that
                users may experience with any transmissions or transactions
                relating to the Website in an accurate or timely manner.</span
              >
            </li>
          </ol>
          <p class="c5 c6"><span class="c0"></span></p>
          <ol class="c7 lst-kix_list_1-0" start="12">
            <li class="c5 c13 c12 li-bullet-5">
              <span class="c1">Waiver</span><span class="c8 c3">.</span>
            </li>
          </ol>
          <p class="c5 c6 c11"><span class="c1"></span></p>
          <p class="c5 c11">
            <span class="c2"
              >No term of these Terms of Use shall be deemed waived and no
              breach excused, unless such waiver or consent is in writing and
              signed by the Company. Any consent by the Company to, or waiver of
              a breach by you, whether expressed or implied, shall not
              constitute consent to, waiver of, or excuse for any other
              different or subsequent breach.</span
            >
          </p>
          <p class="c5 c6 c11"><span class="c0"></span></p>
          <ol class="c7 lst-kix_list_1-0" start="13">
            <li class="c5 c12 c13 li-bullet-6">
              <span class="c1">Governing Law and Jurisdiction</span
              ><span class="c8 c3">.</span>
            </li>
          </ol>
          <p class="c4"><span class="c0"></span></p>
          <p class="c5 c11">
            <span class="c2"
              >Any dispute, claim or controversy arising out of, or relating to
              the breach, termination, enforcement, interpretation or validity
              thereof, including the determination of the scope or applicability
              of these Terms of Use, or to your use of the Company&rsquo;s
              platform or the service or information to which it gives access,
              shall be determined by arbitration in India, before a sole
              arbitrator. Arbitration shall be conducted in accordance with the
              Arbitration and Conciliation Act, 1996. The seat of such
              arbitration shall be Bangalore. All proceedings of such
              arbitration, including, without limitation, any awards, shall be
              in the English language. The award shall be final and binding on
              the parties.</span
            >
          </p>
          <p class="c5 c6 c11"><span class="c0"></span></p>
          <ol class="c7 lst-kix_list_1-0" start="14">
            <li class="c5 c13 c12 li-bullet-6">
              <span class="c1">Severability</span><span class="c2">.</span>
            </li>
          </ol>
          <p class="c5 c6 c11"><span class="c0"></span></p>
          <p class="c5 c11">
            <span class="c2"
              >If any provision of these Terms of Use is held by a court of
              competent jurisdiction or arbitral tribunal to be unenforceable
              under applicable law, then such provision shall be excluded from
              these Terms of Use and the remainder of these Terms of Use shall
              be interpreted as if such provision were so excluded and shall be
              enforceable in accordance with its terms; provided however that,
              in such event these Terms of Use shall be interpreted so as to
              give effect, to the greatest extent consistent with and permitted
              by applicable law, to the meaning and intention of the excluded
              provision as determined by such court of competent jurisdiction or
              arbitral tribunal.</span
            >
          </p>
          <p class="c5 c6"><span class="c2"></span></p>
          <ol class="c7 lst-kix_list_1-0" start="15">
            <li class="c5 c13 c12 li-bullet-5">
              <span class="c1">Complete Understanding</span
              ><span class="c8 c3">.</span>
            </li>
          </ol>
          <p class="c5 c6 c11"><span class="c0"></span></p>
          <p class="c5 c11">
            <span class="c2"
              >These Terms of Use contain the entire understanding between the
              parties, and there are no other written or oral understandings or
              promises between the parties with respect to the subject matter of
              these Terms of Use other than those contained or referenced in
              these Terms of Use.</span
            ><a id="id.tyjcwt"></a>
          </p>
          <p class="c5 c6"><span class="c2"></span></p>
          <ol class="c7 lst-kix_list_1-0" start="16">
            <li class="c5 c13 c12 li-bullet-7">
              <span class="c1">Assignment</span>
            </li>
          </ol>
          <p class="c5 c6 c22"><span class="c1"></span></p>
          <p class="c5 c11">
            <span class="c2"
              >You shall not assign any of their rights and obligations under
              these Terms to any Party under any circumstance whatsoever. The
              Company may assign its rights and responsibilities to a third
              party by giving prior notice, whether written or in the electronic
              form, to the users.
            </span>
          </p>
          <p class="c5 c6"><span class="c2"></span></p>
          <ol class="c7 lst-kix_list_1-0" start="17">
            <li class="c5 c13 c12 li-bullet-5">
              <span class="c1">Amendment</span>
            </li>
          </ol>
          <p class="c5 c6 c22"><span class="c0"></span></p>
          <p class="c5 c11">
            <span class="c3"
              >The Company reserves the right to amend the Terms on a time to
              time basis with the change in the applicable laws and shall
              intimate such change to the users through e-mail.</span
            >
          </p>
          <br />
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "App",
};
</script>

<style scoped>
@import url("https://themes.googleusercontent.com/fonts/css?kit=TjOMxdLmJW6gEQjyZ-Va0w");
ol.lst-kix_list_38-6 {
  list-style-type: none;
}
ol.lst-kix_list_38-5 {
  list-style-type: none;
}
ol.lst-kix_list_38-8 {
  list-style-type: none;
}
.lst-kix_list_21-8 > li {
  counter-increment: lst-ctn-kix_list_21-8;
}
ol.lst-kix_list_38-7 {
  list-style-type: none;
}
ol.lst-kix_list_38-2 {
  list-style-type: none;
}
ol.lst-kix_list_38-1 {
  list-style-type: none;
}
ol.lst-kix_list_38-4 {
  list-style-type: none;
}
ol.lst-kix_list_38-3 {
  list-style-type: none;
}
ol.lst-kix_list_38-0 {
  list-style-type: none;
}
.lst-kix_list_5-0 > li {
  counter-increment: lst-ctn-kix_list_5-0;
}
ol.lst-kix_list_2-3.start {
  counter-reset: lst-ctn-kix_list_2-3 0;
}
ol.lst-kix_list_37-2.start {
  counter-reset: lst-ctn-kix_list_37-2 0;
}
.lst-kix_list_42-1 > li:before {
  content: "" counter(lst-ctn-kix_list_42-1, lower-latin) ". ";
}
.lst-kix_list_40-1 > li {
  counter-increment: lst-ctn-kix_list_40-1;
}
ol.lst-kix_list_5-3.start {
  counter-reset: lst-ctn-kix_list_5-3 0;
}
.lst-kix_list_38-6 > li {
  counter-increment: lst-ctn-kix_list_38-6;
}
.lst-kix_list_4-3 > li {
  counter-increment: lst-ctn-kix_list_4-3;
}
.lst-kix_list_42-7 > li:before {
  content: "" counter(lst-ctn-kix_list_42-7, lower-latin) ". ";
}
ol.lst-kix_list_17-1.start {
  counter-reset: lst-ctn-kix_list_17-1 0;
}
.lst-kix_list_42-5 > li:before {
  content: "" counter(lst-ctn-kix_list_42-5, lower-roman) ". ";
}
ol.lst-kix_list_47-0.start {
  counter-reset: lst-ctn-kix_list_47-0 0;
}
ol.lst-kix_list_8-8.start {
  counter-reset: lst-ctn-kix_list_8-8 0;
}
.lst-kix_list_42-3 > li:before {
  content: "" counter(lst-ctn-kix_list_42-3, decimal) ". ";
}
.lst-kix_list_32-8 > li {
  counter-increment: lst-ctn-kix_list_32-8;
}
ol.lst-kix_list_16-5 {
  list-style-type: none;
}
ol.lst-kix_list_16-6 {
  list-style-type: none;
}
.lst-kix_list_24-8 > li:before {
  content: "" counter(lst-ctn-kix_list_24-8, lower-roman) ". ";
}
ol.lst-kix_list_16-7 {
  list-style-type: none;
}
ol.lst-kix_list_16-8 {
  list-style-type: none;
}
ol.lst-kix_list_16-1 {
  list-style-type: none;
}
ol.lst-kix_list_16-2 {
  list-style-type: none;
}
ol.lst-kix_list_16-3 {
  list-style-type: none;
}
ol.lst-kix_list_16-4 {
  list-style-type: none;
}
.lst-kix_list_24-2 > li:before {
  content: "" counter(lst-ctn-kix_list_24-2, lower-roman) ". ";
}
ol.lst-kix_list_16-0 {
  list-style-type: none;
}
.lst-kix_list_24-4 > li:before {
  content: "" counter(lst-ctn-kix_list_24-4, lower-latin) ". ";
}
ol.lst-kix_list_34-2.start {
  counter-reset: lst-ctn-kix_list_34-2 0;
}
.lst-kix_list_9-4 > li {
  counter-increment: lst-ctn-kix_list_9-4;
}
.lst-kix_list_24-6 > li:before {
  content: "" counter(lst-ctn-kix_list_24-6, decimal) ". ";
}
ol.lst-kix_list_14-1.start {
  counter-reset: lst-ctn-kix_list_14-1 0;
}
.lst-kix_list_23-6 > li:before {
  content: "" counter(lst-ctn-kix_list_23-6, decimal) ". ";
}
.lst-kix_list_23-2 > li:before {
  content: "" counter(lst-ctn-kix_list_23-2, lower-roman) ". ";
}
.lst-kix_list_23-0 > li:before {
  content: "7." counter(lst-ctn-kix_list_23-0, decimal) " ";
}
.lst-kix_list_23-8 > li:before {
  content: "" counter(lst-ctn-kix_list_23-8, lower-roman) ". ";
}
.lst-kix_list_3-6 > li {
  counter-increment: lst-ctn-kix_list_3-6;
}
ol.lst-kix_list_44-0.start {
  counter-reset: lst-ctn-kix_list_44-0 0;
}
.lst-kix_list_24-0 > li:before {
  content: "7." counter(lst-ctn-kix_list_24-0, decimal) " ";
}
.lst-kix_list_23-4 > li:before {
  content: "" counter(lst-ctn-kix_list_23-4, lower-latin) ". ";
}
.lst-kix_list_43-3 > li:before {
  content: "" counter(lst-ctn-kix_list_43-3, decimal) ". ";
}
ol.lst-kix_list_31-2.start {
  counter-reset: lst-ctn-kix_list_31-2 0;
}
.lst-kix_list_22-2 > li:before {
  content: "" counter(lst-ctn-kix_list_22-2, lower-roman) ". ";
}
.lst-kix_list_22-6 > li:before {
  content: "" counter(lst-ctn-kix_list_22-6, decimal) ". ";
}
.lst-kix_list_43-7 > li:before {
  content: "" counter(lst-ctn-kix_list_43-7, lower-latin) ". ";
}
.lst-kix_list_43-1 > li:before {
  content: "" counter(lst-ctn-kix_list_43-1, lower-latin) ". ";
}
.lst-kix_list_22-0 > li:before {
  content: "5." counter(lst-ctn-kix_list_22-0, decimal) " ";
}
.lst-kix_list_22-8 > li:before {
  content: "" counter(lst-ctn-kix_list_22-8, lower-roman) ". ";
}
ol.lst-kix_list_9-7 {
  list-style-type: none;
}
ol.lst-kix_list_9-8 {
  list-style-type: none;
}
ol.lst-kix_list_22-5.start {
  counter-reset: lst-ctn-kix_list_22-5 0;
}
ol.lst-kix_list_9-3 {
  list-style-type: none;
}
ol.lst-kix_list_9-4 {
  list-style-type: none;
}
.lst-kix_list_5-7 > li {
  counter-increment: lst-ctn-kix_list_5-7;
}
ol.lst-kix_list_9-5 {
  list-style-type: none;
}
ol.lst-kix_list_34-7.start {
  counter-reset: lst-ctn-kix_list_34-7 0;
}
ol.lst-kix_list_9-6 {
  list-style-type: none;
}
ol.lst-kix_list_9-0 {
  list-style-type: none;
}
.lst-kix_list_43-5 > li:before {
  content: "" counter(lst-ctn-kix_list_43-5, lower-roman) ". ";
}
ol.lst-kix_list_9-1 {
  list-style-type: none;
}
ol.lst-kix_list_9-2 {
  list-style-type: none;
}
.lst-kix_list_22-4 > li:before {
  content: "" counter(lst-ctn-kix_list_22-4, lower-latin) ". ";
}
ol.lst-kix_list_32-8.start {
  counter-reset: lst-ctn-kix_list_32-8 0;
}
ol.lst-kix_list_25-5.start {
  counter-reset: lst-ctn-kix_list_25-5 0;
}
.lst-kix_list_41-7 > li:before {
  content: "" counter(lst-ctn-kix_list_41-7, lower-latin) ". ";
}
ol.lst-kix_list_24-4.start {
  counter-reset: lst-ctn-kix_list_24-4 0;
}
.lst-kix_list_6-4 > li {
  counter-increment: lst-ctn-kix_list_6-4;
}
.lst-kix_list_41-1 > li:before {
  content: "" counter(lst-ctn-kix_list_41-1, lower-latin) ". ";
}
.lst-kix_list_40-7 > li:before {
  content: "" counter(lst-ctn-kix_list_40-7, lower-latin) ". ";
}
ol.lst-kix_list_27-4.start {
  counter-reset: lst-ctn-kix_list_27-4 0;
}
.lst-kix_list_40-5 > li:before {
  content: "" counter(lst-ctn-kix_list_40-5, lower-roman) ". ";
}
ol.lst-kix_list_15-2.start {
  counter-reset: lst-ctn-kix_list_15-2 0;
}
ol.lst-kix_list_42-1.start {
  counter-reset: lst-ctn-kix_list_42-1 0;
}
.lst-kix_list_41-5 > li:before {
  content: "" counter(lst-ctn-kix_list_41-5, lower-roman) ". ";
}
ol.lst-kix_list_37-7.start {
  counter-reset: lst-ctn-kix_list_37-7 0;
}
ol.lst-kix_list_2-8.start {
  counter-reset: lst-ctn-kix_list_2-8 0;
}
.lst-kix_list_41-3 > li:before {
  content: "" counter(lst-ctn-kix_list_41-3, decimal) ". ";
}
ol.lst-kix_list_29-8 {
  list-style-type: none;
}
ol.lst-kix_list_29-7 {
  list-style-type: none;
}
ol.lst-kix_list_29-4 {
  list-style-type: none;
}
ol.lst-kix_list_29-3 {
  list-style-type: none;
}
ol.lst-kix_list_29-6 {
  list-style-type: none;
}
ol.lst-kix_list_29-5 {
  list-style-type: none;
}
ol.lst-kix_list_29-0 {
  list-style-type: none;
}
ol.lst-kix_list_5-8.start {
  counter-reset: lst-ctn-kix_list_5-8 0;
}
ol.lst-kix_list_29-2 {
  list-style-type: none;
}
ol.lst-kix_list_29-1 {
  list-style-type: none;
}
.lst-kix_list_1-3 > li {
  counter-increment: lst-ctn-kix_list_1-3;
}
.lst-kix_list_40-3 > li:before {
  content: "" counter(lst-ctn-kix_list_40-3, decimal) ". ";
}
ol.lst-kix_list_12-2.start {
  counter-reset: lst-ctn-kix_list_12-2 0;
}
.lst-kix_list_40-1 > li:before {
  content: "" counter(lst-ctn-kix_list_40-1, lower-latin) ". ";
}
.lst-kix_list_42-2 > li {
  counter-increment: lst-ctn-kix_list_42-2;
}
ol.lst-kix_list_38-3.start {
  counter-reset: lst-ctn-kix_list_38-3 0;
}
.lst-kix_list_24-8 > li {
  counter-increment: lst-ctn-kix_list_24-8;
}
ol.lst-kix_list_3-4.start {
  counter-reset: lst-ctn-kix_list_3-4 0;
}
ol.lst-kix_list_19-0.start {
  counter-reset: lst-ctn-kix_list_19-0 2;
}
ol.lst-kix_list_34-6 {
  list-style-type: none;
}
ol.lst-kix_list_34-5 {
  list-style-type: none;
}
ol.lst-kix_list_34-8 {
  list-style-type: none;
}
.lst-kix_list_7-1 > li {
  counter-increment: lst-ctn-kix_list_7-1;
}
ol.lst-kix_list_34-7 {
  list-style-type: none;
}
ol.lst-kix_list_34-2 {
  list-style-type: none;
}
ol.lst-kix_list_34-1 {
  list-style-type: none;
}
ol.lst-kix_list_34-4 {
  list-style-type: none;
}
ol.lst-kix_list_34-3 {
  list-style-type: none;
}
ol.lst-kix_list_34-0 {
  list-style-type: none;
}
.lst-kix_list_21-8 > li:before {
  content: "" counter(lst-ctn-kix_list_21-8, lower-roman) ". ";
}
.lst-kix_list_8-0 > li {
  counter-increment: lst-ctn-kix_list_8-0;
}
ol.lst-kix_list_18-2.start {
  counter-reset: lst-ctn-kix_list_18-2 0;
}
.lst-kix_list_26-8 > li:before {
  content: "" counter(lst-ctn-kix_list_26-8, lower-roman) ". ";
}
.lst-kix_list_21-0 > li:before {
  content: "(" counter(lst-ctn-kix_list_21-0, lower-latin) ") ";
}
.lst-kix_list_13-1 > li {
  counter-increment: lst-ctn-kix_list_13-1;
}
.lst-kix_list_26-4 > li:before {
  content: "" counter(lst-ctn-kix_list_26-4, lower-latin) ". ";
}
ol.lst-kix_list_36-1.start {
  counter-reset: lst-ctn-kix_list_36-1 0;
}
.lst-kix_list_42-4 > li {
  counter-increment: lst-ctn-kix_list_42-4;
}
.lst-kix_list_21-4 > li:before {
  content: "" counter(lst-ctn-kix_list_21-4, lower-latin) ". ";
}
.lst-kix_list_26-0 > li:before {
  content: "7." counter(lst-ctn-kix_list_26-0, decimal) " ";
}
.lst-kix_list_31-4 > li {
  counter-increment: lst-ctn-kix_list_31-4;
}
ol.lst-kix_list_7-2.start {
  counter-reset: lst-ctn-kix_list_7-2 0;
}
.lst-kix_list_31-2 > li {
  counter-increment: lst-ctn-kix_list_31-2;
}
ol.lst-kix_list_38-8.start {
  counter-reset: lst-ctn-kix_list_38-8 0;
}
ol.lst-kix_list_19-5.start {
  counter-reset: lst-ctn-kix_list_19-5 0;
}
ol.lst-kix_list_12-5 {
  list-style-type: none;
}
ol.lst-kix_list_12-6 {
  list-style-type: none;
}
ol.lst-kix_list_12-7 {
  list-style-type: none;
}
ol.lst-kix_list_12-8 {
  list-style-type: none;
}
ol.lst-kix_list_12-1 {
  list-style-type: none;
}
ol.lst-kix_list_12-2 {
  list-style-type: none;
}
.lst-kix_list_35-8 > li {
  counter-increment: lst-ctn-kix_list_35-8;
}
ol.lst-kix_list_12-3 {
  list-style-type: none;
}
ol.lst-kix_list_39-6.start {
  counter-reset: lst-ctn-kix_list_39-6 0;
}
ol.lst-kix_list_12-4 {
  list-style-type: none;
}
ol.lst-kix_list_26-3.start {
  counter-reset: lst-ctn-kix_list_26-3 0;
}
ol.lst-kix_list_12-0 {
  list-style-type: none;
}
.lst-kix_list_45-5 > li:before {
  content: "\0025aa  ";
}
.lst-kix_list_25-0 > li:before {
  content: "7." counter(lst-ctn-kix_list_25-0, decimal) " ";
}
ol.lst-kix_list_10-8.start {
  counter-reset: lst-ctn-kix_list_10-8 0;
}
.lst-kix_list_39-0 > li:before {
  content: "14." counter(lst-ctn-kix_list_39-0, decimal) " ";
}
ol.lst-kix_list_40-7.start {
  counter-reset: lst-ctn-kix_list_40-7 0;
}
.lst-kix_list_37-0 > li {
  counter-increment: lst-ctn-kix_list_37-0;
}
ol.lst-kix_list_21-4.start {
  counter-reset: lst-ctn-kix_list_21-4 0;
}
.lst-kix_list_44-1 > li:before {
  content: "" counter(lst-ctn-kix_list_44-1, lower-latin) ". ";
}
.lst-kix_list_45-1 > li:before {
  content: "o  ";
}
ol.lst-kix_list_20-6.start {
  counter-reset: lst-ctn-kix_list_20-6 0;
}
.lst-kix_list_13-8 > li {
  counter-increment: lst-ctn-kix_list_13-8;
}
.lst-kix_list_2-2 > li {
  counter-increment: lst-ctn-kix_list_2-2;
}
.lst-kix_list_44-5 > li:before {
  content: "" counter(lst-ctn-kix_list_44-5, lower-roman) ". ";
}
ol.lst-kix_list_4-7.start {
  counter-reset: lst-ctn-kix_list_4-7 0;
}
ol.lst-kix_list_5-0 {
  list-style-type: none;
}
ol.lst-kix_list_5-1 {
  list-style-type: none;
}
.lst-kix_list_26-2 > li {
  counter-increment: lst-ctn-kix_list_26-2;
}
ol.lst-kix_list_5-2 {
  list-style-type: none;
}
.lst-kix_list_40-8 > li {
  counter-increment: lst-ctn-kix_list_40-8;
}
.lst-kix_list_27-4 > li:before {
  content: "" counter(lst-ctn-kix_list_27-4, lower-latin) ". ";
}
.lst-kix_list_20-2 > li {
  counter-increment: lst-ctn-kix_list_20-2;
}
.lst-kix_list_6-6 > li {
  counter-increment: lst-ctn-kix_list_6-6;
}
ol.lst-kix_list_15-7.start {
  counter-reset: lst-ctn-kix_list_15-7 0;
}
.lst-kix_list_13-6 > li {
  counter-increment: lst-ctn-kix_list_13-6;
}
ol.lst-kix_list_14-6.start {
  counter-reset: lst-ctn-kix_list_14-6 0;
}
.lst-kix_list_39-4 > li:before {
  content: "" counter(lst-ctn-kix_list_39-4, lower-latin) ". ";
}
.lst-kix_list_39-8 > li:before {
  content: "" counter(lst-ctn-kix_list_39-8, lower-roman) ". ";
}
ol.lst-kix_list_5-7 {
  list-style-type: none;
}
ol.lst-kix_list_5-8 {
  list-style-type: none;
}
ol.lst-kix_list_26-8.start {
  counter-reset: lst-ctn-kix_list_26-8 0;
}
ol.lst-kix_list_5-3 {
  list-style-type: none;
}
.lst-kix_list_8-7 > li {
  counter-increment: lst-ctn-kix_list_8-7;
}
.lst-kix_list_19-6 > li {
  counter-increment: lst-ctn-kix_list_19-6;
}
ol.lst-kix_list_5-4 {
  list-style-type: none;
}
.lst-kix_list_27-0 > li:before {
  content: "7." counter(lst-ctn-kix_list_27-0, decimal) " ";
}
ol.lst-kix_list_5-5 {
  list-style-type: none;
}
ol.lst-kix_list_5-6 {
  list-style-type: none;
}
.lst-kix_list_20-4 > li {
  counter-increment: lst-ctn-kix_list_20-4;
}
ol.lst-kix_list_47-7 {
  list-style-type: none;
}
ol.lst-kix_list_47-8 {
  list-style-type: none;
}
ol.lst-kix_list_47-5 {
  list-style-type: none;
}
ol.lst-kix_list_44-5.start {
  counter-reset: lst-ctn-kix_list_44-5 0;
}
ol.lst-kix_list_47-6 {
  list-style-type: none;
}
ol.lst-kix_list_47-3 {
  list-style-type: none;
}
ol.lst-kix_list_47-4 {
  list-style-type: none;
}
ol.lst-kix_list_47-1 {
  list-style-type: none;
}
ol.lst-kix_list_47-2 {
  list-style-type: none;
}
ol.lst-kix_list_47-0 {
  list-style-type: none;
}
ol.lst-kix_list_20-1.start {
  counter-reset: lst-ctn-kix_list_20-1 0;
}
.lst-kix_list_25-4 > li:before {
  content: "" counter(lst-ctn-kix_list_25-4, lower-latin) ". ";
}
.lst-kix_list_19-4 > li {
  counter-increment: lst-ctn-kix_list_19-4;
}
.lst-kix_list_35-1 > li {
  counter-increment: lst-ctn-kix_list_35-1;
}
.lst-kix_list_46-5 > li:before {
  content: "" counter(lst-ctn-kix_list_46-5, lower-roman) ". ";
}
.lst-kix_list_24-1 > li {
  counter-increment: lst-ctn-kix_list_24-1;
}
.lst-kix_list_25-8 > li:before {
  content: "" counter(lst-ctn-kix_list_25-8, lower-roman) ". ";
}
ol.lst-kix_list_26-6.start {
  counter-reset: lst-ctn-kix_list_26-6 0;
}
.lst-kix_list_44-5 > li {
  counter-increment: lst-ctn-kix_list_44-5;
}
.lst-kix_list_37-2 > li {
  counter-increment: lst-ctn-kix_list_37-2;
}
.lst-kix_list_15-2 > li {
  counter-increment: lst-ctn-kix_list_15-2;
}
.lst-kix_list_22-5 > li {
  counter-increment: lst-ctn-kix_list_22-5;
}
.lst-kix_list_28-8 > li:before {
  content: "" counter(lst-ctn-kix_list_28-8, lower-roman) ". ";
}
.lst-kix_list_24-6 > li {
  counter-increment: lst-ctn-kix_list_24-6;
}
.lst-kix_list_17-3 > li {
  counter-increment: lst-ctn-kix_list_17-3;
}
.lst-kix_list_28-4 > li:before {
  content: "" counter(lst-ctn-kix_list_28-4, lower-latin) ". ";
}
.lst-kix_list_46-6 > li {
  counter-increment: lst-ctn-kix_list_46-6;
}
.lst-kix_list_39-3 > li {
  counter-increment: lst-ctn-kix_list_39-3;
}
.lst-kix_list_28-3 > li {
  counter-increment: lst-ctn-kix_list_28-3;
}
ol.lst-kix_list_25-0.start {
  counter-reset: lst-ctn-kix_list_25-0 0;
}
ol.lst-kix_list_25-8 {
  list-style-type: none;
}
ol.lst-kix_list_25-7 {
  list-style-type: none;
}
.lst-kix_list_35-6 > li {
  counter-increment: lst-ctn-kix_list_35-6;
}
ol.lst-kix_list_25-4 {
  list-style-type: none;
}
ol.lst-kix_list_25-3 {
  list-style-type: none;
}
ol.lst-kix_list_13-0.start {
  counter-reset: lst-ctn-kix_list_13-0 0;
}
ol.lst-kix_list_25-6 {
  list-style-type: none;
}
ol.lst-kix_list_32-3.start {
  counter-reset: lst-ctn-kix_list_32-3 0;
}
ol.lst-kix_list_25-5 {
  list-style-type: none;
}
ol.lst-kix_list_25-0 {
  list-style-type: none;
}
ol.lst-kix_list_25-2 {
  list-style-type: none;
}
ol.lst-kix_list_25-1 {
  list-style-type: none;
}
ol.lst-kix_list_8-3.start {
  counter-reset: lst-ctn-kix_list_8-3 0;
}
.lst-kix_list_11-5 > li {
  counter-increment: lst-ctn-kix_list_11-5;
}
.lst-kix_list_28-0 > li:before {
  content: "7." counter(lst-ctn-kix_list_28-0, decimal) " ";
}
.lst-kix_list_27-8 > li:before {
  content: "" counter(lst-ctn-kix_list_27-8, lower-roman) ". ";
}
ol.lst-kix_list_20-3.start {
  counter-reset: lst-ctn-kix_list_20-3 0;
}
.lst-kix_list_4-1 > li {
  counter-increment: lst-ctn-kix_list_4-1;
}
.lst-kix_list_19-1 > li:before {
  content: "" counter(lst-ctn-kix_list_19-0, decimal) "."
    counter(lst-ctn-kix_list_19-1, decimal) " ";
}
ol.lst-kix_list_46-2.start {
  counter-reset: lst-ctn-kix_list_46-2 0;
}
.lst-kix_list_27-8 > li {
  counter-increment: lst-ctn-kix_list_27-8;
}
.lst-kix_list_19-3 > li:before {
  content: "" counter(lst-ctn-kix_list_19-0, decimal) "."
    counter(lst-ctn-kix_list_19-1, decimal) "."
    counter(lst-ctn-kix_list_19-2, decimal) "."
    counter(lst-ctn-kix_list_19-3, decimal) " ";
}
ol.lst-kix_list_38-0.start {
  counter-reset: lst-ctn-kix_list_38-0 0;
}
.lst-kix_list_15-0 > li {
  counter-increment: lst-ctn-kix_list_15-0;
}
ol.lst-kix_list_6-6.start {
  counter-reset: lst-ctn-kix_list_6-6 0;
}
.lst-kix_list_47-5 > li:before {
  content: "" counter(lst-ctn-kix_list_47-5, lower-roman) ". ";
}
.lst-kix_list_39-5 > li {
  counter-increment: lst-ctn-kix_list_39-5;
}
.lst-kix_list_32-6 > li {
  counter-increment: lst-ctn-kix_list_32-6;
}
ol.lst-kix_list_29-6.start {
  counter-reset: lst-ctn-kix_list_29-6 0;
}
.lst-kix_list_11-0 > li {
  counter-increment: lst-ctn-kix_list_11-0;
}
ol.lst-kix_list_1-5.start {
  counter-reset: lst-ctn-kix_list_1-5 0;
}
ol.lst-kix_list_9-6.start {
  counter-reset: lst-ctn-kix_list_9-6 0;
}
ol.lst-kix_list_16-3.start {
  counter-reset: lst-ctn-kix_list_16-3 0;
}
.lst-kix_list_22-7 > li {
  counter-increment: lst-ctn-kix_list_22-7;
}
.lst-kix_list_47-3 > li:before {
  content: "" counter(lst-ctn-kix_list_47-3, decimal) ". ";
}
ol.lst-kix_list_4-5.start {
  counter-reset: lst-ctn-kix_list_4-5 0;
}
.lst-kix_list_37-0 > li:before {
  content: "(" counter(lst-ctn-kix_list_37-0, lower-latin) ") ";
}
.lst-kix_list_26-7 > li {
  counter-increment: lst-ctn-kix_list_26-7;
}
.lst-kix_list_41-0 > li {
  counter-increment: lst-ctn-kix_list_41-0;
}
.lst-kix_list_5-2 > li {
  counter-increment: lst-ctn-kix_list_5-2;
}
.lst-kix_list_37-7 > li {
  counter-increment: lst-ctn-kix_list_37-7;
}
.lst-kix_list_37-8 > li:before {
  content: "" counter(lst-ctn-kix_list_37-8, lower-roman) ". ";
}
.lst-kix_list_37-6 > li:before {
  content: "" counter(lst-ctn-kix_list_37-6, decimal) ". ";
}
.lst-kix_list_28-5 > li {
  counter-increment: lst-ctn-kix_list_28-5;
}
ol.lst-kix_list_35-0.start {
  counter-reset: lst-ctn-kix_list_35-0 0;
}
.lst-kix_list_22-0 > li {
  counter-increment: lst-ctn-kix_list_22-0;
}
.lst-kix_list_46-1 > li:before {
  content: "" counter(lst-ctn-kix_list_46-1, lower-latin) ". ";
}
.lst-kix_list_21-6 > li {
  counter-increment: lst-ctn-kix_list_21-6;
}
.lst-kix_list_10-3 > li {
  counter-increment: lst-ctn-kix_list_10-3;
}
ol.lst-kix_list_1-0.start {
  counter-reset: lst-ctn-kix_list_1-0 0;
}
ol.lst-kix_list_13-3.start {
  counter-reset: lst-ctn-kix_list_13-3 0;
}
.lst-kix_list_26-0 > li {
  counter-increment: lst-ctn-kix_list_26-0;
}
ol.lst-kix_list_42-8 {
  list-style-type: none;
}
ol.lst-kix_list_4-0.start {
  counter-reset: lst-ctn-kix_list_4-0 0;
}
ol.lst-kix_list_42-6 {
  list-style-type: none;
}
ol.lst-kix_list_42-7 {
  list-style-type: none;
}
li.li-bullet-2:before {
  margin-left: -18pt;
  white-space: nowrap;
  display: inline-block;
  min-width: 18pt;
}
ol.lst-kix_list_42-4 {
  list-style-type: none;
}
ol.lst-kix_list_42-5 {
  list-style-type: none;
}
.lst-kix_list_27-4 > li {
  counter-increment: lst-ctn-kix_list_27-4;
}
ol.lst-kix_list_42-2 {
  list-style-type: none;
}
ol.lst-kix_list_42-3 {
  list-style-type: none;
}
ol.lst-kix_list_42-0 {
  list-style-type: none;
}
ol.lst-kix_list_42-1 {
  list-style-type: none;
}
.lst-kix_list_38-2 > li:before {
  content: "" counter(lst-ctn-kix_list_38-2, lower-roman) ". ";
}
.lst-kix_list_38-4 > li:before {
  content: "" counter(lst-ctn-kix_list_38-4, lower-latin) ". ";
}
ol.lst-kix_list_43-2.start {
  counter-reset: lst-ctn-kix_list_43-2 0;
}
.lst-kix_list_16-1 > li {
  counter-increment: lst-ctn-kix_list_16-1;
}
.lst-kix_list_27-1 > li {
  counter-increment: lst-ctn-kix_list_27-1;
}
.lst-kix_list_38-4 > li {
  counter-increment: lst-ctn-kix_list_38-4;
}
ol.lst-kix_list_43-4.start {
  counter-reset: lst-ctn-kix_list_43-4 0;
}
ul.lst-kix_list_45-2 {
  list-style-type: none;
}
ul.lst-kix_list_45-1 {
  list-style-type: none;
}
ul.lst-kix_list_45-4 {
  list-style-type: none;
}
ul.lst-kix_list_45-3 {
  list-style-type: none;
}
ul.lst-kix_list_45-6 {
  list-style-type: none;
}
.lst-kix_list_17-5 > li {
  counter-increment: lst-ctn-kix_list_17-5;
}
ul.lst-kix_list_45-5 {
  list-style-type: none;
}
ol.lst-kix_list_9-4.start {
  counter-reset: lst-ctn-kix_list_9-4 0;
}
ol.lst-kix_list_41-8.start {
  counter-reset: lst-ctn-kix_list_41-8 0;
}
.lst-kix_list_21-3 > li {
  counter-increment: lst-ctn-kix_list_21-3;
}
ul.lst-kix_list_45-8 {
  list-style-type: none;
}
ul.lst-kix_list_45-7 {
  list-style-type: none;
}
ol.lst-kix_list_30-1.start {
  counter-reset: lst-ctn-kix_list_30-1 0;
}
.lst-kix_list_7-8 > li {
  counter-increment: lst-ctn-kix_list_7-8;
}
.lst-kix_list_36-4 > li:before {
  content: "" counter(lst-ctn-kix_list_36-4, lower-latin) ". ";
}
ol.lst-kix_list_13-5.start {
  counter-reset: lst-ctn-kix_list_13-5 0;
}
ol.lst-kix_list_20-8 {
  list-style-type: none;
}
ol.lst-kix_list_13-8.start {
  counter-reset: lst-ctn-kix_list_13-8 0;
}
ol.lst-kix_list_20-5 {
  list-style-type: none;
}
ol.lst-kix_list_20-4 {
  list-style-type: none;
}
ol.lst-kix_list_20-7 {
  list-style-type: none;
}
.lst-kix_list_11-7 > li {
  counter-increment: lst-ctn-kix_list_11-7;
}
ol.lst-kix_list_20-6 {
  list-style-type: none;
}
ol.lst-kix_list_20-1 {
  list-style-type: none;
}
ol.lst-kix_list_20-0 {
  list-style-type: none;
}
ol.lst-kix_list_20-3 {
  list-style-type: none;
}
ol.lst-kix_list_20-2 {
  list-style-type: none;
}
.lst-kix_list_5-5 > li {
  counter-increment: lst-ctn-kix_list_5-5;
}
.lst-kix_list_36-2 > li:before {
  content: "" counter(lst-ctn-kix_list_36-2, lower-roman) ". ";
}
ol.lst-kix_list_43-7.start {
  counter-reset: lst-ctn-kix_list_43-7 0;
}
.lst-kix_list_44-7 > li {
  counter-increment: lst-ctn-kix_list_44-7;
}
.lst-kix_list_16-8 > li {
  counter-increment: lst-ctn-kix_list_16-8;
}
ol.lst-kix_list_46-7.start {
  counter-reset: lst-ctn-kix_list_46-7 0;
}
.lst-kix_list_38-8 > li {
  counter-increment: lst-ctn-kix_list_38-8;
}
ol.lst-kix_list_18-4.start {
  counter-reset: lst-ctn-kix_list_18-4 0;
}
ol.lst-kix_list_29-1.start {
  counter-reset: lst-ctn-kix_list_29-1 0;
}
ol.lst-kix_list_1-2.start {
  counter-reset: lst-ctn-kix_list_1-2 0;
}
.lst-kix_list_20-4 > li:before {
  content: "" counter(lst-ctn-kix_list_20-4, lower-latin) ". ";
}
ol.lst-kix_list_22-7.start {
  counter-reset: lst-ctn-kix_list_22-7 0;
}
ol.lst-kix_list_6-1.start {
  counter-reset: lst-ctn-kix_list_6-1 0;
}
.lst-kix_list_20-2 > li:before {
  content: "" counter(lst-ctn-kix_list_20-2, lower-roman) ". ";
}
ol.lst-kix_list_16-8.start {
  counter-reset: lst-ctn-kix_list_16-8 0;
}
.lst-kix_list_4-8 > li {
  counter-increment: lst-ctn-kix_list_4-8;
}
ol.lst-kix_list_9-1.start {
  counter-reset: lst-ctn-kix_list_9-1 0;
}
ol.lst-kix_list_24-2.start {
  counter-reset: lst-ctn-kix_list_24-2 0;
}
.lst-kix_list_19-8 > li {
  counter-increment: lst-ctn-kix_list_19-8;
}
.lst-kix_list_35-6 > li:before {
  content: "" counter(lst-ctn-kix_list_35-6, decimal) ". ";
}
.lst-kix_list_36-5 > li {
  counter-increment: lst-ctn-kix_list_36-5;
}
.lst-kix_list_3-2 > li:before {
  content: "" counter(lst-ctn-kix_list_3-2, lower-roman) ". ";
}
.lst-kix_list_8-1 > li:before {
  content: "" counter(lst-ctn-kix_list_8-1, lower-latin) ". ";
}
.lst-kix_list_18-0 > li {
  counter-increment: lst-ctn-kix_list_18-0;
}
.lst-kix_list_30-7 > li:before {
  content: "" counter(lst-ctn-kix_list_30-7, lower-latin) ". ";
}
.lst-kix_list_43-3 > li {
  counter-increment: lst-ctn-kix_list_43-3;
}
.lst-kix_list_26-2 > li:before {
  content: "" counter(lst-ctn-kix_list_26-2, lower-roman) ". ";
}
.lst-kix_list_21-6 > li:before {
  content: "" counter(lst-ctn-kix_list_21-6, decimal) ". ";
}
.lst-kix_list_41-3 > li {
  counter-increment: lst-ctn-kix_list_41-3;
}
ol.lst-kix_list_35-3.start {
  counter-reset: lst-ctn-kix_list_35-3 0;
}
ol.lst-kix_list_4-2.start {
  counter-reset: lst-ctn-kix_list_4-2 0;
}
ol.lst-kix_list_16-0.start {
  counter-reset: lst-ctn-kix_list_16-0 3;
}
ol.lst-kix_list_27-2.start {
  counter-reset: lst-ctn-kix_list_27-2 0;
}
ol.lst-kix_list_18-7.start {
  counter-reset: lst-ctn-kix_list_18-7 0;
}
.lst-kix_list_25-5 > li {
  counter-increment: lst-ctn-kix_list_25-5;
}
ol.lst-kix_list_11-6.start {
  counter-reset: lst-ctn-kix_list_11-6 0;
}
ol.lst-kix_list_6-4.start {
  counter-reset: lst-ctn-kix_list_6-4 0;
}
.lst-kix_list_45-3 > li:before {
  content: "\0025cf  ";
}
.lst-kix_list_17-1 > li:before {
  content: "" counter(lst-ctn-kix_list_17-1, lower-latin) ". ";
}
.lst-kix_list_32-3 > li {
  counter-increment: lst-ctn-kix_list_32-3;
}
.lst-kix_list_47-5 > li {
  counter-increment: lst-ctn-kix_list_47-5;
}
ol.lst-kix_list_27-1.start {
  counter-reset: lst-ctn-kix_list_27-1 0;
}
ol.lst-kix_list_46-4.start {
  counter-reset: lst-ctn-kix_list_46-4 0;
}
.lst-kix_list_16-5 > li:before {
  content: "" counter(lst-ctn-kix_list_16-5, lower-roman) ". ";
}
ol.lst-kix_list_22-2.start {
  counter-reset: lst-ctn-kix_list_22-2 0;
}
.lst-kix_list_30-7 > li {
  counter-increment: lst-ctn-kix_list_30-7;
}
ol.lst-kix_list_41-5.start {
  counter-reset: lst-ctn-kix_list_41-5 0;
}
.lst-kix_list_44-7 > li:before {
  content: "" counter(lst-ctn-kix_list_44-7, lower-latin) ". ";
}
ol.lst-kix_list_29-3.start {
  counter-reset: lst-ctn-kix_list_29-3 0;
}
.lst-kix_list_16-4 > li {
  counter-increment: lst-ctn-kix_list_16-4;
}
ol.lst-kix_list_35-8.start {
  counter-reset: lst-ctn-kix_list_35-8 0;
}
.lst-kix_list_38-1 > li {
  counter-increment: lst-ctn-kix_list_38-1;
}
ol.lst-kix_list_16-5.start {
  counter-reset: lst-ctn-kix_list_16-5 0;
}
.lst-kix_list_41-7 > li {
  counter-increment: lst-ctn-kix_list_41-7;
}
ol.lst-kix_list_35-5.start {
  counter-reset: lst-ctn-kix_list_35-5 0;
}
.lst-kix_list_2-6 > li:before {
  content: "" counter(lst-ctn-kix_list_2-6, decimal) ". ";
}
.lst-kix_list_14-5 > li {
  counter-increment: lst-ctn-kix_list_14-5;
}
.lst-kix_list_7-5 > li:before {
  content: "" counter(lst-ctn-kix_list_7-5, lower-roman) ". ";
}
.lst-kix_list_27-6 > li:before {
  content: "" counter(lst-ctn-kix_list_27-6, decimal) ". ";
}
.lst-kix_list_23-2 > li {
  counter-increment: lst-ctn-kix_list_23-2;
}
ol.lst-kix_list_30-4.start {
  counter-reset: lst-ctn-kix_list_30-4 0;
}
.lst-kix_list_30-0 > li {
  counter-increment: lst-ctn-kix_list_30-0;
}
ol.lst-kix_list_11-1.start {
  counter-reset: lst-ctn-kix_list_11-1 0;
}
.lst-kix_list_18-5 > li:before {
  content: "" counter(lst-ctn-kix_list_18-5, lower-roman) ". ";
}
.lst-kix_list_13-6 > li:before {
  content: "" counter(lst-ctn-kix_list_13-6, decimal) ". ";
}
.lst-kix_list_10-6 > li {
  counter-increment: lst-ctn-kix_list_10-6;
}
.lst-kix_list_1-7 > li {
  counter-increment: lst-ctn-kix_list_1-7;
}
ol.lst-kix_list_41-0.start {
  counter-reset: lst-ctn-kix_list_41-0 0;
}
.lst-kix_list_39-6 > li:before {
  content: "" counter(lst-ctn-kix_list_39-6, decimal) ". ";
}
.lst-kix_list_7-5 > li {
  counter-increment: lst-ctn-kix_list_7-5;
}
.lst-kix_list_29-0 > li {
  counter-increment: lst-ctn-kix_list_29-0;
}
ol.lst-kix_list_24-7.start {
  counter-reset: lst-ctn-kix_list_24-7 0;
}
.lst-kix_list_31-3 > li:before {
  content: "" counter(lst-ctn-kix_list_31-3, decimal) ". ";
}
.lst-kix_list_43-6 > li {
  counter-increment: lst-ctn-kix_list_43-6;
}
.lst-kix_list_10-2 > li:before {
  content: "" counter(lst-ctn-kix_list_10-2, lower-roman) ". ";
}
ol.lst-kix_list_1-7.start {
  counter-reset: lst-ctn-kix_list_1-7 0;
}
.lst-kix_list_4-6 > li:before {
  content: "" counter(lst-ctn-kix_list_4-6, decimal) ". ";
}
ol.lst-kix_list_29-8.start {
  counter-reset: lst-ctn-kix_list_29-8 0;
}
.lst-kix_list_25-6 > li:before {
  content: "" counter(lst-ctn-kix_list_25-6, decimal) ". ";
}
.lst-kix_list_46-7 > li:before {
  content: "" counter(lst-ctn-kix_list_46-7, lower-latin) ". ";
}
.lst-kix_list_34-2 > li {
  counter-increment: lst-ctn-kix_list_34-2;
}
.lst-kix_list_12-2 > li {
  counter-increment: lst-ctn-kix_list_12-2;
}
.lst-kix_list_9-5 > li:before {
  content: "" counter(lst-ctn-kix_list_9-5, lower-roman) ". ";
}
.lst-kix_list_29-6 > li:before {
  content: "" counter(lst-ctn-kix_list_29-6, decimal) ". ";
}
li.li-bullet-5:before {
  margin-left: -29.2pt;
  white-space: nowrap;
  display: inline-block;
  min-width: 29.2pt;
}
ol.lst-kix_list_22-0.start {
  counter-reset: lst-ctn-kix_list_22-0 0;
}
ol.lst-kix_list_41-3.start {
  counter-reset: lst-ctn-kix_list_41-3 0;
}
.lst-kix_list_12-2 > li:before {
  content: "" counter(lst-ctn-kix_list_12-2, lower-roman) ". ";
}
.lst-kix_list_11-6 > li:before {
  content: "" counter(lst-ctn-kix_list_11-6, decimal) ". ";
}
.lst-kix_list_32-7 > li:before {
  content: "" counter(lst-ctn-kix_list_32-7, lower-latin) ". ";
}
ol.lst-kix_list_30-6.start {
  counter-reset: lst-ctn-kix_list_30-6 0;
}
.lst-kix_list_1-2 > li:before {
  content: "" counter(lst-ctn-kix_list_1-0, decimal) "."
    counter(lst-ctn-kix_list_1-1, decimal) "."
    counter(lst-ctn-kix_list_1-2, decimal) " ";
}
ol.lst-kix_list_11-3.start {
  counter-reset: lst-ctn-kix_list_11-3 0;
}
.lst-kix_list_1-0 > li {
  counter-increment: lst-ctn-kix_list_1-0;
}
ol.lst-kix_list_41-2.start {
  counter-reset: lst-ctn-kix_list_41-2 0;
}
.lst-kix_list_18-7 > li {
  counter-increment: lst-ctn-kix_list_18-7;
}
.lst-kix_list_29-7 > li {
  counter-increment: lst-ctn-kix_list_29-7;
}
ol.lst-kix_list_11-4.start {
  counter-reset: lst-ctn-kix_list_11-4 0;
}
.lst-kix_list_28-2 > li:before {
  content: "" counter(lst-ctn-kix_list_28-2, lower-roman) ". ";
}
.lst-kix_list_14-1 > li:before {
  content: "" counter(lst-ctn-kix_list_14-1, lower-latin) ". ";
}
ol.lst-kix_list_15-6 {
  list-style-type: none;
}
.lst-kix_list_25-8 > li {
  counter-increment: lst-ctn-kix_list_25-8;
}
ol.lst-kix_list_15-7 {
  list-style-type: none;
}
ol.lst-kix_list_15-8 {
  list-style-type: none;
}
.lst-kix_list_14-4 > li:before {
  content: "" counter(lst-ctn-kix_list_14-4, lower-latin) ". ";
}
ol.lst-kix_list_15-2 {
  list-style-type: none;
}
ol.lst-kix_list_15-3 {
  list-style-type: none;
}
ol.lst-kix_list_15-4 {
  list-style-type: none;
}
.lst-kix_list_37-5 > li {
  counter-increment: lst-ctn-kix_list_37-5;
}
.lst-kix_list_14-5 > li:before {
  content: "" counter(lst-ctn-kix_list_14-5, lower-roman) ". ";
}
.lst-kix_list_14-7 > li:before {
  content: "" counter(lst-ctn-kix_list_14-7, lower-latin) ". ";
}
ol.lst-kix_list_15-5 {
  list-style-type: none;
}
ol.lst-kix_list_15-0 {
  list-style-type: none;
}
ol.lst-kix_list_15-1 {
  list-style-type: none;
}
.lst-kix_list_34-6 > li {
  counter-increment: lst-ctn-kix_list_34-6;
}
ol.lst-kix_list_32-1.start {
  counter-reset: lst-ctn-kix_list_32-1 0;
}
.lst-kix_list_47-0 > li {
  counter-increment: lst-ctn-kix_list_47-0;
}
.lst-kix_list_20-7 > li {
  counter-increment: lst-ctn-kix_list_20-7;
}
ol.lst-kix_list_35-6.start {
  counter-reset: lst-ctn-kix_list_35-6 0;
}
ol.lst-kix_list_28-3.start {
  counter-reset: lst-ctn-kix_list_28-3 0;
}
.lst-kix_list_44-1 > li {
  counter-increment: lst-ctn-kix_list_44-1;
}
.lst-kix_list_32-3 > li:before {
  content: "" counter(lst-ctn-kix_list_32-3, decimal) ". ";
}
.lst-kix_list_14-8 > li:before {
  content: "" counter(lst-ctn-kix_list_14-8, lower-roman) ". ";
}
.lst-kix_list_39-7 > li {
  counter-increment: lst-ctn-kix_list_39-7;
}
.lst-kix_list_32-0 > li:before {
  content: "(" counter(lst-ctn-kix_list_32-0, lower-latin) ") ";
}
.lst-kix_list_3-2 > li {
  counter-increment: lst-ctn-kix_list_3-2;
}
ol.lst-kix_list_15-5.start {
  counter-reset: lst-ctn-kix_list_15-5 0;
}
ol.lst-kix_list_22-3.start {
  counter-reset: lst-ctn-kix_list_22-3 0;
}
.lst-kix_list_5-4 > li {
  counter-increment: lst-ctn-kix_list_5-4;
}
ol.lst-kix_list_24-6.start {
  counter-reset: lst-ctn-kix_list_24-6 0;
}
.lst-kix_list_5-1 > li:before {
  content: "" counter(lst-ctn-kix_list_5-1, lower-latin) ". ";
}
.lst-kix_list_5-7 > li:before {
  content: "" counter(lst-ctn-kix_list_5-7, lower-latin) ". ";
}
.lst-kix_list_5-8 > li:before {
  content: "" counter(lst-ctn-kix_list_5-8, lower-roman) ". ";
}
.lst-kix_list_5-4 > li:before {
  content: "" counter(lst-ctn-kix_list_5-4, lower-latin) ". ";
}
.lst-kix_list_5-5 > li:before {
  content: "" counter(lst-ctn-kix_list_5-5, lower-roman) ". ";
}
.lst-kix_list_6-1 > li:before {
  content: "" counter(lst-ctn-kix_list_6-1, lower-latin) ". ";
}
.lst-kix_list_6-3 > li:before {
  content: "" counter(lst-ctn-kix_list_6-3, decimal) ". ";
}
ol.lst-kix_list_32-6.start {
  counter-reset: lst-ctn-kix_list_32-6 0;
}
.lst-kix_list_6-8 > li {
  counter-increment: lst-ctn-kix_list_6-8;
}
.lst-kix_list_6-0 > li:before {
  content: "" counter(lst-ctn-kix_list_6-0, lower-latin) ") ";
}
.lst-kix_list_6-4 > li:before {
  content: "" counter(lst-ctn-kix_list_6-4, lower-latin) ". ";
}
ol.lst-kix_list_14-8.start {
  counter-reset: lst-ctn-kix_list_14-8 0;
}
ol.lst-kix_list_15-0.start {
  counter-reset: lst-ctn-kix_list_15-0 0;
}
ol.lst-kix_list_44-7.start {
  counter-reset: lst-ctn-kix_list_44-7 0;
}
.lst-kix_list_2-5 > li {
  counter-increment: lst-ctn-kix_list_2-5;
}
.lst-kix_list_6-7 > li:before {
  content: "" counter(lst-ctn-kix_list_6-7, lower-latin) ". ";
}
.lst-kix_list_6-6 > li:before {
  content: "" counter(lst-ctn-kix_list_6-6, decimal) ". ";
}
ol.lst-kix_list_10-6.start {
  counter-reset: lst-ctn-kix_list_10-6 0;
}
ol.lst-kix_list_27-6.start {
  counter-reset: lst-ctn-kix_list_27-6 0;
}
.lst-kix_list_7-6 > li:before {
  content: "" counter(lst-ctn-kix_list_7-6, decimal) ". ";
}
.lst-kix_list_18-5 > li {
  counter-increment: lst-ctn-kix_list_18-5;
}
ol.lst-kix_list_19-7.start {
  counter-reset: lst-ctn-kix_list_19-7 0;
}
ol.lst-kix_list_6-2.start {
  counter-reset: lst-ctn-kix_list_6-2 0;
}
.lst-kix_list_15-5 > li {
  counter-increment: lst-ctn-kix_list_15-5;
}
ol.lst-kix_list_24-1.start {
  counter-reset: lst-ctn-kix_list_24-1 0;
}
ol.lst-kix_list_36-3.start {
  counter-reset: lst-ctn-kix_list_36-3 0;
}
.lst-kix_list_7-2 > li:before {
  content: "" counter(lst-ctn-kix_list_7-2, lower-roman) ". ";
}
.lst-kix_list_7-6 > li {
  counter-increment: lst-ctn-kix_list_7-6;
}
.lst-kix_list_27-2 > li {
  counter-increment: lst-ctn-kix_list_27-2;
}
.lst-kix_list_31-0 > li {
  counter-increment: lst-ctn-kix_list_31-0;
}
ol.lst-kix_list_22-8.start {
  counter-reset: lst-ctn-kix_list_22-8 0;
}
.lst-kix_list_34-8 > li:before {
  content: "" counter(lst-ctn-kix_list_34-8, lower-roman) ". ";
}
.lst-kix_list_31-0 > li:before {
  content: "7." counter(lst-ctn-kix_list_31-0, decimal) " ";
}
.lst-kix_list_47-8 > li {
  counter-increment: lst-ctn-kix_list_47-8;
}
.lst-kix_list_12-6 > li {
  counter-increment: lst-ctn-kix_list_12-6;
}
ol.lst-kix_list_23-0.start {
  counter-reset: lst-ctn-kix_list_23-0 0;
}
ol.lst-kix_list_28-8 {
  list-style-type: none;
}
.lst-kix_list_9-8 > li {
  counter-increment: lst-ctn-kix_list_9-8;
}
ol.lst-kix_list_28-5 {
  list-style-type: none;
}
.lst-kix_list_13-4 > li {
  counter-increment: lst-ctn-kix_list_13-4;
}
ol.lst-kix_list_28-4 {
  list-style-type: none;
}
ol.lst-kix_list_28-7 {
  list-style-type: none;
}
ol.lst-kix_list_28-6 {
  list-style-type: none;
}
ol.lst-kix_list_28-1 {
  list-style-type: none;
}
ol.lst-kix_list_28-0 {
  list-style-type: none;
}
.lst-kix_list_31-4 > li:before {
  content: "" counter(lst-ctn-kix_list_31-4, lower-latin) ". ";
}
ol.lst-kix_list_28-3 {
  list-style-type: none;
}
ol.lst-kix_list_28-2 {
  list-style-type: none;
}
.lst-kix_list_15-7 > li:before {
  content: "" counter(lst-ctn-kix_list_15-7, lower-latin) ". ";
}
.lst-kix_list_17-7 > li {
  counter-increment: lst-ctn-kix_list_17-7;
}
.lst-kix_list_4-5 > li:before {
  content: "" counter(lst-ctn-kix_list_4-5, lower-roman) ". ";
}
.lst-kix_list_15-1 > li:before {
  content: "" counter(lst-ctn-kix_list_15-1, lower-latin) ". ";
}
ol.lst-kix_list_1-4.start {
  counter-reset: lst-ctn-kix_list_1-4 0;
}
.lst-kix_list_15-3 > li:before {
  content: "" counter(lst-ctn-kix_list_15-3, decimal) ". ";
}
.lst-kix_list_22-1 > li {
  counter-increment: lst-ctn-kix_list_22-1;
}
.lst-kix_list_42-7 > li {
  counter-increment: lst-ctn-kix_list_42-7;
}
ol.lst-kix_list_4-4.start {
  counter-reset: lst-ctn-kix_list_4-4 0;
}
.lst-kix_list_40-5 > li {
  counter-increment: lst-ctn-kix_list_40-5;
}
.lst-kix_list_30-2 > li {
  counter-increment: lst-ctn-kix_list_30-2;
}
ol.lst-kix_list_9-2.start {
  counter-reset: lst-ctn-kix_list_9-2 0;
}
.lst-kix_list_11-2 > li {
  counter-increment: lst-ctn-kix_list_11-2;
}
ol.lst-kix_list_8-8 {
  list-style-type: none;
}
.lst-kix_list_12-3 > li:before {
  content: "" counter(lst-ctn-kix_list_12-3, decimal) ". ";
}
ol.lst-kix_list_31-4.start {
  counter-reset: lst-ctn-kix_list_31-4 0;
}
.lst-kix_list_32-6 > li:before {
  content: "" counter(lst-ctn-kix_list_32-6, decimal) ". ";
}
ol.lst-kix_list_8-4 {
  list-style-type: none;
}
ol.lst-kix_list_8-5 {
  list-style-type: none;
}
.lst-kix_list_33-2 > li:before {
  content: "\0025aa  ";
}
ol.lst-kix_list_8-6 {
  list-style-type: none;
}
ol.lst-kix_list_8-7 {
  list-style-type: none;
}
ol.lst-kix_list_8-0 {
  list-style-type: none;
}
.lst-kix_list_16-3 > li {
  counter-increment: lst-ctn-kix_list_16-3;
}
ol.lst-kix_list_8-1 {
  list-style-type: none;
}
ol.lst-kix_list_8-2 {
  list-style-type: none;
}
.lst-kix_list_13-3 > li {
  counter-increment: lst-ctn-kix_list_13-3;
}
ol.lst-kix_list_8-3 {
  list-style-type: none;
}
ol.lst-kix_list_40-5.start {
  counter-reset: lst-ctn-kix_list_40-5 0;
}
.lst-kix_list_10-4 > li {
  counter-increment: lst-ctn-kix_list_10-4;
}
.lst-kix_list_14-1 > li {
  counter-increment: lst-ctn-kix_list_14-1;
}
.lst-kix_list_34-4 > li:before {
  content: "" counter(lst-ctn-kix_list_34-4, lower-latin) ". ";
}
.lst-kix_list_35-3 > li {
  counter-increment: lst-ctn-kix_list_35-3;
}
.lst-kix_list_28-0 > li {
  counter-increment: lst-ctn-kix_list_28-0;
}
.lst-kix_list_13-5 > li:before {
  content: "" counter(lst-ctn-kix_list_13-5, lower-roman) ". ";
}
ol.lst-kix_list_28-8.start {
  counter-reset: lst-ctn-kix_list_28-8 0;
}
.lst-kix_list_36-1 > li {
  counter-increment: lst-ctn-kix_list_36-1;
}
.lst-kix_list_36-7 > li {
  counter-increment: lst-ctn-kix_list_36-7;
}
.lst-kix_list_29-4 > li {
  counter-increment: lst-ctn-kix_list_29-4;
}
.lst-kix_list_33-6 > li:before {
  content: "\0025aa  ";
}
.lst-kix_list_32-4 > li {
  counter-increment: lst-ctn-kix_list_32-4;
}
.lst-kix_list_33-8 > li:before {
  content: "\0025aa  ";
}
ol.lst-kix_list_29-0.start {
  counter-reset: lst-ctn-kix_list_29-0 0;
}
.lst-kix_list_34-2 > li:before {
  content: "" counter(lst-ctn-kix_list_34-2, lower-roman) ". ";
}
.lst-kix_list_34-5 > li {
  counter-increment: lst-ctn-kix_list_34-5;
}
.lst-kix_list_30-5 > li:before {
  content: "" counter(lst-ctn-kix_list_30-5, lower-roman) ". ";
}
.lst-kix_list_35-0 > li:before {
  content: "9." counter(lst-ctn-kix_list_35-0, decimal) " ";
}
.lst-kix_list_35-1 > li:before {
  content: "" counter(lst-ctn-kix_list_35-1, lower-latin) ". ";
}
.lst-kix_list_35-4 > li:before {
  content: "" counter(lst-ctn-kix_list_35-4, lower-latin) ". ";
}
ol.lst-kix_list_40-6.start {
  counter-reset: lst-ctn-kix_list_40-6 0;
}
ol.lst-kix_list_11-6 {
  list-style-type: none;
}
.lst-kix_list_28-8 > li {
  counter-increment: lst-ctn-kix_list_28-8;
}
ol.lst-kix_list_11-7 {
  list-style-type: none;
}
.lst-kix_list_1-1 > li {
  counter-increment: lst-ctn-kix_list_1-1;
}
ol.lst-kix_list_11-8 {
  list-style-type: none;
}
.lst-kix_list_30-1 > li:before {
  content: "" counter(lst-ctn-kix_list_30-1, lower-latin) ". ";
}
ol.lst-kix_list_11-2 {
  list-style-type: none;
}
.lst-kix_list_3-0 > li:before {
  content: "" counter(lst-ctn-kix_list_3-0, lower-latin) ") ";
}
ol.lst-kix_list_11-3 {
  list-style-type: none;
}
ol.lst-kix_list_11-4 {
  list-style-type: none;
}
.lst-kix_list_30-2 > li:before {
  content: "" counter(lst-ctn-kix_list_30-2, lower-roman) ". ";
}
ol.lst-kix_list_11-5 {
  list-style-type: none;
}
ol.lst-kix_list_11-0 {
  list-style-type: none;
}
ol.lst-kix_list_11-1 {
  list-style-type: none;
}
.lst-kix_list_4-0 > li {
  counter-increment: lst-ctn-kix_list_4-0;
}
.lst-kix_list_31-6 > li {
  counter-increment: lst-ctn-kix_list_31-6;
}
ol.lst-kix_list_37-5.start {
  counter-reset: lst-ctn-kix_list_37-5 0;
}
.lst-kix_list_3-3 > li:before {
  content: "" counter(lst-ctn-kix_list_3-3, decimal) ". ";
}
.lst-kix_list_38-3 > li {
  counter-increment: lst-ctn-kix_list_38-3;
}
ol.lst-kix_list_10-7.start {
  counter-reset: lst-ctn-kix_list_10-7 0;
}
.lst-kix_list_17-1 > li {
  counter-increment: lst-ctn-kix_list_17-1;
}
.lst-kix_list_11-1 > li:before {
  content: "" counter(lst-ctn-kix_list_11-1, lower-latin) ". ";
}
ol.lst-kix_list_31-0.start {
  counter-reset: lst-ctn-kix_list_31-0 0;
}
.lst-kix_list_11-0 > li:before {
  content: "" counter(lst-ctn-kix_list_11-0, lower-latin) ") ";
}
ol.lst-kix_list_9-3.start {
  counter-reset: lst-ctn-kix_list_9-3 0;
}
.lst-kix_list_8-8 > li:before {
  content: "" counter(lst-ctn-kix_list_8-8, lower-roman) ". ";
}
.lst-kix_list_35-4 > li {
  counter-increment: lst-ctn-kix_list_35-4;
}
.lst-kix_list_43-5 > li {
  counter-increment: lst-ctn-kix_list_43-5;
}
.lst-kix_list_4-8 > li:before {
  content: "" counter(lst-ctn-kix_list_4-8, lower-roman) ". ";
}
.lst-kix_list_21-5 > li {
  counter-increment: lst-ctn-kix_list_21-5;
}
.lst-kix_list_14-2 > li {
  counter-increment: lst-ctn-kix_list_14-2;
}
.lst-kix_list_16-0 > li:before {
  content: "3." counter(lst-ctn-kix_list_16-0, decimal) " ";
}
.lst-kix_list_40-6 > li {
  counter-increment: lst-ctn-kix_list_40-6;
}
ol.lst-kix_list_4-8.start {
  counter-reset: lst-ctn-kix_list_4-8 0;
}
.lst-kix_list_8-4 > li {
  counter-increment: lst-ctn-kix_list_8-4;
}
.lst-kix_list_16-3 > li:before {
  content: "" counter(lst-ctn-kix_list_16-3, decimal) ". ";
}
ol.lst-kix_list_46-8 {
  list-style-type: none;
}
ol.lst-kix_list_37-4.start {
  counter-reset: lst-ctn-kix_list_37-4 0;
}
.lst-kix_list_41-1 > li {
  counter-increment: lst-ctn-kix_list_41-1;
}
ol.lst-kix_list_46-6 {
  list-style-type: none;
}
.lst-kix_list_44-0 > li {
  counter-increment: lst-ctn-kix_list_44-0;
}
ol.lst-kix_list_46-7 {
  list-style-type: none;
}
ol.lst-kix_list_46-4 {
  list-style-type: none;
}
ol.lst-kix_list_46-5 {
  list-style-type: none;
}
ol.lst-kix_list_46-2 {
  list-style-type: none;
}
ol.lst-kix_list_46-3 {
  list-style-type: none;
}
.lst-kix_list_17-8 > li:before {
  content: "" counter(lst-ctn-kix_list_17-8, lower-roman) ". ";
}
ol.lst-kix_list_46-0 {
  list-style-type: none;
}
ol.lst-kix_list_46-1 {
  list-style-type: none;
}
.lst-kix_list_7-0 > li:before {
  content: "(" counter(lst-ctn-kix_list_7-0, lower-latin) ") ";
}
ol.lst-kix_list_27-7.start {
  counter-reset: lst-ctn-kix_list_27-7 0;
}
ol.lst-kix_list_19-6.start {
  counter-reset: lst-ctn-kix_list_19-6 0;
}
ol.lst-kix_list_9-7.start {
  counter-reset: lst-ctn-kix_list_9-7 0;
}
.lst-kix_list_2-4 > li:before {
  content: "" counter(lst-ctn-kix_list_2-4, lower-latin) ". ";
}
.lst-kix_list_7-3 > li:before {
  content: "" counter(lst-ctn-kix_list_7-3, decimal) ". ";
}
.lst-kix_list_9-7 > li {
  counter-increment: lst-ctn-kix_list_9-7;
}
.lst-kix_list_13-8 > li:before {
  content: "" counter(lst-ctn-kix_list_13-8, lower-roman) ". ";
}
.lst-kix_list_31-1 > li:before {
  content: "" counter(lst-ctn-kix_list_31-1, lower-latin) ". ";
}
.lst-kix_list_18-7 > li:before {
  content: "" counter(lst-ctn-kix_list_18-7, lower-latin) ". ";
}
.lst-kix_list_15-4 > li:before {
  content: "" counter(lst-ctn-kix_list_15-4, lower-latin) ". ";
}
ol.lst-kix_list_19-1.start {
  counter-reset: lst-ctn-kix_list_19-1 2;
}
ol.lst-kix_list_24-8 {
  list-style-type: none;
}
.lst-kix_list_10-4 > li:before {
  content: "" counter(lst-ctn-kix_list_10-4, lower-latin) ". ";
}
.lst-kix_list_10-8 > li:before {
  content: "" counter(lst-ctn-kix_list_10-8, lower-roman) ". ";
}
ol.lst-kix_list_24-5 {
  list-style-type: none;
}
ol.lst-kix_list_24-4 {
  list-style-type: none;
}
ol.lst-kix_list_24-7 {
  list-style-type: none;
}
.lst-kix_list_15-0 > li:before {
  content: "" counter(lst-ctn-kix_list_15-0, lower-latin) ") ";
}
ol.lst-kix_list_24-6 {
  list-style-type: none;
}
ol.lst-kix_list_24-1 {
  list-style-type: none;
}
ol.lst-kix_list_14-3.start {
  counter-reset: lst-ctn-kix_list_14-3 0;
}
ol.lst-kix_list_24-0 {
  list-style-type: none;
}
ol.lst-kix_list_24-3 {
  list-style-type: none;
}
ol.lst-kix_list_24-2 {
  list-style-type: none;
}
ol.lst-kix_list_44-2.start {
  counter-reset: lst-ctn-kix_list_44-2 0;
}
ol.lst-kix_list_32-5.start {
  counter-reset: lst-ctn-kix_list_32-5 0;
}
.lst-kix_list_12-8 > li {
  counter-increment: lst-ctn-kix_list_12-8;
}
ol.lst-kix_list_4-0 {
  list-style-type: none;
}
ol.lst-kix_list_4-1 {
  list-style-type: none;
}
ol.lst-kix_list_4-2 {
  list-style-type: none;
}
ol.lst-kix_list_4-3 {
  list-style-type: none;
}
ol.lst-kix_list_37-7 {
  list-style-type: none;
}
ol.lst-kix_list_37-6 {
  list-style-type: none;
}
ol.lst-kix_list_14-4.start {
  counter-reset: lst-ctn-kix_list_14-4 0;
}
.lst-kix_list_9-7 > li:before {
  content: "" counter(lst-ctn-kix_list_9-7, lower-latin) ". ";
}
.lst-kix_list_2-4 > li {
  counter-increment: lst-ctn-kix_list_2-4;
}
.lst-kix_list_29-4 > li:before {
  content: "" counter(lst-ctn-kix_list_29-4, lower-latin) ". ";
}
ol.lst-kix_list_37-8 {
  list-style-type: none;
}
ol.lst-kix_list_37-3 {
  list-style-type: none;
}
ol.lst-kix_list_37-2 {
  list-style-type: none;
}
ol.lst-kix_list_32-2.start {
  counter-reset: lst-ctn-kix_list_32-2 0;
}
ol.lst-kix_list_37-5 {
  list-style-type: none;
}
ol.lst-kix_list_37-4 {
  list-style-type: none;
}
.lst-kix_list_11-4 > li:before {
  content: "" counter(lst-ctn-kix_list_11-4, lower-latin) ". ";
}
ol.lst-kix_list_37-1 {
  list-style-type: none;
}
ol.lst-kix_list_37-0 {
  list-style-type: none;
}
ol.lst-kix_list_37-0.start {
  counter-reset: lst-ctn-kix_list_37-0 0;
}
.lst-kix_list_29-0 > li:before {
  content: "7." counter(lst-ctn-kix_list_29-0, decimal) " ";
}
ol.lst-kix_list_4-8 {
  list-style-type: none;
}
ol.lst-kix_list_19-2.start {
  counter-reset: lst-ctn-kix_list_19-2 0;
}
ol.lst-kix_list_4-4 {
  list-style-type: none;
}
ol.lst-kix_list_4-5 {
  list-style-type: none;
}
ol.lst-kix_list_4-6 {
  list-style-type: none;
}
.lst-kix_list_12-0 > li:before {
  content: "" counter(lst-ctn-kix_list_12-0, decimal) ". ";
}
ol.lst-kix_list_4-7 {
  list-style-type: none;
}
.lst-kix_list_1-4 > li:before {
  content: "" counter(lst-ctn-kix_list_1-0, decimal) "."
    counter(lst-ctn-kix_list_1-1, decimal) "."
    counter(lst-ctn-kix_list_1-2, decimal) "."
    counter(lst-ctn-kix_list_1-3, decimal) "."
    counter(lst-ctn-kix_list_1-4, decimal) " ";
}
ol.lst-kix_list_44-3.start {
  counter-reset: lst-ctn-kix_list_44-3 0;
}
.lst-kix_list_1-6 > li {
  counter-increment: lst-ctn-kix_list_1-6;
}
.lst-kix_list_34-5 > li:before {
  content: "" counter(lst-ctn-kix_list_34-5, lower-roman) ". ";
}
.lst-kix_list_33-5 > li:before {
  content: "\0025aa  ";
}
ol.lst-kix_list_19-3.start {
  counter-reset: lst-ctn-kix_list_19-3 0;
}
.lst-kix_list_2-0 > li:before {
  content: "" counter(lst-ctn-kix_list_2-0, lower-latin) ") ";
}
ol.lst-kix_list_9-8.start {
  counter-reset: lst-ctn-kix_list_9-8 0;
}
.lst-kix_list_1-8 > li:before {
  content: "" counter(lst-ctn-kix_list_1-0, decimal) "."
    counter(lst-ctn-kix_list_1-1, decimal) "."
    counter(lst-ctn-kix_list_1-2, decimal) "."
    counter(lst-ctn-kix_list_1-3, decimal) "."
    counter(lst-ctn-kix_list_1-4, decimal) "."
    counter(lst-ctn-kix_list_1-5, decimal) "."
    counter(lst-ctn-kix_list_1-6, decimal) "."
    counter(lst-ctn-kix_list_1-7, decimal) "."
    counter(lst-ctn-kix_list_1-8, decimal) " ";
}
.lst-kix_list_34-1 > li:before {
  content: "" counter(lst-ctn-kix_list_34-1, lower-latin) ". ";
}
.lst-kix_list_19-0 > li:before {
  content: "" counter(lst-ctn-kix_list_19-0, decimal) " ";
}
.lst-kix_list_8-1 > li {
  counter-increment: lst-ctn-kix_list_8-1;
}
.lst-kix_list_7-0 > li {
  counter-increment: lst-ctn-kix_list_7-0;
}
.lst-kix_list_19-0 > li {
  counter-increment: lst-ctn-kix_list_19-0;
}
ol.lst-kix_list_44-6.start {
  counter-reset: lst-ctn-kix_list_44-6 0;
}
.lst-kix_list_29-6 > li {
  counter-increment: lst-ctn-kix_list_29-6;
}
.lst-kix_list_47-2 > li:before {
  content: "" counter(lst-ctn-kix_list_47-2, lower-roman) ". ";
}
ol.lst-kix_list_31-3.start {
  counter-reset: lst-ctn-kix_list_31-3 0;
}
ol.lst-kix_list_19-8.start {
  counter-reset: lst-ctn-kix_list_19-8 0;
}
ol.lst-kix_list_41-7 {
  list-style-type: none;
}
ol.lst-kix_list_41-8 {
  list-style-type: none;
}
ol.lst-kix_list_36-4.start {
  counter-reset: lst-ctn-kix_list_36-4 0;
}
ol.lst-kix_list_41-5 {
  list-style-type: none;
}
ol.lst-kix_list_41-6 {
  list-style-type: none;
}
ol.lst-kix_list_41-3 {
  list-style-type: none;
}
ol.lst-kix_list_41-4 {
  list-style-type: none;
}
ol.lst-kix_list_41-1 {
  list-style-type: none;
}
ol.lst-kix_list_14-7.start {
  counter-reset: lst-ctn-kix_list_14-7 0;
}
ol.lst-kix_list_41-2 {
  list-style-type: none;
}
ol.lst-kix_list_41-0 {
  list-style-type: none;
}
.lst-kix_list_19-7 > li:before {
  content: "" counter(lst-ctn-kix_list_19-0, decimal) "."
    counter(lst-ctn-kix_list_19-1, decimal) "."
    counter(lst-ctn-kix_list_19-2, decimal) "."
    counter(lst-ctn-kix_list_19-3, decimal) "."
    counter(lst-ctn-kix_list_19-4, decimal) "."
    counter(lst-ctn-kix_list_19-5, decimal) "."
    counter(lst-ctn-kix_list_19-6, decimal) "."
    counter(lst-ctn-kix_list_19-7, decimal) " ";
}
.lst-kix_list_9-2 > li {
  counter-increment: lst-ctn-kix_list_9-2;
}
ol.lst-kix_list_23-8.start {
  counter-reset: lst-ctn-kix_list_23-8 0;
}
.lst-kix_list_24-5 > li {
  counter-increment: lst-ctn-kix_list_24-5;
}
li.li-bullet-6:before {
  margin-left: -29.2pt;
  white-space: nowrap;
  display: inline-block;
  min-width: 29.2pt;
}
.lst-kix_list_46-3 > li:before {
  content: "" counter(lst-ctn-kix_list_46-3, decimal) ". ";
}
.lst-kix_list_38-1 > li:before {
  content: "" counter(lst-ctn-kix_list_38-1, lower-latin) ". ";
}
ol.lst-kix_list_15-6.start {
  counter-reset: lst-ctn-kix_list_15-6 0;
}
.lst-kix_list_37-2 > li:before {
  content: "" counter(lst-ctn-kix_list_37-2, lower-roman) ". ";
}
.lst-kix_list_46-5 > li {
  counter-increment: lst-ctn-kix_list_46-5;
}
.lst-kix_list_25-6 > li {
  counter-increment: lst-ctn-kix_list_25-6;
}
.lst-kix_list_37-5 > li:before {
  content: "" counter(lst-ctn-kix_list_37-5, lower-roman) ". ";
}
.lst-kix_list_46-0 > li:before {
  content: "" counter(lst-ctn-kix_list_46-0, decimal) ". ";
}
ol.lst-kix_list_31-8.start {
  counter-reset: lst-ctn-kix_list_31-8 0;
}
.lst-kix_list_18-1 > li:before {
  content: "" counter(lst-ctn-kix_list_18-1, lower-latin) ". ";
}
.lst-kix_list_38-8 > li:before {
  content: "" counter(lst-ctn-kix_list_38-8, lower-roman) ". ";
}
.lst-kix_list_40-3 > li {
  counter-increment: lst-ctn-kix_list_40-3;
}
.lst-kix_list_44-3 > li {
  counter-increment: lst-ctn-kix_list_44-3;
}
.lst-kix_list_23-4 > li {
  counter-increment: lst-ctn-kix_list_23-4;
}
ol.lst-kix_list_23-1.start {
  counter-reset: lst-ctn-kix_list_23-1 0;
}
ol.lst-kix_list_32-0.start {
  counter-reset: lst-ctn-kix_list_32-0 0;
}
.lst-kix_list_23-1 > li {
  counter-increment: lst-ctn-kix_list_23-1;
}
.lst-kix_list_2-7 > li:before {
  content: "" counter(lst-ctn-kix_list_2-7, lower-latin) ". ";
}
.lst-kix_list_2-7 > li {
  counter-increment: lst-ctn-kix_list_2-7;
}
.lst-kix_list_24-2 > li {
  counter-increment: lst-ctn-kix_list_24-2;
}
ol.lst-kix_list_32-8 {
  list-style-type: none;
}
ol.lst-kix_list_32-7 {
  list-style-type: none;
}
.lst-kix_list_27-5 > li:before {
  content: "" counter(lst-ctn-kix_list_27-5, lower-roman) ". ";
}
ol.lst-kix_list_32-4 {
  list-style-type: none;
}
ol.lst-kix_list_32-3 {
  list-style-type: none;
}
ol.lst-kix_list_32-6 {
  list-style-type: none;
}
ol.lst-kix_list_32-5 {
  list-style-type: none;
}
ol.lst-kix_list_32-0 {
  list-style-type: none;
}
ol.lst-kix_list_10-3.start {
  counter-reset: lst-ctn-kix_list_10-3 0;
}
.lst-kix_list_22-3 > li {
  counter-increment: lst-ctn-kix_list_22-3;
}
ol.lst-kix_list_32-2 {
  list-style-type: none;
}
ol.lst-kix_list_32-1 {
  list-style-type: none;
}
.lst-kix_list_39-7 > li:before {
  content: "" counter(lst-ctn-kix_list_39-7, lower-latin) ". ";
}
.lst-kix_list_46-8 > li {
  counter-increment: lst-ctn-kix_list_46-8;
}
.lst-kix_list_10-1 > li:before {
  content: "" counter(lst-ctn-kix_list_10-1, lower-latin) ". ";
}
.lst-kix_list_18-4 > li:before {
  content: "" counter(lst-ctn-kix_list_18-4, lower-latin) ". ";
}
ol.lst-kix_list_15-1.start {
  counter-reset: lst-ctn-kix_list_15-1 0;
}
ol.lst-kix_list_15-4.start {
  counter-reset: lst-ctn-kix_list_15-4 0;
}
.lst-kix_list_36-6 > li:before {
  content: "" counter(lst-ctn-kix_list_36-6, decimal) ". ";
}
.lst-kix_list_47-6 > li {
  counter-increment: lst-ctn-kix_list_47-6;
}
.lst-kix_list_36-0 > li:before {
  content: "(" counter(lst-ctn-kix_list_36-0, lower-latin) ") ";
}
ol.lst-kix_list_40-2.start {
  counter-reset: lst-ctn-kix_list_40-2 0;
}
ol.lst-kix_list_44-8.start {
  counter-reset: lst-ctn-kix_list_44-8 0;
}
.lst-kix_list_34-0 > li {
  counter-increment: lst-ctn-kix_list_34-0;
}
ol.lst-kix_list_45-0.start {
  counter-reset: lst-ctn-kix_list_45-0 0;
}
.lst-kix_list_26-4 > li {
  counter-increment: lst-ctn-kix_list_26-4;
}
ol.lst-kix_list_5-7.start {
  counter-reset: lst-ctn-kix_list_5-7 0;
}
ol.lst-kix_list_28-7.start {
  counter-reset: lst-ctn-kix_list_28-7 0;
}
.lst-kix_list_20-8 > li:before {
  content: "" counter(lst-ctn-kix_list_20-8, lower-roman) ". ";
}
.lst-kix_list_39-1 > li {
  counter-increment: lst-ctn-kix_list_39-1;
}
.lst-kix_list_46-6 > li:before {
  content: "" counter(lst-ctn-kix_list_46-6, decimal) ". ";
}
.lst-kix_list_29-7 > li:before {
  content: "" counter(lst-ctn-kix_list_29-7, lower-latin) ". ";
}
ol.lst-kix_list_10-7 {
  list-style-type: none;
}
ol.lst-kix_list_10-8 {
  list-style-type: none;
}
.lst-kix_list_9-4 > li:before {
  content: "" counter(lst-ctn-kix_list_9-4, lower-latin) ". ";
}
ol.lst-kix_list_10-3 {
  list-style-type: none;
}
.lst-kix_list_30-8 > li {
  counter-increment: lst-ctn-kix_list_30-8;
}
ol.lst-kix_list_10-4 {
  list-style-type: none;
}
ol.lst-kix_list_10-5 {
  list-style-type: none;
}
ol.lst-kix_list_10-6 {
  list-style-type: none;
}
ol.lst-kix_list_10-0 {
  list-style-type: none;
}
ol.lst-kix_list_10-1 {
  list-style-type: none;
}
ol.lst-kix_list_10-2 {
  list-style-type: none;
}
ol.lst-kix_list_36-2.start {
  counter-reset: lst-ctn-kix_list_36-2 0;
}
.lst-kix_list_1-1 > li:before {
  content: "" counter(lst-ctn-kix_list_1-0, decimal) "."
    counter(lst-ctn-kix_list_1-1, decimal) " ";
}
.lst-kix_list_11-7 > li:before {
  content: "" counter(lst-ctn-kix_list_11-7, lower-latin) ". ";
}
ol.lst-kix_list_10-5.start {
  counter-reset: lst-ctn-kix_list_10-5 0;
}
.lst-kix_list_14-7 > li {
  counter-increment: lst-ctn-kix_list_14-7;
}
ol.lst-kix_list_31-5.start {
  counter-reset: lst-ctn-kix_list_31-5 0;
}
ol.lst-kix_list_40-4.start {
  counter-reset: lst-ctn-kix_list_40-4 0;
}
.lst-kix_list_28-1 > li:before {
  content: "" counter(lst-ctn-kix_list_28-1, lower-latin) ". ";
}
ol.lst-kix_list_19-6 {
  list-style-type: none;
}
ol.lst-kix_list_19-7 {
  list-style-type: none;
}
ol.lst-kix_list_19-8 {
  list-style-type: none;
}
ol.lst-kix_list_19-2 {
  list-style-type: none;
}
ol.lst-kix_list_19-3 {
  list-style-type: none;
}
ol.lst-kix_list_19-4 {
  list-style-type: none;
}
.lst-kix_list_30-8 > li:before {
  content: "" counter(lst-ctn-kix_list_30-8, lower-roman) ". ";
}
ol.lst-kix_list_19-5 {
  list-style-type: none;
}
.lst-kix_list_35-7 > li:before {
  content: "" counter(lst-ctn-kix_list_35-7, lower-latin) ". ";
}
.lst-kix_list_30-5 > li {
  counter-increment: lst-ctn-kix_list_30-5;
}
ol.lst-kix_list_19-0 {
  list-style-type: none;
}
ol.lst-kix_list_19-1 {
  list-style-type: none;
}
.lst-kix_list_26-6 > li:before {
  content: "" counter(lst-ctn-kix_list_26-6, decimal) ". ";
}
.lst-kix_list_8-2 > li:before {
  content: "" counter(lst-ctn-kix_list_8-2, lower-roman) ". ";
}
ol.lst-kix_list_36-7.start {
  counter-reset: lst-ctn-kix_list_36-7 0;
}
ol.lst-kix_list_31-6.start {
  counter-reset: lst-ctn-kix_list_31-6 0;
}
.lst-kix_list_46-2 > li {
  counter-increment: lst-ctn-kix_list_46-2;
}
.lst-kix_list_12-0 > li {
  counter-increment: lst-ctn-kix_list_12-0;
}
.lst-kix_list_47-3 > li {
  counter-increment: lst-ctn-kix_list_47-3;
}
ol.lst-kix_list_40-0.start {
  counter-reset: lst-ctn-kix_list_40-0 0;
}
.lst-kix_list_8-5 > li:before {
  content: "" counter(lst-ctn-kix_list_8-5, lower-roman) ". ";
}
ol.lst-kix_list_28-0.start {
  counter-reset: lst-ctn-kix_list_28-0 0;
}
.lst-kix_list_26-3 > li:before {
  content: "" counter(lst-ctn-kix_list_26-3, decimal) ". ";
}
.lst-kix_list_3-6 > li:before {
  content: "" counter(lst-ctn-kix_list_3-6, decimal) ". ";
}
.lst-kix_list_21-7 > li:before {
  content: "" counter(lst-ctn-kix_list_21-7, lower-latin) ". ";
}
ol.lst-kix_list_5-0.start {
  counter-reset: lst-ctn-kix_list_5-0 0;
}
.lst-kix_list_39-4 > li {
  counter-increment: lst-ctn-kix_list_39-4;
}
ol.lst-kix_list_31-7.start {
  counter-reset: lst-ctn-kix_list_31-7 0;
}
.lst-kix_list_16-6 > li:before {
  content: "" counter(lst-ctn-kix_list_16-6, decimal) ". ";
}
ol.lst-kix_list_10-1.start {
  counter-reset: lst-ctn-kix_list_10-1 0;
}
.lst-kix_list_18-2 > li {
  counter-increment: lst-ctn-kix_list_18-2;
}
ol.lst-kix_list_5-6.start {
  counter-reset: lst-ctn-kix_list_5-6 0;
}
.lst-kix_list_22-6 > li {
  counter-increment: lst-ctn-kix_list_22-6;
}
.lst-kix_list_7-3 > li {
  counter-increment: lst-ctn-kix_list_7-3;
}
.lst-kix_list_25-0 > li {
  counter-increment: lst-ctn-kix_list_25-0;
}
.lst-kix_list_45-4 > li:before {
  content: "o  ";
}
.lst-kix_list_45-7 > li:before {
  content: "o  ";
}
ol.lst-kix_list_28-6.start {
  counter-reset: lst-ctn-kix_list_28-6 0;
}
.lst-kix_list_19-3 > li {
  counter-increment: lst-ctn-kix_list_19-3;
}
ol.lst-kix_list_28-5.start {
  counter-reset: lst-ctn-kix_list_28-5 0;
}
.lst-kix_list_44-8 > li:before {
  content: "" counter(lst-ctn-kix_list_44-8, lower-roman) ". ";
}
.lst-kix_list_23-7 > li {
  counter-increment: lst-ctn-kix_list_23-7;
}
.lst-kix_list_44-6 > li {
  counter-increment: lst-ctn-kix_list_44-6;
}
ol.lst-kix_list_10-2.start {
  counter-reset: lst-ctn-kix_list_10-2 0;
}
.lst-kix_list_40-0 > li {
  counter-increment: lst-ctn-kix_list_40-0;
}
.lst-kix_list_17-2 > li:before {
  content: "" counter(lst-ctn-kix_list_17-2, lower-roman) ". ";
}
ol.lst-kix_list_5-5.start {
  counter-reset: lst-ctn-kix_list_5-5 0;
}
ol.lst-kix_list_40-1.start {
  counter-reset: lst-ctn-kix_list_40-1 0;
}
.lst-kix_list_17-5 > li:before {
  content: "" counter(lst-ctn-kix_list_17-5, lower-roman) ". ";
}
.lst-kix_list_6-2 > li {
  counter-increment: lst-ctn-kix_list_6-2;
}
ol.lst-kix_list_36-6.start {
  counter-reset: lst-ctn-kix_list_36-6 0;
}
.lst-kix_list_27-2 > li:before {
  content: "" counter(lst-ctn-kix_list_27-2, lower-roman) ". ";
}
.lst-kix_list_22-3 > li:before {
  content: "" counter(lst-ctn-kix_list_22-3, decimal) ". ";
}
ol.lst-kix_list_28-4.start {
  counter-reset: lst-ctn-kix_list_28-4 0;
}
.lst-kix_list_43-8 > li {
  counter-increment: lst-ctn-kix_list_43-8;
}
ol.lst-kix_list_23-3.start {
  counter-reset: lst-ctn-kix_list_23-3 0;
}
ol.lst-kix_list_5-4.start {
  counter-reset: lst-ctn-kix_list_5-4 0;
}
ol.lst-kix_list_5-1.start {
  counter-reset: lst-ctn-kix_list_5-1 0;
}
.lst-kix_list_25-3 > li {
  counter-increment: lst-ctn-kix_list_25-3;
}
.lst-kix_list_16-6 > li {
  counter-increment: lst-ctn-kix_list_16-6;
}
ol.lst-kix_list_28-1.start {
  counter-reset: lst-ctn-kix_list_28-1 0;
}
.lst-kix_list_31-7 > li:before {
  content: "" counter(lst-ctn-kix_list_31-7, lower-latin) ". ";
}
ol.lst-kix_list_10-0.start {
  counter-reset: lst-ctn-kix_list_10-0 0;
}
.lst-kix_list_3-8 > li {
  counter-increment: lst-ctn-kix_list_3-8;
}
.lst-kix_list_4-6 > li {
  counter-increment: lst-ctn-kix_list_4-6;
}
.lst-kix_list_4-2 > li:before {
  content: "" counter(lst-ctn-kix_list_4-2, lower-roman) ". ";
}
ol.lst-kix_list_23-6.start {
  counter-reset: lst-ctn-kix_list_23-6 0;
}
.lst-kix_list_17-4 > li {
  counter-increment: lst-ctn-kix_list_17-4;
}
.lst-kix_list_36-3 > li:before {
  content: "" counter(lst-ctn-kix_list_36-3, decimal) ". ";
}
.lst-kix_list_26-1 > li {
  counter-increment: lst-ctn-kix_list_26-1;
}
.lst-kix_list_9-1 > li:before {
  content: "" counter(lst-ctn-kix_list_9-1, lower-latin) ". ";
}
.lst-kix_list_15-8 > li {
  counter-increment: lst-ctn-kix_list_15-8;
}
.lst-kix_list_40-8 > li:before {
  content: "" counter(lst-ctn-kix_list_40-8, lower-roman) ". ";
}
ol.lst-kix_list_36-8.start {
  counter-reset: lst-ctn-kix_list_36-8 0;
}
.lst-kix_list_37-8 > li {
  counter-increment: lst-ctn-kix_list_37-8;
}
.lst-kix_list_31-3 > li {
  counter-increment: lst-ctn-kix_list_31-3;
}
.lst-kix_list_41-4 > li:before {
  content: "" counter(lst-ctn-kix_list_41-4, lower-latin) ". ";
}
ol.lst-kix_list_23-5.start {
  counter-reset: lst-ctn-kix_list_23-5 0;
}
.lst-kix_list_10-1 > li {
  counter-increment: lst-ctn-kix_list_10-1;
}
ol.lst-kix_list_28-2.start {
  counter-reset: lst-ctn-kix_list_28-2 0;
}
.lst-kix_list_12-6 > li:before {
  content: "" counter(lst-ctn-kix_list_12-6, decimal) ". ";
}
ol.lst-kix_list_23-4.start {
  counter-reset: lst-ctn-kix_list_23-4 0;
}
.lst-kix_list_32-1 > li {
  counter-increment: lst-ctn-kix_list_32-1;
}
.lst-kix_list_13-2 > li:before {
  content: "" counter(lst-ctn-kix_list_13-2, lower-roman) ". ";
}
ol.lst-kix_list_5-2.start {
  counter-reset: lst-ctn-kix_list_5-2 0;
}
ol.lst-kix_list_7-0 {
  list-style-type: none;
}
.lst-kix_list_2-1 > li {
  counter-increment: lst-ctn-kix_list_2-1;
}
ol.lst-kix_list_9-0.start {
  counter-reset: lst-ctn-kix_list_9-0 0;
}
ol.lst-kix_list_30-0.start {
  counter-reset: lst-ctn-kix_list_30-0 0;
}
ol.lst-kix_list_13-4.start {
  counter-reset: lst-ctn-kix_list_13-4 0;
}
.lst-kix_list_30-6 > li {
  counter-increment: lst-ctn-kix_list_30-6;
}
ol.lst-kix_list_40-3.start {
  counter-reset: lst-ctn-kix_list_40-3 0;
}
.lst-kix_list_42-8 > li:before {
  content: "" counter(lst-ctn-kix_list_42-8, lower-roman) ". ";
}
.lst-kix_list_13-0 > li {
  counter-increment: lst-ctn-kix_list_13-0;
}
ol.lst-kix_list_43-8.start {
  counter-reset: lst-ctn-kix_list_43-8 0;
}
ol.lst-kix_list_20-2.start {
  counter-reset: lst-ctn-kix_list_20-2 0;
}
ol.lst-kix_list_7-5 {
  list-style-type: none;
}
ol.lst-kix_list_7-6 {
  list-style-type: none;
}
ol.lst-kix_list_7-7 {
  list-style-type: none;
}
ol.lst-kix_list_7-8 {
  list-style-type: none;
}
ol.lst-kix_list_7-1 {
  list-style-type: none;
}
ol.lst-kix_list_7-2 {
  list-style-type: none;
}
ol.lst-kix_list_7-3 {
  list-style-type: none;
}
ol.lst-kix_list_7-4 {
  list-style-type: none;
}
.lst-kix_list_42-0 > li:before {
  content: "3." counter(lst-ctn-kix_list_42-0, decimal) " ";
}
ol.lst-kix_list_23-2.start {
  counter-reset: lst-ctn-kix_list_23-2 0;
}
.lst-kix_list_42-2 > li:before {
  content: "" counter(lst-ctn-kix_list_42-2, lower-roman) ". ";
}
ol.lst-kix_list_46-8.start {
  counter-reset: lst-ctn-kix_list_46-8 0;
}
.lst-kix_list_43-0 > li {
  counter-increment: lst-ctn-kix_list_43-0;
}
.lst-kix_list_35-7 > li {
  counter-increment: lst-ctn-kix_list_35-7;
}
.lst-kix_list_29-8 > li {
  counter-increment: lst-ctn-kix_list_29-8;
}
.lst-kix_list_42-6 > li:before {
  content: "" counter(lst-ctn-kix_list_42-6, decimal) ". ";
}
.lst-kix_list_24-7 > li {
  counter-increment: lst-ctn-kix_list_24-7;
}
ol.lst-kix_list_30-5.start {
  counter-reset: lst-ctn-kix_list_30-5 0;
}
.lst-kix_list_42-4 > li:before {
  content: "" counter(lst-ctn-kix_list_42-4, lower-latin) ". ";
}
.lst-kix_list_18-8 > li {
  counter-increment: lst-ctn-kix_list_18-8;
}
ol.lst-kix_list_26-7.start {
  counter-reset: lst-ctn-kix_list_26-7 0;
}
.lst-kix_list_7-2 > li {
  counter-increment: lst-ctn-kix_list_7-2;
}
ol.lst-kix_list_10-4.start {
  counter-reset: lst-ctn-kix_list_10-4 0;
}
ol.lst-kix_list_36-5.start {
  counter-reset: lst-ctn-kix_list_36-5 0;
}
.lst-kix_list_24-7 > li:before {
  content: "" counter(lst-ctn-kix_list_24-7, lower-latin) ". ";
}
.lst-kix_list_1-4 > li {
  counter-increment: lst-ctn-kix_list_1-4;
}
ol.lst-kix_list_1-6.start {
  counter-reset: lst-ctn-kix_list_1-6 0;
}
ol.lst-kix_list_9-5.start {
  counter-reset: lst-ctn-kix_list_9-5 0;
}
ol.lst-kix_list_40-8.start {
  counter-reset: lst-ctn-kix_list_40-8 0;
}
ol.lst-kix_list_20-7.start {
  counter-reset: lst-ctn-kix_list_20-7 0;
}
.lst-kix_list_24-3 > li:before {
  content: "" counter(lst-ctn-kix_list_24-3, decimal) ". ";
}
.lst-kix_list_24-5 > li:before {
  content: "" counter(lst-ctn-kix_list_24-5, lower-roman) ". ";
}
ol.lst-kix_list_43-3.start {
  counter-reset: lst-ctn-kix_list_43-3 0;
}
ol.lst-kix_list_16-4.start {
  counter-reset: lst-ctn-kix_list_16-4 0;
}
.lst-kix_list_6-5 > li {
  counter-increment: lst-ctn-kix_list_6-5;
}
ol.lst-kix_list_27-6 {
  list-style-type: none;
}
ol.lst-kix_list_27-5 {
  list-style-type: none;
}
ol.lst-kix_list_27-8 {
  list-style-type: none;
}
.lst-kix_list_23-3 > li:before {
  content: "" counter(lst-ctn-kix_list_23-3, decimal) ". ";
}
.lst-kix_list_23-7 > li:before {
  content: "" counter(lst-ctn-kix_list_23-7, lower-latin) ". ";
}
ol.lst-kix_list_27-7 {
  list-style-type: none;
}
ol.lst-kix_list_27-2 {
  list-style-type: none;
}
ol.lst-kix_list_27-1 {
  list-style-type: none;
}
ol.lst-kix_list_23-7.start {
  counter-reset: lst-ctn-kix_list_23-7 0;
}
ol.lst-kix_list_27-4 {
  list-style-type: none;
}
ol.lst-kix_list_27-3 {
  list-style-type: none;
}
ol.lst-kix_list_46-3.start {
  counter-reset: lst-ctn-kix_list_46-3 0;
}
ol.lst-kix_list_27-0 {
  list-style-type: none;
}
.lst-kix_list_23-1 > li:before {
  content: "" counter(lst-ctn-kix_list_23-1, lower-latin) ". ";
}
.lst-kix_list_24-1 > li:before {
  content: "" counter(lst-ctn-kix_list_24-1, lower-latin) ". ";
}
.lst-kix_list_2-8 > li {
  counter-increment: lst-ctn-kix_list_2-8;
}
ol.lst-kix_list_26-2.start {
  counter-reset: lst-ctn-kix_list_26-2 0;
}
.lst-kix_list_23-5 > li:before {
  content: "" counter(lst-ctn-kix_list_23-5, lower-roman) ". ";
}
ol.lst-kix_list_36-0.start {
  counter-reset: lst-ctn-kix_list_36-0 0;
}
.lst-kix_list_8-6 > li {
  counter-increment: lst-ctn-kix_list_8-6;
}
ol.lst-kix_list_4-6.start {
  counter-reset: lst-ctn-kix_list_4-6 0;
}
ol.lst-kix_list_39-5.start {
  counter-reset: lst-ctn-kix_list_39-5 0;
}
ol.lst-kix_list_3-0.start {
  counter-reset: lst-ctn-kix_list_3-0 0;
}
ol.lst-kix_list_29-2.start {
  counter-reset: lst-ctn-kix_list_29-2 0;
}
.lst-kix_list_25-5 > li:before {
  content: "" counter(lst-ctn-kix_list_25-5, lower-roman) ". ";
}
.lst-kix_list_25-7 > li:before {
  content: "" counter(lst-ctn-kix_list_25-7, lower-latin) ". ";
}
.lst-kix_list_3-5 > li {
  counter-increment: lst-ctn-kix_list_3-5;
}
ol.lst-kix_list_1-1.start {
  counter-reset: lst-ctn-kix_list_1-1 0;
}
ol.lst-kix_list_18-3.start {
  counter-reset: lst-ctn-kix_list_18-3 0;
}
ol.lst-kix_list_18-7 {
  list-style-type: none;
}
ol.lst-kix_list_18-8 {
  list-style-type: none;
}
ol.lst-kix_list_18-3 {
  list-style-type: none;
}
ol.lst-kix_list_18-4 {
  list-style-type: none;
}
ol.lst-kix_list_18-5 {
  list-style-type: none;
}
ol.lst-kix_list_18-6 {
  list-style-type: none;
}
ol.lst-kix_list_18-0 {
  list-style-type: none;
}
ol.lst-kix_list_18-1 {
  list-style-type: none;
}
.lst-kix_list_9-3 > li {
  counter-increment: lst-ctn-kix_list_9-3;
}
ol.lst-kix_list_18-2 {
  list-style-type: none;
}
ol.lst-kix_list_7-6.start {
  counter-reset: lst-ctn-kix_list_7-6 0;
}
ol.lst-kix_list_15-3.start {
  counter-reset: lst-ctn-kix_list_15-3 0;
}
ol.lst-kix_list_6-0.start {
  counter-reset: lst-ctn-kix_list_6-0 0;
}
.lst-kix_list_4-2 > li {
  counter-increment: lst-ctn-kix_list_4-2;
}
ol.lst-kix_list_3-1 {
  list-style-type: none;
}
ol.lst-kix_list_3-2 {
  list-style-type: none;
}
ol.lst-kix_list_3-3 {
  list-style-type: none;
}
.lst-kix_list_5-1 > li {
  counter-increment: lst-ctn-kix_list_5-1;
}
ol.lst-kix_list_3-4 {
  list-style-type: none;
}
ol.lst-kix_list_21-3.start {
  counter-reset: lst-ctn-kix_list_21-3 0;
}
ol.lst-kix_list_3-0 {
  list-style-type: none;
}
.lst-kix_list_38-5 > li {
  counter-increment: lst-ctn-kix_list_38-5;
}
ol.lst-kix_list_25-6.start {
  counter-reset: lst-ctn-kix_list_25-6 0;
}
ol.lst-kix_list_32-4.start {
  counter-reset: lst-ctn-kix_list_32-4 0;
}
.lst-kix_list_26-5 > li:before {
  content: "" counter(lst-ctn-kix_list_26-5, lower-roman) ". ";
}
.lst-kix_list_16-0 > li {
  counter-increment: lst-ctn-kix_list_16-0;
}
.lst-kix_list_10-0 > li {
  counter-increment: lst-ctn-kix_list_10-0;
}
ol.lst-kix_list_3-5 {
  list-style-type: none;
}
ol.lst-kix_list_3-6 {
  list-style-type: none;
}
ol.lst-kix_list_3-7 {
  list-style-type: none;
}
ol.lst-kix_list_3-8 {
  list-style-type: none;
}
.lst-kix_list_26-1 > li:before {
  content: "" counter(lst-ctn-kix_list_26-1, lower-latin) ". ";
}
.lst-kix_list_21-1 > li:before {
  content: "" counter(lst-ctn-kix_list_21-1, lower-latin) ". ";
}
ol.lst-kix_list_15-8.start {
  counter-reset: lst-ctn-kix_list_15-8 0;
}
.lst-kix_list_34-3 > li {
  counter-increment: lst-ctn-kix_list_34-3;
}
ol.lst-kix_list_45-0 {
  list-style-type: none;
}
.lst-kix_list_10-2 > li {
  counter-increment: lst-ctn-kix_list_10-2;
}
.lst-kix_list_21-5 > li:before {
  content: "" counter(lst-ctn-kix_list_21-5, lower-roman) ". ";
}
.lst-kix_list_45-2 > li:before {
  content: "\0025aa  ";
}
ol.lst-kix_list_27-8.start {
  counter-reset: lst-ctn-kix_list_27-8 0;
}
.lst-kix_list_21-0 > li {
  counter-increment: lst-ctn-kix_list_21-0;
}
.lst-kix_list_25-1 > li:before {
  content: "" counter(lst-ctn-kix_list_25-1, lower-latin) ". ";
}
ol.lst-kix_list_31-1.start {
  counter-reset: lst-ctn-kix_list_31-1 0;
}
.lst-kix_list_45-6 > li:before {
  content: "\0025cf  ";
}
.lst-kix_list_44-6 > li:before {
  content: "" counter(lst-ctn-kix_list_44-6, decimal) ". ";
}
ol.lst-kix_list_7-1.start {
  counter-reset: lst-ctn-kix_list_7-1 0;
}
.lst-kix_list_44-2 > li:before {
  content: "" counter(lst-ctn-kix_list_44-2, lower-roman) ". ";
}
ol.lst-kix_list_23-6 {
  list-style-type: none;
}
ol.lst-kix_list_23-5 {
  list-style-type: none;
}
ol.lst-kix_list_23-8 {
  list-style-type: none;
}
.lst-kix_list_39-1 > li:before {
  content: "" counter(lst-ctn-kix_list_39-1, lower-latin) ". ";
}
ol.lst-kix_list_23-7 {
  list-style-type: none;
}
ol.lst-kix_list_23-2 {
  list-style-type: none;
}
.lst-kix_list_16-7 > li {
  counter-increment: lst-ctn-kix_list_16-7;
}
ol.lst-kix_list_23-1 {
  list-style-type: none;
}
ol.lst-kix_list_23-4 {
  list-style-type: none;
}
ol.lst-kix_list_23-3 {
  list-style-type: none;
}
.lst-kix_list_27-7 > li {
  counter-increment: lst-ctn-kix_list_27-7;
}
ol.lst-kix_list_23-0 {
  list-style-type: none;
}
.lst-kix_list_34-1 > li {
  counter-increment: lst-ctn-kix_list_34-1;
}
.lst-kix_list_38-7 > li {
  counter-increment: lst-ctn-kix_list_38-7;
}
.lst-kix_list_16-5 > li {
  counter-increment: lst-ctn-kix_list_16-5;
}
.lst-kix_list_3-7 > li {
  counter-increment: lst-ctn-kix_list_3-7;
}
.lst-kix_list_22-5 > li:before {
  content: "" counter(lst-ctn-kix_list_22-5, lower-roman) ". ";
}
ol.lst-kix_list_36-8 {
  list-style-type: none;
}
ol.lst-kix_list_36-7 {
  list-style-type: none;
}
.lst-kix_list_43-2 > li:before {
  content: "" counter(lst-ctn-kix_list_43-2, lower-roman) ". ";
}
.lst-kix_list_43-6 > li:before {
  content: "" counter(lst-ctn-kix_list_43-6, decimal) ". ";
}
ol.lst-kix_list_36-4 {
  list-style-type: none;
}
ol.lst-kix_list_36-3 {
  list-style-type: none;
}
.lst-kix_list_21-2 > li {
  counter-increment: lst-ctn-kix_list_21-2;
}
ol.lst-kix_list_36-6 {
  list-style-type: none;
}
ol.lst-kix_list_36-5 {
  list-style-type: none;
}
.lst-kix_list_22-1 > li:before {
  content: "" counter(lst-ctn-kix_list_22-1, lower-latin) ". ";
}
ol.lst-kix_list_36-0 {
  list-style-type: none;
}
ol.lst-kix_list_36-2 {
  list-style-type: none;
}
ol.lst-kix_list_36-1 {
  list-style-type: none;
}
.lst-kix_list_23-3 > li {
  counter-increment: lst-ctn-kix_list_23-3;
}
.lst-kix_list_9-5 > li {
  counter-increment: lst-ctn-kix_list_9-5;
}
.lst-kix_list_5-8 > li {
  counter-increment: lst-ctn-kix_list_5-8;
}
.lst-kix_list_27-0 > li {
  counter-increment: lst-ctn-kix_list_27-0;
}
.lst-kix_list_41-6 > li {
  counter-increment: lst-ctn-kix_list_41-6;
}
li.li-bullet-3:before {
  margin-left: -35.5pt;
  white-space: nowrap;
  display: inline-block;
  min-width: 35.5pt;
}
ol.lst-kix_list_19-4.start {
  counter-reset: lst-ctn-kix_list_19-4 0;
}
.lst-kix_list_38-0 > li {
  counter-increment: lst-ctn-kix_list_38-0;
}
.lst-kix_list_32-0 > li {
  counter-increment: lst-ctn-kix_list_32-0;
}
ol.lst-kix_list_2-2.start {
  counter-reset: lst-ctn-kix_list_2-2 0;
}
ol.lst-kix_list_38-4.start {
  counter-reset: lst-ctn-kix_list_38-4 0;
}
ol.lst-kix_list_25-1.start {
  counter-reset: lst-ctn-kix_list_25-1 0;
}
ol.lst-kix_list_14-7 {
  list-style-type: none;
}
ol.lst-kix_list_14-8 {
  list-style-type: none;
}
ol.lst-kix_list_14-3 {
  list-style-type: none;
}
ol.lst-kix_list_21-8.start {
  counter-reset: lst-ctn-kix_list_21-8 0;
}
ol.lst-kix_list_14-4 {
  list-style-type: none;
}
ol.lst-kix_list_14-5 {
  list-style-type: none;
}
ol.lst-kix_list_14-6 {
  list-style-type: none;
}
.lst-kix_list_34-8 > li {
  counter-increment: lst-ctn-kix_list_34-8;
}
ol.lst-kix_list_14-0 {
  list-style-type: none;
}
.lst-kix_list_27-5 > li {
  counter-increment: lst-ctn-kix_list_27-5;
}
.lst-kix_list_40-6 > li:before {
  content: "" counter(lst-ctn-kix_list_40-6, decimal) ". ";
}
ol.lst-kix_list_44-4.start {
  counter-reset: lst-ctn-kix_list_44-4 0;
}
ol.lst-kix_list_14-1 {
  list-style-type: none;
}
ol.lst-kix_list_14-2 {
  list-style-type: none;
}
.lst-kix_list_41-6 > li:before {
  content: "" counter(lst-ctn-kix_list_41-6, decimal) ". ";
}
.lst-kix_list_20-5 > li:before {
  content: "" counter(lst-ctn-kix_list_20-5, lower-roman) ". ";
}
.lst-kix_list_41-2 > li:before {
  content: "" counter(lst-ctn-kix_list_41-2, lower-roman) ". ";
}
.lst-kix_list_20-1 > li:before {
  content: "" counter(lst-ctn-kix_list_20-1, lower-latin) ". ";
}
.lst-kix_list_12-3 > li {
  counter-increment: lst-ctn-kix_list_12-3;
}
ol.lst-kix_list_8-4.start {
  counter-reset: lst-ctn-kix_list_8-4 0;
}
ol.lst-kix_list_3-5.start {
  counter-reset: lst-ctn-kix_list_3-5 0;
}
.lst-kix_list_21-7 > li {
  counter-increment: lst-ctn-kix_list_21-7;
}
.lst-kix_list_14-4 > li {
  counter-increment: lst-ctn-kix_list_14-4;
}
.lst-kix_list_10-7 > li {
  counter-increment: lst-ctn-kix_list_10-7;
}
.lst-kix_list_25-4 > li {
  counter-increment: lst-ctn-kix_list_25-4;
}
.lst-kix_list_18-1 > li {
  counter-increment: lst-ctn-kix_list_18-1;
}
ol.lst-kix_list_14-5.start {
  counter-reset: lst-ctn-kix_list_14-5 0;
}
.lst-kix_list_47-4 > li {
  counter-increment: lst-ctn-kix_list_47-4;
}
.lst-kix_list_32-7 > li {
  counter-increment: lst-ctn-kix_list_32-7;
}
.lst-kix_list_40-2 > li:before {
  content: "" counter(lst-ctn-kix_list_40-2, lower-roman) ". ";
}
ol.lst-kix_list_37-1.start {
  counter-reset: lst-ctn-kix_list_37-1 0;
}
.lst-kix_list_36-4 > li {
  counter-increment: lst-ctn-kix_list_36-4;
}
.lst-kix_list_29-1 > li {
  counter-increment: lst-ctn-kix_list_29-1;
}
.lst-kix_list_43-7 > li {
  counter-increment: lst-ctn-kix_list_43-7;
}
ol.lst-kix_list_32-7.start {
  counter-reset: lst-ctn-kix_list_32-7 0;
}
.lst-kix_list_43-2 > li {
  counter-increment: lst-ctn-kix_list_43-2;
}
ol.lst-kix_list_41-1.start {
  counter-reset: lst-ctn-kix_list_41-1 0;
}
.lst-kix_list_19-4 > li:before {
  content: "" counter(lst-ctn-kix_list_19-0, decimal) "."
    counter(lst-ctn-kix_list_19-1, decimal) "."
    counter(lst-ctn-kix_list_19-2, decimal) "."
    counter(lst-ctn-kix_list_19-3, decimal) "."
    counter(lst-ctn-kix_list_19-4, decimal) " ";
}
ol.lst-kix_list_37-8.start {
  counter-reset: lst-ctn-kix_list_37-8 0;
}
.lst-kix_list_31-5 > li {
  counter-increment: lst-ctn-kix_list_31-5;
}
ol.lst-kix_list_24-5.start {
  counter-reset: lst-ctn-kix_list_24-5 0;
}
.lst-kix_list_47-6 > li:before {
  content: "" counter(lst-ctn-kix_list_47-6, decimal) ". ";
}
.lst-kix_list_47-2 > li {
  counter-increment: lst-ctn-kix_list_47-2;
}
.lst-kix_list_47-8 > li:before {
  content: "" counter(lst-ctn-kix_list_47-8, lower-roman) ". ";
}
ol.lst-kix_list_27-5.start {
  counter-reset: lst-ctn-kix_list_27-5 0;
}
ol.lst-kix_list_22-4.start {
  counter-reset: lst-ctn-kix_list_22-4 0;
}
.lst-kix_list_42-1 > li {
  counter-increment: lst-ctn-kix_list_42-1;
}
ol.lst-kix_list_11-2.start {
  counter-reset: lst-ctn-kix_list_11-2 0;
}
.lst-kix_list_19-6 > li:before {
  content: "" counter(lst-ctn-kix_list_19-0, decimal) "."
    counter(lst-ctn-kix_list_19-1, decimal) "."
    counter(lst-ctn-kix_list_19-2, decimal) "."
    counter(lst-ctn-kix_list_19-3, decimal) "."
    counter(lst-ctn-kix_list_19-4, decimal) "."
    counter(lst-ctn-kix_list_19-5, decimal) "."
    counter(lst-ctn-kix_list_19-6, decimal) " ";
}
.lst-kix_list_47-0 > li:before {
  content: "(" counter(lst-ctn-kix_list_47-0, lower-latin) ") ";
}
ol.lst-kix_list_8-7.start {
  counter-reset: lst-ctn-kix_list_8-7 0;
}
.lst-kix_list_17-2 > li {
  counter-increment: lst-ctn-kix_list_17-2;
}
ol.lst-kix_list_41-6.start {
  counter-reset: lst-ctn-kix_list_41-6 0;
}
.lst-kix_list_42-5 > li {
  counter-increment: lst-ctn-kix_list_42-5;
}
.lst-kix_list_32-2 > li {
  counter-increment: lst-ctn-kix_list_32-2;
}
.lst-kix_list_36-2 > li {
  counter-increment: lst-ctn-kix_list_36-2;
}
ol.lst-kix_list_31-8 {
  list-style-type: none;
}
.lst-kix_list_20-5 > li {
  counter-increment: lst-ctn-kix_list_20-5;
}
ol.lst-kix_list_31-5 {
  list-style-type: none;
}
ol.lst-kix_list_31-4 {
  list-style-type: none;
}
ol.lst-kix_list_31-7 {
  list-style-type: none;
}
ol.lst-kix_list_31-6 {
  list-style-type: none;
}
ol.lst-kix_list_37-3.start {
  counter-reset: lst-ctn-kix_list_37-3 0;
}
ol.lst-kix_list_31-1 {
  list-style-type: none;
}
ol.lst-kix_list_31-0 {
  list-style-type: none;
}
ol.lst-kix_list_31-3 {
  list-style-type: none;
}
.lst-kix_list_37-1 > li:before {
  content: "" counter(lst-ctn-kix_list_37-1, lower-latin) ". ";
}
ol.lst-kix_list_31-2 {
  list-style-type: none;
}
.lst-kix_list_46-2 > li:before {
  content: "" counter(lst-ctn-kix_list_46-2, lower-roman) ". ";
}
ol.lst-kix_list_44-1.start {
  counter-reset: lst-ctn-kix_list_44-1 0;
}
ol.lst-kix_list_24-0.start {
  counter-reset: lst-ctn-kix_list_24-0 0;
}
.lst-kix_list_18-3 > li {
  counter-increment: lst-ctn-kix_list_18-3;
}
.lst-kix_list_37-3 > li:before {
  content: "" counter(lst-ctn-kix_list_37-3, decimal) ". ";
}
ol.lst-kix_list_35-7.start {
  counter-reset: lst-ctn-kix_list_35-7 0;
}
.lst-kix_list_37-3 > li {
  counter-increment: lst-ctn-kix_list_37-3;
}
.lst-kix_list_18-0 > li:before {
  content: "3." counter(lst-ctn-kix_list_18-0, decimal) " ";
}
.lst-kix_list_38-7 > li:before {
  content: "" counter(lst-ctn-kix_list_38-7, lower-latin) ". ";
}
.lst-kix_list_3-0 > li {
  counter-increment: lst-ctn-kix_list_3-0;
}
.lst-kix_list_18-2 > li:before {
  content: "" counter(lst-ctn-kix_list_18-2, lower-roman) ". ";
}
ol.lst-kix_list_27-0.start {
  counter-reset: lst-ctn-kix_list_27-0 0;
}
ol.lst-kix_list_11-7.start {
  counter-reset: lst-ctn-kix_list_11-7 0;
}
ol.lst-kix_list_14-2.start {
  counter-reset: lst-ctn-kix_list_14-2 0;
}
.lst-kix_list_41-4 > li {
  counter-increment: lst-ctn-kix_list_41-4;
}
.lst-kix_list_30-4 > li {
  counter-increment: lst-ctn-kix_list_30-4;
}
.lst-kix_list_38-5 > li:before {
  content: "" counter(lst-ctn-kix_list_38-5, lower-roman) ". ";
}
.lst-kix_list_25-2 > li {
  counter-increment: lst-ctn-kix_list_25-2;
}
.lst-kix_list_27-1 > li:before {
  content: "" counter(lst-ctn-kix_list_27-1, lower-latin) ". ";
}
.lst-kix_list_27-3 > li:before {
  content: "" counter(lst-ctn-kix_list_27-3, decimal) ". ";
}
.lst-kix_list_18-8 > li:before {
  content: "" counter(lst-ctn-kix_list_18-8, lower-roman) ". ";
}
ol.lst-kix_list_27-3.start {
  counter-reset: lst-ctn-kix_list_27-3 0;
}
.lst-kix_list_17-6 > li {
  counter-increment: lst-ctn-kix_list_17-6;
}
ol.lst-kix_list_4-3.start {
  counter-reset: lst-ctn-kix_list_4-3 0;
}
.lst-kix_list_10-7 > li:before {
  content: "" counter(lst-ctn-kix_list_10-7, lower-latin) ". ";
}
.lst-kix_list_20-1 > li {
  counter-increment: lst-ctn-kix_list_20-1;
}
.lst-kix_list_10-5 > li:before {
  content: "" counter(lst-ctn-kix_list_10-5, lower-roman) ". ";
}
ol.lst-kix_list_18-6.start {
  counter-reset: lst-ctn-kix_list_18-6 0;
}
.lst-kix_list_29-3 > li {
  counter-increment: lst-ctn-kix_list_29-3;
}
.lst-kix_list_9-2 > li:before {
  content: "" counter(lst-ctn-kix_list_9-2, lower-roman) ". ";
}
.lst-kix_list_46-4 > li:before {
  content: "" counter(lst-ctn-kix_list_46-4, lower-latin) ". ";
}
ol.lst-kix_list_37-6.start {
  counter-reset: lst-ctn-kix_list_37-6 0;
}
ol.lst-kix_list_14-0.start {
  counter-reset: lst-ctn-kix_list_14-0 0;
}
.lst-kix_list_12-5 > li {
  counter-increment: lst-ctn-kix_list_12-5;
}
.lst-kix_list_31-1 > li {
  counter-increment: lst-ctn-kix_list_31-1;
}
.lst-kix_list_9-0 > li:before {
  content: "" counter(lst-ctn-kix_list_9-0, lower-latin) ") ";
}
ol.lst-kix_list_24-3.start {
  counter-reset: lst-ctn-kix_list_24-3 0;
}
.lst-kix_list_23-5 > li {
  counter-increment: lst-ctn-kix_list_23-5;
}
.lst-kix_list_11-3 > li:before {
  content: "" counter(lst-ctn-kix_list_11-3, decimal) ". ";
}
.lst-kix_list_6-3 > li {
  counter-increment: lst-ctn-kix_list_6-3;
}
ol.lst-kix_list_1-3.start {
  counter-reset: lst-ctn-kix_list_1-3 0;
}
.lst-kix_list_29-1 > li:before {
  content: "" counter(lst-ctn-kix_list_29-1, lower-latin) ". ";
}
.lst-kix_list_29-3 > li:before {
  content: "" counter(lst-ctn-kix_list_29-3, decimal) ". ";
}
.lst-kix_list_9-8 > li:before {
  content: "" counter(lst-ctn-kix_list_9-8, lower-roman) ". ";
}
.lst-kix_list_28-6 > li {
  counter-increment: lst-ctn-kix_list_28-6;
}
.lst-kix_list_28-7 > li:before {
  content: "" counter(lst-ctn-kix_list_28-7, lower-latin) ". ";
}
ol.lst-kix_list_44-8 {
  list-style-type: none;
}
.lst-kix_list_1-7 > li:before {
  content: "" counter(lst-ctn-kix_list_1-0, decimal) "."
    counter(lst-ctn-kix_list_1-1, decimal) "."
    counter(lst-ctn-kix_list_1-2, decimal) "."
    counter(lst-ctn-kix_list_1-3, decimal) "."
    counter(lst-ctn-kix_list_1-4, decimal) "."
    counter(lst-ctn-kix_list_1-5, decimal) "."
    counter(lst-ctn-kix_list_1-6, decimal) "."
    counter(lst-ctn-kix_list_1-7, decimal) " ";
}
ol.lst-kix_list_44-6 {
  list-style-type: none;
}
ol.lst-kix_list_44-7 {
  list-style-type: none;
}
ol.lst-kix_list_44-4 {
  list-style-type: none;
}
.lst-kix_list_1-5 > li:before {
  content: "" counter(lst-ctn-kix_list_1-0, decimal) "."
    counter(lst-ctn-kix_list_1-1, decimal) "."
    counter(lst-ctn-kix_list_1-2, decimal) "."
    counter(lst-ctn-kix_list_1-3, decimal) "."
    counter(lst-ctn-kix_list_1-4, decimal) "."
    counter(lst-ctn-kix_list_1-5, decimal) " ";
}
ol.lst-kix_list_44-5 {
  list-style-type: none;
}
.lst-kix_list_28-5 > li:before {
  content: "" counter(lst-ctn-kix_list_28-5, lower-roman) ". ";
}
ol.lst-kix_list_44-2 {
  list-style-type: none;
}
ol.lst-kix_list_44-3 {
  list-style-type: none;
}
.lst-kix_list_5-6 > li {
  counter-increment: lst-ctn-kix_list_5-6;
}
ol.lst-kix_list_44-0 {
  list-style-type: none;
}
ol.lst-kix_list_44-1 {
  list-style-type: none;
}
ol.lst-kix_list_22-6.start {
  counter-reset: lst-ctn-kix_list_22-6 0;
}
.lst-kix_list_2-1 > li:before {
  content: "" counter(lst-ctn-kix_list_2-1, lower-latin) ". ";
}
.lst-kix_list_2-3 > li:before {
  content: "" counter(lst-ctn-kix_list_2-3, decimal) ". ";
}
.lst-kix_list_11-8 > li {
  counter-increment: lst-ctn-kix_list_11-8;
}
.lst-kix_list_35-3 > li:before {
  content: "" counter(lst-ctn-kix_list_35-3, decimal) ". ";
}
ol.lst-kix_list_24-8.start {
  counter-reset: lst-ctn-kix_list_24-8 0;
}
.lst-kix_list_30-4 > li:before {
  content: "" counter(lst-ctn-kix_list_30-4, lower-latin) ". ";
}
.lst-kix_list_20-8 > li {
  counter-increment: lst-ctn-kix_list_20-8;
}
.lst-kix_list_9-1 > li {
  counter-increment: lst-ctn-kix_list_9-1;
}
.lst-kix_list_26-7 > li:before {
  content: "" counter(lst-ctn-kix_list_26-7, lower-latin) ". ";
}
ol.lst-kix_list_1-8.start {
  counter-reset: lst-ctn-kix_list_1-8 0;
}
.lst-kix_list_6-0 > li {
  counter-increment: lst-ctn-kix_list_6-0;
}
.lst-kix_list_3-5 > li:before {
  content: "" counter(lst-ctn-kix_list_3-5, lower-roman) ". ";
}
.lst-kix_list_40-2 > li {
  counter-increment: lst-ctn-kix_list_40-2;
}
ol.lst-kix_list_30-8.start {
  counter-reset: lst-ctn-kix_list_30-8 0;
}
ol.lst-kix_list_11-5.start {
  counter-reset: lst-ctn-kix_list_11-5 0;
}
.lst-kix_list_8-6 > li:before {
  content: "" counter(lst-ctn-kix_list_8-6, decimal) ". ";
}
.lst-kix_list_11-1 > li {
  counter-increment: lst-ctn-kix_list_11-1;
}
ol.lst-kix_list_16-6.start {
  counter-reset: lst-ctn-kix_list_16-6 0;
}
.lst-kix_list_44-4 > li {
  counter-increment: lst-ctn-kix_list_44-4;
}
ol.lst-kix_list_41-4.start {
  counter-reset: lst-ctn-kix_list_41-4 0;
}
ol.lst-kix_list_22-1.start {
  counter-reset: lst-ctn-kix_list_22-1 0;
}
ol.lst-kix_list_46-5.start {
  counter-reset: lst-ctn-kix_list_46-5 0;
}
.lst-kix_list_21-3 > li:before {
  content: "" counter(lst-ctn-kix_list_21-3, decimal) ". ";
}
ol.lst-kix_list_30-2.start {
  counter-reset: lst-ctn-kix_list_30-2 0;
}
.lst-kix_list_4-4 > li {
  counter-increment: lst-ctn-kix_list_4-4;
}
ol.lst-kix_list_29-4.start {
  counter-reset: lst-ctn-kix_list_29-4 0;
}
.lst-kix_list_45-8 > li:before {
  content: "\0025aa  ";
}
.lst-kix_list_31-8 > li {
  counter-increment: lst-ctn-kix_list_31-8;
}
.lst-kix_list_25-3 > li:before {
  content: "" counter(lst-ctn-kix_list_25-3, decimal) ". ";
}
ol.lst-kix_list_4-1.start {
  counter-reset: lst-ctn-kix_list_4-1 0;
}
.lst-kix_list_39-2 > li {
  counter-increment: lst-ctn-kix_list_39-2;
}
.lst-kix_list_16-2 > li:before {
  content: "" counter(lst-ctn-kix_list_16-2, lower-roman) ". ";
}
.lst-kix_list_26-6 > li {
  counter-increment: lst-ctn-kix_list_26-6;
}
.lst-kix_list_15-3 > li {
  counter-increment: lst-ctn-kix_list_15-3;
}
.lst-kix_list_44-4 > li:before {
  content: "" counter(lst-ctn-kix_list_44-4, lower-latin) ". ";
}
ol.lst-kix_list_30-3.start {
  counter-reset: lst-ctn-kix_list_30-3 0;
}
.lst-kix_list_37-6 > li {
  counter-increment: lst-ctn-kix_list_37-6;
}
ol.lst-kix_list_11-0.start {
  counter-reset: lst-ctn-kix_list_11-0 0;
}
ol.lst-kix_list_18-8.start {
  counter-reset: lst-ctn-kix_list_18-8 0;
}
.lst-kix_list_3-3 > li {
  counter-increment: lst-ctn-kix_list_3-3;
}
.lst-kix_list_39-3 > li:before {
  content: "" counter(lst-ctn-kix_list_39-3, decimal) ". ";
}
ol.lst-kix_list_6-3.start {
  counter-reset: lst-ctn-kix_list_6-3 0;
}
.lst-kix_list_45-0 > li:before {
  content: "" counter(lst-ctn-kix_list_45-0, lower-latin) ") ";
}
.lst-kix_list_17-6 > li:before {
  content: "" counter(lst-ctn-kix_list_17-6, decimal) ". ";
}
ol.lst-kix_list_16-2.start {
  counter-reset: lst-ctn-kix_list_16-2 0;
}
.lst-kix_list_42-8 > li {
  counter-increment: lst-ctn-kix_list_42-8;
}
.lst-kix_list_43-0 > li:before {
  content: "3." counter(lst-ctn-kix_list_43-0, decimal) " ";
}
.lst-kix_list_43-8 > li:before {
  content: "" counter(lst-ctn-kix_list_43-8, lower-roman) ". ";
}
.lst-kix_list_19-5 > li {
  counter-increment: lst-ctn-kix_list_19-5;
}
.lst-kix_list_28-2 > li {
  counter-increment: lst-ctn-kix_list_28-2;
}
.lst-kix_list_22-7 > li:before {
  content: "" counter(lst-ctn-kix_list_22-7, lower-latin) ". ";
}
ol.lst-kix_list_35-2.start {
  counter-reset: lst-ctn-kix_list_35-2 0;
}
.lst-kix_list_34-7 > li:before {
  content: "" counter(lst-ctn-kix_list_34-7, lower-latin) ". ";
}
.lst-kix_list_6-7 > li {
  counter-increment: lst-ctn-kix_list_6-7;
}
.lst-kix_list_26-3 > li {
  counter-increment: lst-ctn-kix_list_26-3;
}
.lst-kix_list_15-6 > li:before {
  content: "" counter(lst-ctn-kix_list_15-6, decimal) ". ";
}
.lst-kix_list_11-4 > li {
  counter-increment: lst-ctn-kix_list_11-4;
}
.lst-kix_list_22-4 > li {
  counter-increment: lst-ctn-kix_list_22-4;
}
.lst-kix_list_36-7 > li:before {
  content: "" counter(lst-ctn-kix_list_36-7, lower-latin) ". ";
}
ol.lst-kix_list_6-8.start {
  counter-reset: lst-ctn-kix_list_6-8 0;
}
.lst-kix_list_13-7 > li {
  counter-increment: lst-ctn-kix_list_13-7;
}
.lst-kix_list_20-7 > li:before {
  content: "" counter(lst-ctn-kix_list_20-7, lower-latin) ". ";
}
ol.lst-kix_list_6-5.start {
  counter-reset: lst-ctn-kix_list_6-5 0;
}
.lst-kix_list_41-8 > li:before {
  content: "" counter(lst-ctn-kix_list_41-8, lower-roman) ". ";
}
.lst-kix_list_46-7 > li {
  counter-increment: lst-ctn-kix_list_46-7;
}
ol.lst-kix_list_29-5.start {
  counter-reset: lst-ctn-kix_list_29-5 0;
}
.lst-kix_list_41-0 > li:before {
  content: "" counter(lst-ctn-kix_list_41-0, decimal) ". ";
}
ol.lst-kix_list_6-7.start {
  counter-reset: lst-ctn-kix_list_6-7 0;
}
.lst-kix_list_24-0 > li {
  counter-increment: lst-ctn-kix_list_24-0;
}
.lst-kix_list_33-3 > li:before {
  content: "\0025aa  ";
}
ol.lst-kix_list_46-1.start {
  counter-reset: lst-ctn-kix_list_46-1 0;
}
.lst-kix_list_46-0 > li {
  counter-increment: lst-ctn-kix_list_46-0;
}
ol.lst-kix_list_16-1.start {
  counter-reset: lst-ctn-kix_list_16-1 0;
}
ol.lst-kix_list_35-4.start {
  counter-reset: lst-ctn-kix_list_35-4 0;
}
ol.lst-kix_list_40-8 {
  list-style-type: none;
}
li.li-bullet-0:before {
  margin-left: -36pt;
  white-space: nowrap;
  display: inline-block;
  min-width: 36pt;
}
ol.lst-kix_list_29-7.start {
  counter-reset: lst-ctn-kix_list_29-7 0;
}
ol.lst-kix_list_40-6 {
  list-style-type: none;
}
.lst-kix_list_35-0 > li {
  counter-increment: lst-ctn-kix_list_35-0;
}
ol.lst-kix_list_40-7 {
  list-style-type: none;
}
ol.lst-kix_list_40-4 {
  list-style-type: none;
}
ol.lst-kix_list_40-5 {
  list-style-type: none;
}
ol.lst-kix_list_40-2 {
  list-style-type: none;
}
ol.lst-kix_list_40-3 {
  list-style-type: none;
}
ol.lst-kix_list_40-0 {
  list-style-type: none;
}
ol.lst-kix_list_40-1 {
  list-style-type: none;
}
.lst-kix_list_40-4 > li:before {
  content: "" counter(lst-ctn-kix_list_40-4, lower-latin) ". ";
}
ol.lst-kix_list_30-7.start {
  counter-reset: lst-ctn-kix_list_30-7 0;
}
ol.lst-kix_list_46-0.start {
  counter-reset: lst-ctn-kix_list_46-0 0;
}
.lst-kix_list_41-2 > li {
  counter-increment: lst-ctn-kix_list_41-2;
}
.lst-kix_list_14-3 > li:before {
  content: "" counter(lst-ctn-kix_list_14-3, decimal) ". ";
}
.lst-kix_list_14-0 > li:before {
  content: "" counter(lst-ctn-kix_list_14-0, lower-latin) ") ";
}
ol.lst-kix_list_18-5.start {
  counter-reset: lst-ctn-kix_list_18-5 0;
}
.lst-kix_list_6-1 > li {
  counter-increment: lst-ctn-kix_list_6-1;
}
.lst-kix_list_14-6 > li:before {
  content: "" counter(lst-ctn-kix_list_14-6, decimal) ". ";
}
ol.lst-kix_list_7-4.start {
  counter-reset: lst-ctn-kix_list_7-4 0;
}
.lst-kix_list_17-0 > li {
  counter-increment: lst-ctn-kix_list_17-0;
}
.lst-kix_list_9-0 > li {
  counter-increment: lst-ctn-kix_list_9-0;
}
ol.lst-kix_list_25-3.start {
  counter-reset: lst-ctn-kix_list_25-3 0;
}
ol.lst-kix_list_11-8.start {
  counter-reset: lst-ctn-kix_list_11-8 0;
}
.lst-kix_list_14-2 > li:before {
  content: "" counter(lst-ctn-kix_list_14-2, lower-roman) ". ";
}
ol.lst-kix_list_26-7 {
  list-style-type: none;
}
ol.lst-kix_list_26-6 {
  list-style-type: none;
}
ol.lst-kix_list_26-8 {
  list-style-type: none;
}
ol.lst-kix_list_26-3 {
  list-style-type: none;
}
ol.lst-kix_list_26-2 {
  list-style-type: none;
}
ol.lst-kix_list_12-0.start {
  counter-reset: lst-ctn-kix_list_12-0 0;
}
ol.lst-kix_list_26-5 {
  list-style-type: none;
}
ol.lst-kix_list_26-4 {
  list-style-type: none;
}
ol.lst-kix_list_41-7.start {
  counter-reset: lst-ctn-kix_list_41-7 0;
}
ol.lst-kix_list_26-1 {
  list-style-type: none;
}
ol.lst-kix_list_26-0 {
  list-style-type: none;
}
.lst-kix_list_32-2 > li:before {
  content: "" counter(lst-ctn-kix_list_32-2, lower-roman) ". ";
}
.lst-kix_list_32-1 > li:before {
  content: "" counter(lst-ctn-kix_list_32-1, lower-latin) ". ";
}
ol.lst-kix_list_21-6.start {
  counter-reset: lst-ctn-kix_list_21-6 0;
}
ol.lst-kix_list_3-7.start {
  counter-reset: lst-ctn-kix_list_3-7 0;
}
.lst-kix_list_28-7 > li {
  counter-increment: lst-ctn-kix_list_28-7;
}
.lst-kix_list_31-7 > li {
  counter-increment: lst-ctn-kix_list_31-7;
}
ol.lst-kix_list_39-8 {
  list-style-type: none;
}
.lst-kix_list_5-0 > li:before {
  content: "" counter(lst-ctn-kix_list_5-0, lower-latin) ") ";
}
ol.lst-kix_list_6-0 {
  list-style-type: none;
}
ol.lst-kix_list_6-1 {
  list-style-type: none;
}
.lst-kix_list_14-8 > li {
  counter-increment: lst-ctn-kix_list_14-8;
}
ol.lst-kix_list_39-5 {
  list-style-type: none;
}
ol.lst-kix_list_39-4 {
  list-style-type: none;
}
ol.lst-kix_list_39-7 {
  list-style-type: none;
}
ol.lst-kix_list_39-6 {
  list-style-type: none;
}
.lst-kix_list_5-3 > li:before {
  content: "" counter(lst-ctn-kix_list_5-3, decimal) ". ";
}
.lst-kix_list_36-8 > li {
  counter-increment: lst-ctn-kix_list_36-8;
}
ol.lst-kix_list_39-1 {
  list-style-type: none;
}
ol.lst-kix_list_39-0 {
  list-style-type: none;
}
.lst-kix_list_5-2 > li:before {
  content: "" counter(lst-ctn-kix_list_5-2, lower-roman) ". ";
}
ol.lst-kix_list_39-3 {
  list-style-type: none;
}
.lst-kix_list_8-3 > li {
  counter-increment: lst-ctn-kix_list_8-3;
}
ol.lst-kix_list_39-2 {
  list-style-type: none;
}
ol.lst-kix_list_18-0.start {
  counter-reset: lst-ctn-kix_list_18-0 3;
}
.lst-kix_list_5-6 > li:before {
  content: "" counter(lst-ctn-kix_list_5-6, decimal) ". ";
}
ol.lst-kix_list_6-6 {
  list-style-type: none;
}
ol.lst-kix_list_6-7 {
  list-style-type: none;
}
ol.lst-kix_list_6-8 {
  list-style-type: none;
}
ol.lst-kix_list_6-2 {
  list-style-type: none;
}
ol.lst-kix_list_6-3 {
  list-style-type: none;
}
ol.lst-kix_list_6-4 {
  list-style-type: none;
}
ol.lst-kix_list_6-5 {
  list-style-type: none;
}
ol.lst-kix_list_12-5.start {
  counter-reset: lst-ctn-kix_list_12-5 0;
}
.lst-kix_list_6-2 > li:before {
  content: "" counter(lst-ctn-kix_list_6-2, lower-roman) ". ";
}
ol.lst-kix_list_35-1.start {
  counter-reset: lst-ctn-kix_list_35-1 0;
}
ol.lst-kix_list_3-2.start {
  counter-reset: lst-ctn-kix_list_3-2 0;
}
.lst-kix_list_6-8 > li:before {
  content: "" counter(lst-ctn-kix_list_6-8, lower-roman) ". ";
}
.lst-kix_list_6-5 > li:before {
  content: "" counter(lst-ctn-kix_list_6-5, lower-roman) ". ";
}
ol.lst-kix_list_42-4.start {
  counter-reset: lst-ctn-kix_list_42-4 0;
}
ol.lst-kix_list_17-8 {
  list-style-type: none;
}
.lst-kix_list_7-4 > li:before {
  content: "" counter(lst-ctn-kix_list_7-4, lower-latin) ". ";
}
ol.lst-kix_list_17-4 {
  list-style-type: none;
}
ol.lst-kix_list_17-5 {
  list-style-type: none;
}
ol.lst-kix_list_17-6 {
  list-style-type: none;
}
ol.lst-kix_list_17-7 {
  list-style-type: none;
}
ol.lst-kix_list_17-0 {
  list-style-type: none;
}
.lst-kix_list_22-2 > li {
  counter-increment: lst-ctn-kix_list_22-2;
}
ol.lst-kix_list_17-1 {
  list-style-type: none;
}
ol.lst-kix_list_17-2 {
  list-style-type: none;
}
.lst-kix_list_44-8 > li {
  counter-increment: lst-ctn-kix_list_44-8;
}
ol.lst-kix_list_17-3 {
  list-style-type: none;
}
.lst-kix_list_24-3 > li {
  counter-increment: lst-ctn-kix_list_24-3;
}
ol.lst-kix_list_46-6.start {
  counter-reset: lst-ctn-kix_list_46-6 0;
}
.lst-kix_list_13-7 > li:before {
  content: "" counter(lst-ctn-kix_list_13-7, lower-latin) ". ";
}
ol.lst-kix_list_34-4.start {
  counter-reset: lst-ctn-kix_list_34-4 0;
}
.lst-kix_list_7-8 > li:before {
  content: "" counter(lst-ctn-kix_list_7-8, lower-roman) ". ";
}
.lst-kix_list_15-6 > li {
  counter-increment: lst-ctn-kix_list_15-6;
}
.lst-kix_list_4-7 > li {
  counter-increment: lst-ctn-kix_list_4-7;
}
ol.lst-kix_list_2-5.start {
  counter-reset: lst-ctn-kix_list_2-5 0;
}
.lst-kix_list_15-5 > li:before {
  content: "" counter(lst-ctn-kix_list_15-5, lower-roman) ". ";
}
.lst-kix_list_31-6 > li:before {
  content: "" counter(lst-ctn-kix_list_31-6, decimal) ". ";
}
.lst-kix_list_31-8 > li:before {
  content: "" counter(lst-ctn-kix_list_31-8, lower-roman) ". ";
}
ol.lst-kix_list_26-0.start {
  counter-reset: lst-ctn-kix_list_26-0 0;
}
.lst-kix_list_27-3 > li {
  counter-increment: lst-ctn-kix_list_27-3;
}
.lst-kix_list_4-1 > li:before {
  content: "" counter(lst-ctn-kix_list_4-1, lower-latin) ". ";
}
.lst-kix_list_31-2 > li:before {
  content: "" counter(lst-ctn-kix_list_31-2, lower-roman) ". ";
}
.lst-kix_list_36-0 > li {
  counter-increment: lst-ctn-kix_list_36-0;
}
.lst-kix_list_4-3 > li:before {
  content: "" counter(lst-ctn-kix_list_4-3, decimal) ". ";
}
.lst-kix_list_1-8 > li {
  counter-increment: lst-ctn-kix_list_1-8;
}
ol.lst-kix_list_38-1.start {
  counter-reset: lst-ctn-kix_list_38-1 0;
}
.lst-kix_list_10-5 > li {
  counter-increment: lst-ctn-kix_list_10-5;
}
.lst-kix_list_24-4 > li {
  counter-increment: lst-ctn-kix_list_24-4;
}
.lst-kix_list_16-2 > li {
  counter-increment: lst-ctn-kix_list_16-2;
}
ol.lst-kix_list_39-3.start {
  counter-reset: lst-ctn-kix_list_39-3 0;
}
.lst-kix_list_20-0 > li {
  counter-increment: lst-ctn-kix_list_20-0;
}
ol.lst-kix_list_16-7.start {
  counter-reset: lst-ctn-kix_list_16-7 0;
}
.lst-kix_list_32-4 > li:before {
  content: "" counter(lst-ctn-kix_list_32-4, lower-latin) ". ";
}
.lst-kix_list_26-5 > li {
  counter-increment: lst-ctn-kix_list_26-5;
}
.lst-kix_list_19-2 > li {
  counter-increment: lst-ctn-kix_list_19-2;
}
.lst-kix_list_33-4 > li:before {
  content: "\0025aa  ";
}
.lst-kix_list_30-3 > li {
  counter-increment: lst-ctn-kix_list_30-3;
}
.lst-kix_list_38-2 > li {
  counter-increment: lst-ctn-kix_list_38-2;
}
ol.lst-kix_list_43-6.start {
  counter-reset: lst-ctn-kix_list_43-6 0;
}
.lst-kix_list_12-1 > li:before {
  content: "" counter(lst-ctn-kix_list_12-1, lower-latin) ". ";
}
.lst-kix_list_33-0 > li:before {
  content: "\0025aa  ";
}
.lst-kix_list_32-8 > li:before {
  content: "" counter(lst-ctn-kix_list_32-8, lower-roman) ". ";
}
.lst-kix_list_23-6 > li {
  counter-increment: lst-ctn-kix_list_23-6;
}
ol.lst-kix_list_13-6.start {
  counter-reset: lst-ctn-kix_list_13-6 0;
}
ol.lst-kix_list_25-8.start {
  counter-reset: lst-ctn-kix_list_25-8 0;
}
.lst-kix_list_39-0 > li {
  counter-increment: lst-ctn-kix_list_39-0;
}
.lst-kix_list_34-0 > li:before {
  content: "9." counter(lst-ctn-kix_list_34-0, decimal) " ";
}
.lst-kix_list_46-3 > li {
  counter-increment: lst-ctn-kix_list_46-3;
}
.lst-kix_list_21-4 > li {
  counter-increment: lst-ctn-kix_list_21-4;
}
.lst-kix_list_39-6 > li {
  counter-increment: lst-ctn-kix_list_39-6;
}
.lst-kix_list_13-3 > li:before {
  content: "" counter(lst-ctn-kix_list_13-3, decimal) ". ";
}
.lst-kix_list_42-6 > li {
  counter-increment: lst-ctn-kix_list_42-6;
}
ol.lst-kix_list_43-5.start {
  counter-reset: lst-ctn-kix_list_43-5 0;
}
.lst-kix_list_34-6 > li:before {
  content: "" counter(lst-ctn-kix_list_34-6, decimal) ". ";
}
li.li-bullet-4:before {
  margin-left: -40.5pt;
  white-space: nowrap;
  display: inline-block;
  min-width: 40.5pt;
}
.lst-kix_list_12-5 > li:before {
  content: "" counter(lst-ctn-kix_list_12-5, lower-roman) ". ";
}
ol.lst-kix_list_13-7.start {
  counter-reset: lst-ctn-kix_list_13-7 0;
}
.lst-kix_list_43-4 > li {
  counter-increment: lst-ctn-kix_list_43-4;
}
.lst-kix_list_18-4 > li {
  counter-increment: lst-ctn-kix_list_18-4;
}
.lst-kix_list_42-0 > li {
  counter-increment: lst-ctn-kix_list_42-0;
}
.lst-kix_list_12-7 > li:before {
  content: "" counter(lst-ctn-kix_list_12-7, lower-latin) ". ";
}
.lst-kix_list_47-1 > li {
  counter-increment: lst-ctn-kix_list_47-1;
}
ol.lst-kix_list_21-1.start {
  counter-reset: lst-ctn-kix_list_21-1 0;
}
.lst-kix_list_47-7 > li {
  counter-increment: lst-ctn-kix_list_47-7;
}
.lst-kix_list_25-1 > li {
  counter-increment: lst-ctn-kix_list_25-1;
}
.lst-kix_list_13-1 > li:before {
  content: "" counter(lst-ctn-kix_list_13-1, lower-latin) ". ";
}
.lst-kix_list_32-5 > li {
  counter-increment: lst-ctn-kix_list_32-5;
}
.lst-kix_list_22-8 > li {
  counter-increment: lst-ctn-kix_list_22-8;
}
.lst-kix_list_35-5 > li:before {
  content: "" counter(lst-ctn-kix_list_35-5, lower-roman) ". ";
}
ol.lst-kix_list_2-6.start {
  counter-reset: lst-ctn-kix_list_2-6 0;
}
ol.lst-kix_list_20-5.start {
  counter-reset: lst-ctn-kix_list_20-5 0;
}
ol.lst-kix_list_13-1.start {
  counter-reset: lst-ctn-kix_list_13-1 0;
}
.lst-kix_list_3-4 > li:before {
  content: "" counter(lst-ctn-kix_list_3-4, lower-latin) ". ";
}
.lst-kix_list_8-0 > li:before {
  content: "" counter(lst-ctn-kix_list_8-0, lower-latin) ") ";
}
.lst-kix_list_30-6 > li:before {
  content: "" counter(lst-ctn-kix_list_30-6, decimal) ". ";
}
.lst-kix_list_8-7 > li:before {
  content: "" counter(lst-ctn-kix_list_8-7, lower-latin) ". ";
}
.lst-kix_list_3-8 > li:before {
  content: "" counter(lst-ctn-kix_list_3-8, lower-roman) ". ";
}
.lst-kix_list_8-3 > li:before {
  content: "" counter(lst-ctn-kix_list_8-3, decimal) ". ";
}
ol.lst-kix_list_22-7 {
  list-style-type: none;
}
ol.lst-kix_list_22-6 {
  list-style-type: none;
}
ol.lst-kix_list_43-0.start {
  counter-reset: lst-ctn-kix_list_43-0 6;
}
ol.lst-kix_list_22-8 {
  list-style-type: none;
}
.lst-kix_list_3-7 > li:before {
  content: "" counter(lst-ctn-kix_list_3-7, lower-latin) ". ";
}
ol.lst-kix_list_22-3 {
  list-style-type: none;
}
ol.lst-kix_list_22-2 {
  list-style-type: none;
}
.lst-kix_list_8-4 > li:before {
  content: "" counter(lst-ctn-kix_list_8-4, lower-latin) ". ";
}
ol.lst-kix_list_22-5 {
  list-style-type: none;
}
ol.lst-kix_list_22-4 {
  list-style-type: none;
}
.lst-kix_list_19-1 > li {
  counter-increment: lst-ctn-kix_list_19-1;
}
ol.lst-kix_list_22-1 {
  list-style-type: none;
}
ol.lst-kix_list_22-0 {
  list-style-type: none;
}
ol.lst-kix_list_8-5.start {
  counter-reset: lst-ctn-kix_list_8-5 0;
}
ol.lst-kix_list_26-4.start {
  counter-reset: lst-ctn-kix_list_26-4 0;
}
.lst-kix_list_46-4 > li {
  counter-increment: lst-ctn-kix_list_46-4;
}
.lst-kix_list_35-8 > li:before {
  content: "" counter(lst-ctn-kix_list_35-8, lower-roman) ". ";
}
.lst-kix_list_37-4 > li {
  counter-increment: lst-ctn-kix_list_37-4;
}
ol.lst-kix_list_2-2 {
  list-style-type: none;
}
.lst-kix_list_16-8 > li:before {
  content: "" counter(lst-ctn-kix_list_16-8, lower-roman) ". ";
}
ol.lst-kix_list_2-3 {
  list-style-type: none;
}
ol.lst-kix_list_2-4 {
  list-style-type: none;
}
.lst-kix_list_16-7 > li:before {
  content: "" counter(lst-ctn-kix_list_16-7, lower-latin) ". ";
}
ol.lst-kix_list_2-5 {
  list-style-type: none;
}
.lst-kix_list_17-8 > li {
  counter-increment: lst-ctn-kix_list_17-8;
}
ol.lst-kix_list_35-8 {
  list-style-type: none;
}
ol.lst-kix_list_2-0 {
  list-style-type: none;
}
ol.lst-kix_list_2-1 {
  list-style-type: none;
}
ol.lst-kix_list_35-5 {
  list-style-type: none;
}
ol.lst-kix_list_35-4 {
  list-style-type: none;
}
.lst-kix_list_4-7 > li:before {
  content: "" counter(lst-ctn-kix_list_4-7, lower-latin) ". ";
}
ol.lst-kix_list_35-7 {
  list-style-type: none;
}
ol.lst-kix_list_20-0.start {
  counter-reset: lst-ctn-kix_list_20-0 3;
}
.lst-kix_list_17-0 > li:before {
  content: "3." counter(lst-ctn-kix_list_17-0, decimal) " ";
}
ol.lst-kix_list_35-6 {
  list-style-type: none;
}
ol.lst-kix_list_35-1 {
  list-style-type: none;
}
li.li-bullet-7:before {
  margin-left: -29.2pt;
  white-space: nowrap;
  display: inline-block;
  min-width: 29.2pt;
}
ol.lst-kix_list_35-0 {
  list-style-type: none;
}
ol.lst-kix_list_35-3 {
  list-style-type: none;
}
ol.lst-kix_list_35-2 {
  list-style-type: none;
}
.lst-kix_list_39-8 > li {
  counter-increment: lst-ctn-kix_list_39-8;
}
.lst-kix_list_16-4 > li:before {
  content: "" counter(lst-ctn-kix_list_16-4, lower-latin) ". ";
}
ol.lst-kix_list_3-3.start {
  counter-reset: lst-ctn-kix_list_3-3 0;
}
ol.lst-kix_list_2-6 {
  list-style-type: none;
}
ol.lst-kix_list_2-7 {
  list-style-type: none;
}
ol.lst-kix_list_2-8 {
  list-style-type: none;
}
.lst-kix_list_11-3 > li {
  counter-increment: lst-ctn-kix_list_11-3;
}
ol.lst-kix_list_18-1.start {
  counter-reset: lst-ctn-kix_list_18-1 0;
}
.lst-kix_list_30-1 > li {
  counter-increment: lst-ctn-kix_list_30-1;
}
ol.lst-kix_list_39-7.start {
  counter-reset: lst-ctn-kix_list_39-7 0;
}
ol.lst-kix_list_8-6.start {
  counter-reset: lst-ctn-kix_list_8-6 0;
}
.lst-kix_list_17-7 > li:before {
  content: "" counter(lst-ctn-kix_list_17-7, lower-latin) ". ";
}
ol.lst-kix_list_38-2.start {
  counter-reset: lst-ctn-kix_list_38-2 0;
}
.lst-kix_list_17-3 > li:before {
  content: "" counter(lst-ctn-kix_list_17-3, decimal) ". ";
}
.lst-kix_list_17-4 > li:before {
  content: "" counter(lst-ctn-kix_list_17-4, lower-latin) ". ";
}
ol.lst-kix_list_8-0.start {
  counter-reset: lst-ctn-kix_list_8-0 0;
}
ol.lst-kix_list_13-8 {
  list-style-type: none;
}
.lst-kix_list_2-8 > li:before {
  content: "" counter(lst-ctn-kix_list_2-8, lower-roman) ". ";
}
ol.lst-kix_list_13-4 {
  list-style-type: none;
}
ol.lst-kix_list_13-5 {
  list-style-type: none;
}
ol.lst-kix_list_13-6 {
  list-style-type: none;
}
ol.lst-kix_list_13-7 {
  list-style-type: none;
}
ol.lst-kix_list_13-0 {
  list-style-type: none;
}
ol.lst-kix_list_13-1 {
  list-style-type: none;
}
ol.lst-kix_list_13-2 {
  list-style-type: none;
}
ol.lst-kix_list_13-3 {
  list-style-type: none;
}
.lst-kix_list_10-0 > li:before {
  content: "10." counter(lst-ctn-kix_list_10-0, decimal) " ";
}
ol.lst-kix_list_21-7.start {
  counter-reset: lst-ctn-kix_list_21-7 0;
}
ol.lst-kix_list_43-1.start {
  counter-reset: lst-ctn-kix_list_43-1 0;
}
.lst-kix_list_18-3 > li:before {
  content: "" counter(lst-ctn-kix_list_18-3, decimal) ". ";
}
.lst-kix_list_18-6 > li {
  counter-increment: lst-ctn-kix_list_18-6;
}
ol.lst-kix_list_3-8.start {
  counter-reset: lst-ctn-kix_list_3-8 0;
}
ol.lst-kix_list_39-8.start {
  counter-reset: lst-ctn-kix_list_39-8 0;
}
.lst-kix_list_7-7 > li:before {
  content: "" counter(lst-ctn-kix_list_7-7, lower-latin) ". ";
}
.lst-kix_list_36-5 > li:before {
  content: "" counter(lst-ctn-kix_list_36-5, lower-roman) ". ";
}
ol.lst-kix_list_8-1.start {
  counter-reset: lst-ctn-kix_list_8-1 0;
}
.lst-kix_list_31-5 > li:before {
  content: "" counter(lst-ctn-kix_list_31-5, lower-roman) ". ";
}
ol.lst-kix_list_20-4.start {
  counter-reset: lst-ctn-kix_list_20-4 0;
}
.lst-kix_list_4-0 > li:before {
  content: "" counter(lst-ctn-kix_list_4-0, lower-latin) ") ";
}
.lst-kix_list_36-1 > li:before {
  content: "" counter(lst-ctn-kix_list_36-1, lower-latin) ". ";
}
ol.lst-kix_list_25-2.start {
  counter-reset: lst-ctn-kix_list_25-2 0;
}
.lst-kix_list_15-8 > li:before {
  content: "" counter(lst-ctn-kix_list_15-8, lower-roman) ". ";
}
ol.lst-kix_list_38-7.start {
  counter-reset: lst-ctn-kix_list_38-7 0;
}
.lst-kix_list_15-7 > li {
  counter-increment: lst-ctn-kix_list_15-7;
}
.lst-kix_list_4-4 > li:before {
  content: "" counter(lst-ctn-kix_list_4-4, lower-latin) ". ";
}
.lst-kix_list_9-3 > li:before {
  content: "" counter(lst-ctn-kix_list_9-3, decimal) ". ";
}
ol.lst-kix_list_7-0.start {
  counter-reset: lst-ctn-kix_list_7-0 0;
}
ol.lst-kix_list_13-2.start {
  counter-reset: lst-ctn-kix_list_13-2 0;
}
.lst-kix_list_29-8 > li:before {
  content: "" counter(lst-ctn-kix_list_29-8, lower-roman) ". ";
}
ol.lst-kix_list_3-6.start {
  counter-reset: lst-ctn-kix_list_3-6 0;
}
.lst-kix_list_32-5 > li:before {
  content: "" counter(lst-ctn-kix_list_32-5, lower-roman) ". ";
}
.lst-kix_list_12-4 > li:before {
  content: "" counter(lst-ctn-kix_list_12-4, lower-latin) ". ";
}
.lst-kix_list_5-3 > li {
  counter-increment: lst-ctn-kix_list_5-3;
}
.lst-kix_list_7-4 > li {
  counter-increment: lst-ctn-kix_list_7-4;
}
.lst-kix_list_33-1 > li:before {
  content: "o  ";
}
.lst-kix_list_1-0 > li:before {
  content: "" counter(lst-ctn-kix_list_1-0, decimal) ". ";
}
ol.lst-kix_list_38-5.start {
  counter-reset: lst-ctn-kix_list_38-5 0;
}
.lst-kix_list_11-8 > li:before {
  content: "" counter(lst-ctn-kix_list_11-8, lower-roman) ". ";
}
ol.lst-kix_list_2-0.start {
  counter-reset: lst-ctn-kix_list_2-0 0;
}
.lst-kix_list_13-0 > li:before {
  content: "" counter(lst-ctn-kix_list_13-0, decimal) ". ";
}
.lst-kix_list_13-4 > li:before {
  content: "" counter(lst-ctn-kix_list_13-4, lower-latin) ". ";
}
ol.lst-kix_list_26-5.start {
  counter-reset: lst-ctn-kix_list_26-5 0;
}
ol.lst-kix_list_38-6.start {
  counter-reset: lst-ctn-kix_list_38-6 0;
}
ol.lst-kix_list_2-1.start {
  counter-reset: lst-ctn-kix_list_2-1 0;
}
.lst-kix_list_4-5 > li {
  counter-increment: lst-ctn-kix_list_4-5;
}
.lst-kix_list_12-8 > li:before {
  content: "" counter(lst-ctn-kix_list_12-8, lower-roman) ". ";
}
.lst-kix_list_8-2 > li {
  counter-increment: lst-ctn-kix_list_8-2;
}
ol.lst-kix_list_17-7.start {
  counter-reset: lst-ctn-kix_list_17-7 0;
}
ol.lst-kix_list_12-6.start {
  counter-reset: lst-ctn-kix_list_12-6 0;
}
.lst-kix_list_23-8 > li {
  counter-increment: lst-ctn-kix_list_23-8;
}
.lst-kix_list_35-5 > li {
  counter-increment: lst-ctn-kix_list_35-5;
}
ol.lst-kix_list_30-6 {
  list-style-type: none;
}
ol.lst-kix_list_8-2.start {
  counter-reset: lst-ctn-kix_list_8-2 0;
}
ol.lst-kix_list_26-1.start {
  counter-reset: lst-ctn-kix_list_26-1 0;
}
ol.lst-kix_list_30-5 {
  list-style-type: none;
}
.lst-kix_list_19-2 > li:before {
  content: "" counter(lst-ctn-kix_list_19-0, decimal) "."
    counter(lst-ctn-kix_list_19-1, decimal) "."
    counter(lst-ctn-kix_list_19-2, decimal) " ";
}
ol.lst-kix_list_30-8 {
  list-style-type: none;
}
ol.lst-kix_list_3-1.start {
  counter-reset: lst-ctn-kix_list_3-1 0;
}
ol.lst-kix_list_21-0.start {
  counter-reset: lst-ctn-kix_list_21-0 0;
}
ol.lst-kix_list_30-7 {
  list-style-type: none;
}
ol.lst-kix_list_30-2 {
  list-style-type: none;
}
ol.lst-kix_list_30-1 {
  list-style-type: none;
}
ol.lst-kix_list_30-4 {
  list-style-type: none;
}
ol.lst-kix_list_30-3 {
  list-style-type: none;
}
.lst-kix_list_47-7 > li:before {
  content: "" counter(lst-ctn-kix_list_47-7, lower-latin) ". ";
}
ol.lst-kix_list_30-0 {
  list-style-type: none;
}
ol.lst-kix_list_42-5.start {
  counter-reset: lst-ctn-kix_list_42-5 0;
}
.lst-kix_list_45-0 > li {
  counter-increment: lst-ctn-kix_list_45-0;
}
.lst-kix_list_36-6 > li {
  counter-increment: lst-ctn-kix_list_36-6;
}
.lst-kix_list_2-3 > li {
  counter-increment: lst-ctn-kix_list_2-3;
}
ol.lst-kix_list_47-6.start {
  counter-reset: lst-ctn-kix_list_47-6 0;
}
.lst-kix_list_47-4 > li:before {
  content: "" counter(lst-ctn-kix_list_47-4, lower-latin) ". ";
}
.lst-kix_list_1-2 > li {
  counter-increment: lst-ctn-kix_list_1-2;
}
.lst-kix_list_19-8 > li:before {
  content: "" counter(lst-ctn-kix_list_19-0, decimal) "."
    counter(lst-ctn-kix_list_19-1, decimal) "."
    counter(lst-ctn-kix_list_19-2, decimal) "."
    counter(lst-ctn-kix_list_19-3, decimal) "."
    counter(lst-ctn-kix_list_19-4, decimal) "."
    counter(lst-ctn-kix_list_19-5, decimal) "."
    counter(lst-ctn-kix_list_19-6, decimal) "."
    counter(lst-ctn-kix_list_19-7, decimal) "."
    counter(lst-ctn-kix_list_19-8, decimal) " ";
}
ol.lst-kix_list_20-8.start {
  counter-reset: lst-ctn-kix_list_20-8 0;
}
.lst-kix_list_46-1 > li {
  counter-increment: lst-ctn-kix_list_46-1;
}
.lst-kix_list_19-5 > li:before {
  content: "" counter(lst-ctn-kix_list_19-0, decimal) "."
    counter(lst-ctn-kix_list_19-1, decimal) "."
    counter(lst-ctn-kix_list_19-2, decimal) "."
    counter(lst-ctn-kix_list_19-3, decimal) "."
    counter(lst-ctn-kix_list_19-4, decimal) "."
    counter(lst-ctn-kix_list_19-5, decimal) " ";
}
.lst-kix_list_47-1 > li:before {
  content: "" counter(lst-ctn-kix_list_47-1, lower-latin) ". ";
}
ol.lst-kix_list_34-8.start {
  counter-reset: lst-ctn-kix_list_34-8 0;
}
.lst-kix_list_37-7 > li:before {
  content: "" counter(lst-ctn-kix_list_37-7, lower-latin) ". ";
}
ol.lst-kix_list_17-2.start {
  counter-reset: lst-ctn-kix_list_17-2 0;
}
.lst-kix_list_13-2 > li {
  counter-increment: lst-ctn-kix_list_13-2;
}
ol.lst-kix_list_21-5.start {
  counter-reset: lst-ctn-kix_list_21-5 0;
}
.lst-kix_list_38-0 > li:before {
  content: "3." counter(lst-ctn-kix_list_38-0, decimal) " ";
}
.lst-kix_list_19-7 > li {
  counter-increment: lst-ctn-kix_list_19-7;
}
.lst-kix_list_14-3 > li {
  counter-increment: lst-ctn-kix_list_14-3;
}
.lst-kix_list_37-4 > li:before {
  content: "" counter(lst-ctn-kix_list_37-4, lower-latin) ". ";
}
.lst-kix_list_12-1 > li {
  counter-increment: lst-ctn-kix_list_12-1;
}
ol.lst-kix_list_47-1.start {
  counter-reset: lst-ctn-kix_list_47-1 0;
}
ol.lst-kix_list_25-4.start {
  counter-reset: lst-ctn-kix_list_25-4 0;
}
.lst-kix_list_38-3 > li:before {
  content: "" counter(lst-ctn-kix_list_38-3, decimal) ". ";
}
.lst-kix_list_38-6 > li:before {
  content: "" counter(lst-ctn-kix_list_38-6, decimal) ". ";
}
ol.lst-kix_list_34-3.start {
  counter-reset: lst-ctn-kix_list_34-3 0;
}
ol.lst-kix_list_2-4.start {
  counter-reset: lst-ctn-kix_list_2-4 0;
}
.lst-kix_list_34-4 > li {
  counter-increment: lst-ctn-kix_list_34-4;
}
ol.lst-kix_list_1-3 {
  list-style-type: none;
}
ol.lst-kix_list_1-4 {
  list-style-type: none;
}
ol.lst-kix_list_1-5 {
  list-style-type: none;
}
ol.lst-kix_list_1-6 {
  list-style-type: none;
}
.lst-kix_list_41-8 > li {
  counter-increment: lst-ctn-kix_list_41-8;
}
ol.lst-kix_list_1-0 {
  list-style-type: none;
}
.lst-kix_list_2-5 > li:before {
  content: "" counter(lst-ctn-kix_list_2-5, lower-roman) ". ";
}
ol.lst-kix_list_1-1 {
  list-style-type: none;
}
ol.lst-kix_list_1-2 {
  list-style-type: none;
}
ol.lst-kix_list_17-0.start {
  counter-reset: lst-ctn-kix_list_17-0 0;
}
.lst-kix_list_18-6 > li:before {
  content: "" counter(lst-ctn-kix_list_18-6, decimal) ". ";
}
.lst-kix_list_39-5 > li:before {
  content: "" counter(lst-ctn-kix_list_39-5, lower-roman) ". ";
}
.lst-kix_list_14-6 > li {
  counter-increment: lst-ctn-kix_list_14-6;
}
.lst-kix_list_7-7 > li {
  counter-increment: lst-ctn-kix_list_7-7;
}
ol.lst-kix_list_42-3.start {
  counter-reset: lst-ctn-kix_list_42-3 0;
}
ol.lst-kix_list_1-7 {
  list-style-type: none;
}
ol.lst-kix_list_1-8 {
  list-style-type: none;
}
ol.lst-kix_list_39-2.start {
  counter-reset: lst-ctn-kix_list_39-2 0;
}
li.li-bullet-1:before {
  margin-left: -18pt;
  white-space: nowrap;
  display: inline-block;
  min-width: 18pt;
}
.lst-kix_list_10-3 > li:before {
  content: "" counter(lst-ctn-kix_list_10-3, decimal) ". ";
}
.lst-kix_list_15-4 > li {
  counter-increment: lst-ctn-kix_list_15-4;
}
.lst-kix_list_2-6 > li {
  counter-increment: lst-ctn-kix_list_2-6;
}
ol.lst-kix_list_7-3.start {
  counter-reset: lst-ctn-kix_list_7-3 0;
}
.lst-kix_list_36-8 > li:before {
  content: "" counter(lst-ctn-kix_list_36-8, lower-roman) ". ";
}
ol.lst-kix_list_43-7 {
  list-style-type: none;
}
ol.lst-kix_list_43-8 {
  list-style-type: none;
}
ol.lst-kix_list_43-5 {
  list-style-type: none;
}
ol.lst-kix_list_43-6 {
  list-style-type: none;
}
ol.lst-kix_list_43-3 {
  list-style-type: none;
}
ol.lst-kix_list_43-4 {
  list-style-type: none;
}
.lst-kix_list_28-1 > li {
  counter-increment: lst-ctn-kix_list_28-1;
}
ol.lst-kix_list_43-1 {
  list-style-type: none;
}
ol.lst-kix_list_43-2 {
  list-style-type: none;
}
ol.lst-kix_list_43-0 {
  list-style-type: none;
}
.lst-kix_list_46-8 > li:before {
  content: "" counter(lst-ctn-kix_list_46-8, lower-roman) ". ";
}
.lst-kix_list_3-4 > li {
  counter-increment: lst-ctn-kix_list_3-4;
}
.lst-kix_list_29-5 > li:before {
  content: "" counter(lst-ctn-kix_list_29-5, lower-roman) ". ";
}
.lst-kix_list_20-0 > li:before {
  content: "3." counter(lst-ctn-kix_list_20-0, decimal) " ";
}
.lst-kix_list_9-6 > li:before {
  content: "" counter(lst-ctn-kix_list_9-6, decimal) ". ";
}
ol.lst-kix_list_34-5.start {
  counter-reset: lst-ctn-kix_list_34-5 0;
}
.lst-kix_list_20-6 > li:before {
  content: "" counter(lst-ctn-kix_list_20-6, decimal) ". ";
}
.lst-kix_list_23-0 > li {
  counter-increment: lst-ctn-kix_list_23-0;
}
ol.lst-kix_list_12-1.start {
  counter-reset: lst-ctn-kix_list_12-1 0;
}
.lst-kix_list_11-5 > li:before {
  content: "" counter(lst-ctn-kix_list_11-5, lower-roman) ". ";
}
ol.lst-kix_list_21-2.start {
  counter-reset: lst-ctn-kix_list_21-2 0;
}
.lst-kix_list_20-6 > li {
  counter-increment: lst-ctn-kix_list_20-6;
}
ul.lst-kix_list_33-0 {
  list-style-type: none;
}
.lst-kix_list_8-5 > li {
  counter-increment: lst-ctn-kix_list_8-5;
}
ol.lst-kix_list_25-7.start {
  counter-reset: lst-ctn-kix_list_25-7 0;
}
ul.lst-kix_list_33-1 {
  list-style-type: none;
}
.lst-kix_list_1-3 > li:before {
  content: "" counter(lst-ctn-kix_list_1-0, decimal) "."
    counter(lst-ctn-kix_list_1-1, decimal) "."
    counter(lst-ctn-kix_list_1-2, decimal) "."
    counter(lst-ctn-kix_list_1-3, decimal) " ";
}
ul.lst-kix_list_33-2 {
  list-style-type: none;
}
ol.lst-kix_list_34-6.start {
  counter-reset: lst-ctn-kix_list_34-6 0;
}
ul.lst-kix_list_33-3 {
  list-style-type: none;
}
ul.lst-kix_list_33-4 {
  list-style-type: none;
}
ul.lst-kix_list_33-5 {
  list-style-type: none;
}
ul.lst-kix_list_33-6 {
  list-style-type: none;
}
.lst-kix_list_28-3 > li:before {
  content: "" counter(lst-ctn-kix_list_28-3, decimal) ". ";
}
ol.lst-kix_list_42-0.start {
  counter-reset: lst-ctn-kix_list_42-0 3;
}
ul.lst-kix_list_33-7 {
  list-style-type: none;
}
ol.lst-kix_list_21-8 {
  list-style-type: none;
}
ol.lst-kix_list_21-7 {
  list-style-type: none;
}
ol.lst-kix_list_2-7.start {
  counter-reset: lst-ctn-kix_list_2-7 0;
}
ol.lst-kix_list_21-4 {
  list-style-type: none;
}
ol.lst-kix_list_21-3 {
  list-style-type: none;
}
ol.lst-kix_list_21-6 {
  list-style-type: none;
}
ol.lst-kix_list_21-5 {
  list-style-type: none;
}
ol.lst-kix_list_21-0 {
  list-style-type: none;
}
ol.lst-kix_list_7-5.start {
  counter-reset: lst-ctn-kix_list_7-5 0;
}
ol.lst-kix_list_21-2 {
  list-style-type: none;
}
ol.lst-kix_list_21-1 {
  list-style-type: none;
}
.lst-kix_list_27-7 > li:before {
  content: "" counter(lst-ctn-kix_list_27-7, lower-latin) ". ";
}
.lst-kix_list_25-7 > li {
  counter-increment: lst-ctn-kix_list_25-7;
}
ul.lst-kix_list_33-8 {
  list-style-type: none;
}
ol.lst-kix_list_39-4.start {
  counter-reset: lst-ctn-kix_list_39-4 0;
}
.lst-kix_list_35-2 > li:before {
  content: "" counter(lst-ctn-kix_list_35-2, lower-roman) ". ";
}
.lst-kix_list_3-1 > li {
  counter-increment: lst-ctn-kix_list_3-1;
}
.lst-kix_list_30-3 > li:before {
  content: "" counter(lst-ctn-kix_list_30-3, decimal) ". ";
}
ol.lst-kix_list_34-0.start {
  counter-reset: lst-ctn-kix_list_34-0 5;
}
.lst-kix_list_26-8 > li {
  counter-increment: lst-ctn-kix_list_26-8;
}
ol.lst-kix_list_39-1.start {
  counter-reset: lst-ctn-kix_list_39-1 0;
}
ol.lst-kix_list_7-7.start {
  counter-reset: lst-ctn-kix_list_7-7 0;
}
.lst-kix_list_3-1 > li:before {
  content: "" counter(lst-ctn-kix_list_3-1, lower-latin) ". ";
}
.lst-kix_list_14-0 > li {
  counter-increment: lst-ctn-kix_list_14-0;
}
.lst-kix_list_44-0 > li:before {
  content: "4." counter(lst-ctn-kix_list_44-0, decimal) " ";
}
ol.lst-kix_list_17-4.start {
  counter-reset: lst-ctn-kix_list_17-4 0;
}
ol.lst-kix_list_12-3.start {
  counter-reset: lst-ctn-kix_list_12-3 0;
}
.lst-kix_list_44-2 > li {
  counter-increment: lst-ctn-kix_list_44-2;
}
.lst-kix_list_21-2 > li:before {
  content: "" counter(lst-ctn-kix_list_21-2, lower-roman) ". ";
}
.lst-kix_list_2-0 > li {
  counter-increment: lst-ctn-kix_list_2-0;
}
.lst-kix_list_15-1 > li {
  counter-increment: lst-ctn-kix_list_15-1;
}
ol.lst-kix_list_47-3.start {
  counter-reset: lst-ctn-kix_list_47-3 0;
}
.lst-kix_list_36-3 > li {
  counter-increment: lst-ctn-kix_list_36-3;
}
.lst-kix_list_11-2 > li:before {
  content: "" counter(lst-ctn-kix_list_11-2, lower-roman) ". ";
}
ol.lst-kix_list_42-2.start {
  counter-reset: lst-ctn-kix_list_42-2 0;
}
.lst-kix_list_40-4 > li {
  counter-increment: lst-ctn-kix_list_40-4;
}
ol.lst-kix_list_12-4.start {
  counter-reset: lst-ctn-kix_list_12-4 0;
}
.lst-kix_list_35-2 > li {
  counter-increment: lst-ctn-kix_list_35-2;
}
.lst-kix_list_25-2 > li:before {
  content: "" counter(lst-ctn-kix_list_25-2, lower-roman) ". ";
}
.lst-kix_list_29-5 > li {
  counter-increment: lst-ctn-kix_list_29-5;
}
ol.lst-kix_list_47-2.start {
  counter-reset: lst-ctn-kix_list_47-2 0;
}
.lst-kix_list_16-1 > li:before {
  content: "" counter(lst-ctn-kix_list_16-1, lower-latin) ". ";
}
ol.lst-kix_list_39-0.start {
  counter-reset: lst-ctn-kix_list_39-0 0;
}
ol.lst-kix_list_7-8.start {
  counter-reset: lst-ctn-kix_list_7-8 0;
}
ol.lst-kix_list_47-8.start {
  counter-reset: lst-ctn-kix_list_47-8 0;
}
.lst-kix_list_12-4 > li {
  counter-increment: lst-ctn-kix_list_12-4;
}
.lst-kix_list_44-3 > li:before {
  content: "" counter(lst-ctn-kix_list_44-3, decimal) ". ";
}
.lst-kix_list_39-2 > li:before {
  content: "" counter(lst-ctn-kix_list_39-2, lower-roman) ". ";
}
.lst-kix_list_12-7 > li {
  counter-increment: lst-ctn-kix_list_12-7;
}
.lst-kix_list_34-7 > li {
  counter-increment: lst-ctn-kix_list_34-7;
}
.lst-kix_list_30-0 > li:before {
  content: "7." counter(lst-ctn-kix_list_30-0, decimal) " ";
}
ol.lst-kix_list_17-3.start {
  counter-reset: lst-ctn-kix_list_17-3 0;
}
.lst-kix_list_43-4 > li:before {
  content: "" counter(lst-ctn-kix_list_43-4, lower-latin) ". ";
}
ol.lst-kix_list_47-7.start {
  counter-reset: lst-ctn-kix_list_47-7 0;
}
.lst-kix_list_7-1 > li:before {
  content: "" counter(lst-ctn-kix_list_7-1, lower-latin) ". ";
}
.lst-kix_list_13-5 > li {
  counter-increment: lst-ctn-kix_list_13-5;
}
.lst-kix_list_9-6 > li {
  counter-increment: lst-ctn-kix_list_9-6;
}
.lst-kix_list_29-2 > li {
  counter-increment: lst-ctn-kix_list_29-2;
}
ol.lst-kix_list_42-6.start {
  counter-reset: lst-ctn-kix_list_42-6 0;
}
.lst-kix_list_20-3 > li {
  counter-increment: lst-ctn-kix_list_20-3;
}
.lst-kix_list_11-6 > li {
  counter-increment: lst-ctn-kix_list_11-6;
}
ol.lst-kix_list_47-4.start {
  counter-reset: lst-ctn-kix_list_47-4 0;
}
ol.lst-kix_list_17-8.start {
  counter-reset: lst-ctn-kix_list_17-8 0;
}
ol.lst-kix_list_34-1.start {
  counter-reset: lst-ctn-kix_list_34-1 0;
}
.lst-kix_list_1-5 > li {
  counter-increment: lst-ctn-kix_list_1-5;
}
.lst-kix_list_28-4 > li {
  counter-increment: lst-ctn-kix_list_28-4;
}
.lst-kix_list_37-1 > li {
  counter-increment: lst-ctn-kix_list_37-1;
}
ol.lst-kix_list_17-5.start {
  counter-reset: lst-ctn-kix_list_17-5 0;
}
.lst-kix_list_15-2 > li:before {
  content: "" counter(lst-ctn-kix_list_15-2, lower-roman) ". ";
}
.lst-kix_list_10-8 > li {
  counter-increment: lst-ctn-kix_list_10-8;
}
.lst-kix_list_10-6 > li:before {
  content: "" counter(lst-ctn-kix_list_10-6, decimal) ". ";
}
.lst-kix_list_40-7 > li {
  counter-increment: lst-ctn-kix_list_40-7;
}
ol.lst-kix_list_12-7.start {
  counter-reset: lst-ctn-kix_list_12-7 0;
}
.lst-kix_list_41-5 > li {
  counter-increment: lst-ctn-kix_list_41-5;
}
ol.lst-kix_list_42-8.start {
  counter-reset: lst-ctn-kix_list_42-8 0;
}
ol.lst-kix_list_12-8.start {
  counter-reset: lst-ctn-kix_list_12-8 0;
}
.lst-kix_list_20-3 > li:before {
  content: "" counter(lst-ctn-kix_list_20-3, decimal) ". ";
}
.lst-kix_list_27-6 > li {
  counter-increment: lst-ctn-kix_list_27-6;
}
.lst-kix_list_29-2 > li:before {
  content: "" counter(lst-ctn-kix_list_29-2, lower-roman) ". ";
}
.lst-kix_list_21-1 > li {
  counter-increment: lst-ctn-kix_list_21-1;
}
.lst-kix_list_8-8 > li {
  counter-increment: lst-ctn-kix_list_8-8;
}
ol.lst-kix_list_17-6.start {
  counter-reset: lst-ctn-kix_list_17-6 0;
}
.lst-kix_list_28-6 > li:before {
  content: "" counter(lst-ctn-kix_list_28-6, decimal) ". ";
}
.lst-kix_list_1-6 > li:before {
  content: "" counter(lst-ctn-kix_list_1-0, decimal) "."
    counter(lst-ctn-kix_list_1-1, decimal) "."
    counter(lst-ctn-kix_list_1-2, decimal) "."
    counter(lst-ctn-kix_list_1-3, decimal) "."
    counter(lst-ctn-kix_list_1-4, decimal) "."
    counter(lst-ctn-kix_list_1-5, decimal) "."
    counter(lst-ctn-kix_list_1-6, decimal) " ";
}
.lst-kix_list_42-3 > li {
  counter-increment: lst-ctn-kix_list_42-3;
}
ol.lst-kix_list_47-5.start {
  counter-reset: lst-ctn-kix_list_47-5 0;
}
.lst-kix_list_43-1 > li {
  counter-increment: lst-ctn-kix_list_43-1;
}
.lst-kix_list_33-7 > li:before {
  content: "\0025aa  ";
}
ol.lst-kix_list_42-7.start {
  counter-reset: lst-ctn-kix_list_42-7 0;
}
.lst-kix_list_40-0 > li:before {
  content: "14." counter(lst-ctn-kix_list_40-0, decimal) " ";
}
.lst-kix_list_34-3 > li:before {
  content: "" counter(lst-ctn-kix_list_34-3, decimal) ". ";
}
.lst-kix_list_2-2 > li:before {
  content: "" counter(lst-ctn-kix_list_2-2, lower-roman) ". ";
}
ol {
  margin: 0;
  padding: 0;
}
table td,
table th {
  padding: 0;
}
.c1 {
  -webkit-text-decoration-skip: none;
  color: #000000;
  font-weight: 700;
  text-decoration: underline;
  vertical-align: baseline;
  text-decoration-skip-ink: none;
  font-size: 11pt;
  font-family: "Times New Roman";
  font-style: normal;
}
.c8 {
  -webkit-text-decoration-skip: none;
  color: #000000;
  font-weight: 400;
  text-decoration: underline;
  vertical-align: baseline;
  text-decoration-skip-ink: none;
  font-family: "Times New Roman";
  font-style: normal;
}
.c4 {
  margin-left: 36pt;
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1.1500000000000001;
  orphans: 2;
  widows: 2;
  text-align: justify;
  height: 12pt;
}
.c0 {
  color: #000000;
  font-weight: 700;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 11pt;
  font-family: "Times New Roman";
  font-style: normal;
}
.c2 {
  color: #000000;
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 11pt;
  font-family: "Times New Roman";
  font-style: normal;
}
.c23 {
  color: #333333;
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-family: "Times New Roman";
  font-style: normal;
}
.c34 {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1;
  orphans: 2;
  widows: 2;
  text-align: justify;
}
.c24 {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1.1500000000000001;
  orphans: 2;
  widows: 2;
  text-align: center;
}
.c5 {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1.1500000000000001;
  orphans: 2;
  widows: 2;
  text-align: justify;
}
.c17 {
  color: #000000;
  text-decoration: none;
  vertical-align: baseline;
  font-family: "Times New Roman";
  font-style: italic;
}
.c30 {
  text-decoration-skip-ink: none;
  -webkit-text-decoration-skip: none;
  color: #0000ff;
  text-decoration: underline;
}
.c33 {
  font-family: "Georgia";
  color: #222222;
  font-weight: 400;
}
.c9 {
  margin-left: 18pt;
  padding-left: 18pt;
}
.c7 {
  padding: 0;
  margin: 0;
}
.c15 {
  color: inherit;
  text-decoration: inherit;
}
.c28 {
  max-width: 468pt;
  padding: 72pt 72pt 72pt 72pt;
}
.c18 {
  margin-left: 36pt;
  padding-left: 0pt;
}
.c14 {
  margin-left: 54pt;
  padding-left: 18pt;
}
.c13 {
  padding-left: 11.2pt;
}
.c32 {
  margin-left: 54pt;
}
.c6 {
  height: 12pt;
}
.c3 {
  font-size: 11pt;
}
.c27 {
  padding-left: 17.5pt;
}
.c11 {
  margin-left: 29.2pt;
}
.c26 {
  margin-left: 72pt;
}
.c19 {
  background-color: #ffff00;
}
.c22 {
  background-color: #ffffff;
}
.c16 {
  margin-left: 22.5pt;
}
.c21 {
  margin-left: 58.5pt;
}
.c29 {
  margin-left: 36pt;
}
.c12 {
  margin-left: 18pt;
}
.c10 {
  font-weight: 700;
}
.c20 {
  margin-left: 47.2pt;
}
.c31 {
  padding-left: 22.5pt;
}
.c25 {
  margin-left: 35.5pt;
}
.title {
  padding-top: 0pt;
  color: #000000;
  border-bottom-color: #4f81bd;
  border-bottom-width: 1pt;
  font-size: 12pt;
  padding-bottom: 4pt;
  font-family: "Times New Roman";
  line-height: 1;
  border-bottom-style: solid;
  orphans: 2;
  widows: 2;
  text-align: justify;
}
.subtitle {
  padding-top: 18pt;
  color: #666666;
  font-size: 24pt;
  padding-bottom: 4pt;
  font-family: "Georgia";
  line-height: 1;
  page-break-after: avoid;
  font-style: italic;
  orphans: 2;
  widows: 2;
  text-align: justify;
}
li {
  color: #000000;
  font-size: 12pt;
  font-family: "Times New Roman";
}
p {
  margin: 0;
  color: #000000;
  font-size: 12pt;
  font-family: "Times New Roman";
}
h1 {
  padding-top: 24pt;
  color: #365f91;
  font-weight: 700;
  font-size: 14pt;
  padding-bottom: 0pt;
  font-family: "Cambria";
  line-height: 1;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: justify;
}
h2 {
  padding-top: 10pt;
  color: #4f81bd;
  font-weight: 700;
  font-size: 13pt;
  padding-bottom: 0pt;
  font-family: "Cambria";
  line-height: 1;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: justify;
}
h3 {
  padding-top: 10pt;
  color: #4f81bd;
  font-weight: 700;
  font-size: 12pt;
  padding-bottom: 0pt;
  font-family: "Cambria";
  line-height: 1;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: justify;
}
h4 {
  padding-top: 10pt;
  color: #4f81bd;
  font-weight: 700;
  font-size: 12pt;
  padding-bottom: 0pt;
  font-family: "Cambria";
  line-height: 1;
  page-break-after: avoid;
  font-style: italic;
  orphans: 2;
  widows: 2;
  text-align: justify;
}
h5 {
  padding-top: 11pt;
  color: #000000;
  font-weight: 700;
  font-size: 11pt;
  padding-bottom: 2pt;
  font-family: "Times New Roman";
  line-height: 1;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: justify;
}
h6 {
  padding-top: 10pt;
  color: #000000;
  font-weight: 700;
  font-size: 10pt;
  padding-bottom: 2pt;
  font-family: "Times New Roman";
  line-height: 1;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: justify;
}
.bottomPadding {
  padding-bottom: 75px;
  padding-top: 90px;
}
</style>