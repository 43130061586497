<template>
  <v-container fluid>
    <v-row justify="end">
      <v-col cols="12">
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-card color="white" flat tile :loading="loading">
            <template slot="progress">
              <v-progress-linear
                color="deep-purple"
                height="10"
                indeterminate
              ></v-progress-linear>
            </template>
            <v-card-title>
              <span class="headline">{{ cardTitle }}</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      :value="StateUser.id"
                      label="User Id"
                      readonly
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="6" sm="6" md="6">
                    <v-text-field
                      v-model="user.first_name"
                      label="First Name"
                      :rules="nameRules"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6" sm="6" md="6">
                    <v-text-field
                      v-model="user.last_name"
                      label="Last Name"
                      :rules="nameRules"
                      required
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      v-model="user.mobile_number"
                      label="Mobile Number"
                      :rules="numberRules"
                      required
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      disabled
                      v-model="user.email_id"
                      label="Email Id"
                      required
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-checkbox
                      v-model="user.enable_mfa"
                      :input-value="user.enable_mfa"
                      :value="user.enable_mfa"
                      :disabled="mfaDisabled"
                      label="MFA enabled for User. Un-tick to disable MFA"
                      color="indigo darken-3"
                      hide-details
                    >
                    </v-checkbox>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-col cols="2">
                <v-btn
                  block
                  color="#424242"
                  class="white--text"
                  @click="UpdateUser"
                  :disabled="!valid"
                >
                  Update User
                </v-btn>
              </v-col>
              <v-col cols="2">
                <v-btn block color="#424242" class="white--text" to="/vault">
                  Close
                </v-btn>
              </v-col>
              <v-col cols="2">
                <!-- Form to update Principal password-->
                <v-dialog v-model="dialog" :max-width="dialogWidth" persistent>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      block
                      color="#bdbdbd"
                      v-bind="attrs"
                      v-on="on"
                      v-if="enableMasterPasswordUpdate"
                    >
                      Change Principal Password
                    </v-btn>
                  </template>
                  <v-card tile outlined elevation="3" :loading="loading">
                    <v-system-bar dark color="#000000">
                      <v-spacer></v-spacer>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            small
                            icon
                            v-bind="attrs"
                            v-on="on"
                            @click="closeDialog"
                          >
                            <v-icon>mdi-close</v-icon>
                          </v-btn>
                        </template>
                        Exit
                      </v-tooltip>
                    </v-system-bar>
                    <v-container>
                      <template slot="progress">
                        <v-progress-linear
                          color="deep-purple"
                          height="10"
                          indeterminate
                        ></v-progress-linear>
                      </template>
                      <v-card-title>
                        <span class="headline">Change Principal Password </span>
                      </v-card-title>
                      <v-card-subtitle>
                        <span class="red--text"
                          ><b
                            >NOTE: After Pricnipal Password is updated, you'll
                            need to logout and login</b
                          ></span
                        >
                      </v-card-subtitle>
                      <v-form
                        ref="formPass"
                        v-model="validPass"
                        lazy-validation
                      >
                        <v-card-text>
                          <v-container>
                            <v-row>
                              <v-col cols="12" sm="12" md="12">
                                <v-text-field
                                  type="password"
                                  v-model="current_pass"
                                  label="Enter Current Password"
                                  :rules="passwordRules"
                                  required
                                ></v-text-field>
                              </v-col>
                            </v-row>
                            <v-row>
                              <v-col cols="12" sm="12" md="12">
                                <v-text-field
                                  type="password"
                                  v-model="user.password"
                                  label="Enter New Password"
                                  :rules="newPasswordRules"
                                  required
                                ></v-text-field>
                              </v-col>
                            </v-row>
                            <v-row>
                              <v-col cols="12" sm="12" md="12">
                                <v-text-field
                                  v-model="user.re_password"
                                  label="Re-enter Password"
                                  :rules="validatePasswordRule"
                                  required
                                ></v-text-field>
                              </v-col>
                            </v-row>
                          </v-container>
                        </v-card-text>
                        <v-card-actions>
                          <v-btn
                            class="success"
                            text
                            @click="UpdatePassword"
                            :disabled="!validPass"
                          >
                            Update
                          </v-btn>
                        </v-card-actions>
                      </v-form>
                    </v-container>
                  </v-card>
                </v-dialog>
              </v-col>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-col>
    </v-row>
    <v-dialog v-model="dialogAlert" :max-width="dialogWidth" persistent>
      <v-alert :type="alertType">
        <v-row align="center">
          <v-col class="grow">{{ alert_message }}</v-col>
          <v-col v-if="logout" class="shrink">
            <v-btn @click="logoutAlert">logout</v-btn>
          </v-col>
          <v-col v-else class="shrink">
            <v-btn @click="closeAlert">Exit</v-btn>
          </v-col>
        </v-row>
      </v-alert>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import axios from "axios";
import cryptoHelper from "../services/webcrypto.js";
export default {
  name: "UserProfileUpdate",
  mixins: [cryptoHelper],
  data() {
    return {
      validPass: true,
      valid: true,
      loading: false,
      cardTitle: "Update User Info",
      dialogAlert: false,
      dialog: false,
      dialogWidth: "600px",
      enableMasterPasswordUpdate: false,
      current_pass: "",
      logout: false,
      alert_message: "",
      alertType: "",
      nameRules: [
        (v) => !!v || "Required",
        (v) => (v && v.length <= 50) || "Must be less than 50 characters",
      ],
      numberRules: [
        (v) => !!v || "Required",
        (v) => /^[0-9]+$/.test(v) || "only numbers are allowed",
        (v) => (v && v.length == 10) || "Must be 10 digit mobile number",
      ],
      passwordRules: [(v) => !!v || "Password is required"],
      newPasswordRules: [
        (v) => !!v || "Password is required",
        (v) =>
          (v && v !== this.current_pass) ||
          "New password should be different than current",
        (v) =>
          /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z])[a-zA-Z0-9!@#$%^&*]{15,50}$/.test(
            v
          ) ||
          "Password criteria does not match. Password must contain following \n" +
            "At least one numeric digit (0-9). \n" +
            "At least one special character from ! @ # $ % ^ & * \n" +
            "At least one lower (a-z) and one upper (A-Z) case letter. \n" +
            "Length must be between 15 to 50 characters",
      ],
      validatePasswordRule: [
        (v) => !!v || "Required",
        (v) => (v && v === this.user.password) || "password do not match",
      ],
    };
  },
  computed: {
    ...mapGetters(["StateUser", "StateCredentialsRetail"]),
    user: function () {
      return this.StateUser;
    },
    mfaDisabled: function () {
      if (!this.user.enable_mfa) return true;
      return false;
    },
  },
  methods: {
    ...mapActions([
      "MutateUser",
      "MutateCryptoKey",
      "MutatePassword",
      "LogOut",
    ]),
    async UpdateUser() {
      try {
        if (this.$refs.form.validate()) {
          this.valid = false;
          this.loading = true;
          const updatedUser = new URLSearchParams();
          updatedUser.append("id", this.user.id);
          updatedUser.append("first_name", this.user.first_name);
          updatedUser.append("last_name", this.user.last_name);
          updatedUser.append("mobile_number", this.user.mobile_number);
          updatedUser.append("enable_mfa", !this.mfaDisabled);
          let responseData = await axios
            .post("UpdateUserProfile", updatedUser)
            .then(function (response) {
              if (response !== undefined && response.data !== undefined) {
                return response.data;
              }
            });
          this.valid = true;
          //Update User State
          await this.MutateUser(responseData);
          this.loading = false;

          this.alert_message = responseData.message;
          this.alertType = responseData.message_type.toLowerCase();
          this.dialogAlert = true;
        }
      } catch (error) {
        this.valid = true;
        this.loading = false;
        this.alert_message =
          "Error updating user profile. Please try again in some time" + error;
        this.alertType = "error";
        this.dialogAlert = true;
      }
    },
    async UpdatePassword() {
      try {
        if (this.$refs.formPass.validate()) {
          this.validPass = false;
          this.loading = true;
          //Get all credentials
          let creds = this.StateCredentialsRetail;
          // get crypto key based on new password
          let cryptokey = await this.getKeyUsingPBKDF2(
            this.user.password,
            this.StateUser.salt
          );
          let encryptedItems = [];
          let credsArray = JSON.parse(creds);
          if (credsArray) {
            for (const cred of credsArray) {
              let encrypted = await this.encrypt(
                cryptokey,
                JSON.stringify(cred.credentials)
              );
              cred.credentials = encrypted;
              encryptedItems.push(cred);
            }
          }

          let hashedPassNew = await this.sha512Hash(this.user.password);
          let hashedPassCurrent = await this.sha512Hash(this.current_pass);
          const updatedUser = new URLSearchParams();
          updatedUser.append("userId", this.user.id);
          updatedUser.append("new_pass_hash", hashedPassNew);
          updatedUser.append("current_pass_hash", hashedPassCurrent);
          updatedUser.append("items", JSON.stringify(encryptedItems));
          let responseData = await axios
            .post("UpdatePrincipalPassword", updatedUser)
            .then(function (response) {
              if (response !== undefined && response.data !== undefined) {
                return response.data;
              }
            });
          if (responseData.message_type === "Success") {
            await this.MutateCryptoKey(cryptokey);
            this.logout = true;
          }

          await this.MutatePassword();
          this.current_pass = "";
          this.validPass = true;
          this.loading = false;
          this.dialog = false;
          this.alert_message =
            responseData.message + ". Please logout and login";
          this.alertType = responseData.message_type.toLowerCase();
          this.dialogAlert = true;
        }
      } catch (error) {
        this.validPass = true;
        this.loading = false;
        this.dialog = false;
        this.alert_message =
          "Error updating principal password. Please try again in some time" +
          error;
        this.alertType = "error";
        this.dialogAlert = true;
      }
    },
    closeAlert() {
      this.dialogAlert = false;
      this.$nextTick(() => {
        this.alertType = "";
        this.alert_message = "";
        this.logout = false;
      });
    },
    async logoutAlert() {
      this.logout = false;
      await this.LogOut();
    },
    closeDialog() {
      this.dialog = false;
      this.$nextTick(() => {
        this.user.password = "";
        this.user.re_password = "";
      });
    },
  },
};
</script>
