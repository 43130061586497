<template>
  <div></div>
</template>
<script>
import { mapActions } from "vuex";
export default {
  name: "AutoLogout",
  data() {
    return {
      events: [
        "click",
        "mousemove",
        "mousedown",
        "scroll",
        "keydown",
        "keyup",
        "load",
      ],
      warningTime: process.env.VUE_APP_WARNING_TIME * 60 * 1000,
      logoutTime: process.env.VUE_APP_LOGOUT_TIME * 60 * 1000,

      warningTimer: null,
      logoutTimer: null,
      toastOptionsWarning: {
        // you can pass a single action as below
        action: {
          text: "Click To extend by 20 min",
          onClick: (e, toastObject) => {
            this.resetTimer();
            toastObject.goAway(0);
          },
        },
        //auto disappear after 5 minutes
        duration: 5 * 60 * 1000,
        position: "top-center",
        keepOnHover: true,
      },
      toastOptionsLogout: {
        // you can pass a single action as below
        action: {
          text: "Ok",
          onClick: (e, toastObject) => {
            toastObject.goAway(0);
          },
        },
        position: "top-center",
        keepOnHover: true,
      },
    };
  },
  mounted() {
    this.events.forEach(function (event) {
      document.addEventListener(event, this.resetTimer.bind(this));
    }, this);

    this.resetTimer();
  },
  destroyed() {
    this.events.forEach(function (event) {
      document.removeEventListener(event, this.clearTimer);
    }, this);
  },
  methods: {
    ...mapActions(["LogOut"]),

    setTimers() {
      //show warning after 15 minutes
      this.warningTimer = setTimeout(this.warningMessage, 15 * 60 * 1000);
      //Logout user if there is no activity until 20 minutes
      this.logoutTimer = setTimeout(this.logoutUser, 20 * 60 * 1000); //15 min
    },
    warningMessage() {
      this.$toasted.show(
        "Are you still with us? For your vault's security, you will be logged you out after 5 minutes if there is no further activity on the page",
        this.toastOptionsWarning
      );
    },
    logoutUser() {
      this.clearTimer();
      this.$toasted.show(
        "Due to inactivity for 20 minutes, you have been logged you out for your vault's safety",
        this.toastOptionsLogout
      );

      this.LogOut();
    },
    clearTimer() {
      clearTimeout(this.warningTimer);
      clearTimeout(this.logoutTimer);
    },
    resetTimer() {
      this.clearTimer();
      this.setTimers();
    },
  },
};
</script>
<style scoped></style>
