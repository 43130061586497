<template>
  <v-theme-provider :dark="dark">
    <div>
      <base-info-card title="GET IN TOUCH" pace="6">
        You can get in touch with us and send us a message from here. If you
        need detailed assistance about any of the products or services we
        provide, just reach us by phone or by email.
      </base-info-card>

      <base-business-contact dense />
    </div>
  </v-theme-provider>
</template>

<script>
export default {
  name: "BaseBusinessInfo",

  props: { dark: Boolean },

  data: () => ({
    business: [
      {
        icon: "mdi-map-marker-outline",
        title: "Address",
        text: "603, GM IT Park, Sector 142, Noida, Uttar Pradesh 201305",
      },
      // {
      //   icon: 'mdi-cellphone',
      //   title: 'Phone',
      //   text: '01 (800) 433 744<br>01 (800) 433 633',
      // },
      // {
      //   icon: 'mdi-email',
      //   title: 'Email',
      //   text: 'john@vuetifyjs.com<br>heather@vuetifyjs.com',
      // },
    ],
  }),
};
</script>
