<template>
  <v-container>
    <v-card color="white" flat v-if="!mfaAlreadyEnabled">
      <v-card-subtitle>
        <v-row>
          <h4>
            <span>
              Step 1) Please install ANY authenticator app on your phone (We
              recommend
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <a
                    target="_blank"
                    href="https://www.microsoft.com/en-us/account/authenticator"
                    rel="noopener"
                    @click.stop
                    v-on="on"
                  >
                    Microsoft
                  </a>
                </template>
                Microsoft Authenticator App
              </v-tooltip>
              or
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <a
                    target="_blank"
                    href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en_US&gl=US"
                    rel="noopener"
                    @click.stop
                    v-on="on"
                  >
                    Google
                  </a>
                </template>
                Google Authenticator App
              </v-tooltip>
              Authenticator).
            </span>
          </h4>
        </v-row>
        <v-row>
          <h4>
            Step 2) After app is installed on your phone, log into app, hit "+"
            sign on top right and scan below QR code.
          </h4>
        </v-row>
      </v-card-subtitle>
      <br />
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12" sm="12" md="12">
              <qrcode-vue
                :value="qrCodeText"
                :size="size"
                level="H"
              ></qrcode-vue>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6" sm="6" md="6">
              <v-text-field
                v-model="verificationCode"
                label="Please enter 6 digit code from the authenticator app and hit 'Submit Code' to enable MFA."
                required
                :rules="codeRules"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col sm="2">
              <v-btn @click="submitCode" color="#424242" class="white--text">
                Submit Code
                <v-icon> mdi-content-save-move </v-icon>
              </v-btn>
            </v-col>
            <v-col sm="2">
              <v-btn block color="#424242" class="white--text" to="/vault">
                Close
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
    <v-dialog v-model="showDialog" width="500" persistent>
      <v-card class="text-center">
        <v-card-subtitle>
          {{ dialogMessage }}
        </v-card-subtitle>
        <v-card-actions>
          <v-btn
            color="#424242"
            class="white--text"
            text
            @click="closeDialog"
            to="/vault"
            >Exit</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import QrcodeVue from "qrcode.vue";

export default {
  props: {
    qrCodeText: String,
    showDialog: Boolean,
    dialogMessage: String,
    mfaAlreadyEnabled: Boolean,
  },
  data() {
    return {
      size: 200,
      verificationCode: "",
      codeRules: [
        (v) => !!v || "Required",
        (v) => (v && v.length === 6) || "Code must be 6 digits",
      ],
    };
  },
  methods: {
    submitCode() {
      if (this.verificationCode.length !== 6) {
        alert("Please enter a valid 6 digit OTP");
        return;
      }
      this.$emit("qrCodeSubmitted", this.verificationCode);
    },
    closeDialog() {
      this.$emit("closeQrDialog");
    },
  },
  components: {
    QrcodeVue,
  },
};
</script>
