<template>
  <v-text-field
    solo
    dense
    :hide-details="hideDetails"
    :class="customClass"
    :readonly="isReadonly"
    :type="textType"
    v-model="textValueRendered"
    :label="labelValue"
    :solo-inverted="isSoloInverted"
  >
    <template v-slot:append-outer v-if="allowCopy">
      <v-tooltip right>
        <template v-slot:activator="{ on, attrs }">
          <v-icon small @click="copy(textValue)" v-bind="attrs" v-on="on">
            mdi-content-copy
          </v-icon>
        </template>
        {{ tooltip }}
      </v-tooltip>
    </template>
    <template v-slot:append v-if="showPassword">
      <v-tooltip right>
        <template v-slot:activator="{ on, attrs }">
          <span v-if="toggleShow">
            <v-icon small @click="show" v-bind="attrs" v-on="on"
              >mdi-eye</v-icon
            >
          </span>
          <span v-else>
            <v-icon small @click="show" v-bind="attrs" v-on="on"
              >mdi-eye-off</v-icon
            >
          </span>
        </template>
        {{ ShowToolTip }}
      </v-tooltip>
    </template>
  </v-text-field>
</template>

<script>
export default {
  props: {
    textValue: { type: String, default: "" },
    tooltip: { type: String, default: "" },
    isPassWord: { type: Boolean, default: false },
    isCard: { type: Boolean, default: false },
    allowCopy: { type: Boolean, default: false },
    isReadonly: { type: Boolean, default: false },
    showPassword: { type: Boolean, default: false },
    customClass: { type: String, default: "" },
    hideDetails: { type: Boolean, default: false },
    labelValue: { type: String, default: "" },
    isSoloInverted: { type: Boolean, default: false },
  },
  beforeMount() {
    this.textValueRendered = this.textValue;
    this.textType = this.isPassWord ? "password" : "text";
    if (this.isCard) {
      this.textValueCard = this.textValue;
      this.textValueHidden = this.textValueCard.replace(/.(?=.{4})/g, "*");
      this.textValueRendered = this.textValueHidden;
    }
  },
  data() {
    return {
      textValueRendered: "",
      toggleShow: false,
      textValueCard: "",
      textValueHidden: "",
      ShowToolTip: "Show",
      textType: "text",
      toastCopyOptions: {
        // you can pass a single action as below
        action: {
          text: "Ok",
          onClick: (e, toastObject) => {
            toastObject.goAway(0);
          },
        },
        position: "top-center",
        duration: 1000,
        keepOnHover: true,
      },
    };
  },
  methods: {
    show() {
      this.toggleShow = !this.toggleShow;
      if (this.isCard) {
        if (this.toggleShow) {
          this.ShowToolTip = "Hide";
          this.textValueRendered = this.textValueCard;
        } else {
          this.ShowToolTip = "Show";
          this.textValueRendered = this.textValueHidden;
        }
      } else {
        if (this.toggleShow) {
          this.ShowToolTip = "Hide";
          this.textType = "text";
        } else {
          this.ShowToolTip = "Show";
          this.textType = "password";
        }
      }
    },
    async copy(value) {
      let mess = await navigator.clipboard.writeText(value).then(
        function () {
          return "Copied ";
        },
        function (err) {
          return "Async: Could not copy: " + err;
        }
      );
      this.$toasted.show(mess, this.toastCopyOptions);
    },
  },
};
</script>
