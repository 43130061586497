export default {
    data() { return { ivLen: 16 } },
    methods: {

        base64EncodeURL(byteArray) {
            return btoa(Array.from(new Uint8Array(byteArray)).map(val => {
                return String.fromCharCode(val);
            }).join('')).replace(/\+/g, '-').replace(/\//g, '_').replace(/=/g, '');
        },

        base64DecodeURL(b64urlstring) {
            return new Uint8Array(atob(b64urlstring.replace(/-/g, '+').replace(/_/g, '/')).split('').map(val => {
                return val.charCodeAt(0);
            }));
        },

        getEncodedText(text) {
            let enc = new TextEncoder();
            return enc.encode(text);
        },
        getdecodedText(text) {
            let dec = new TextDecoder();
            return dec.decode(text);
        },

        async getPBKDF2KeyMaterial(password) {
            let encPassword = this.getEncodedText(password);

            let keyMaterial = await window.crypto.subtle.importKey(
                "raw",
                encPassword,
                "PBKDF2",
                false,
                ["deriveBits", "deriveKey"]
            );

            return keyMaterial;
        },
        // returns a cryptoKey
        async getKeyUsingPBKDF2(password, salt) {
            let keyMaterial = await this.getPBKDF2KeyMaterial(password);
            //let salt = window.crypto.getRandomValues(new Uint8Array(16));
            salt = this.base64DecodeURL(salt);
            return window.crypto.subtle.deriveKey(
                {
                    "name": "PBKDF2",
                    salt: salt,
                    "iterations": 100000,
                    "hash": "SHA-512"
                },
                keyMaterial,
                { "name": "AES-GCM", "length": 256 },
                true,
                ["encrypt", "decrypt"]
            )
        },
        async encrypt(key, plaintext) {
            let iv = window.crypto.getRandomValues(new Uint8Array(16));
            let textArray = this.getEncodedText(plaintext);
            let encryptedData = await window.crypto.subtle.encrypt(
                {
                    name: "AES-GCM",
                    iv: iv
                },
                key,
                textArray
            ).then(x => {
                var ciphered = this.joinIvAndData(iv, new Uint8Array(x))
                return this.base64EncodeURL(ciphered);
            });
            return encryptedData;

        },
        async encryptArray(key, arrayBuffer) {
            let iv = window.crypto.getRandomValues(new Uint8Array(16));

            let encryptedData = await window.crypto.subtle.encrypt(
                {
                    name: "AES-GCM",
                    iv: iv
                },
                key,
                arrayBuffer
            ).then(x => {
                var ciphered = this.joinIvAndData32(iv, new Uint8Array(x))
                return new Blob([ciphered]);
            });
            return encryptedData;
        },
        async decrypt(key, ciphertext) {
            let buffer = this.base64DecodeURL(ciphertext);
            var parts = this.separateIvFromData(buffer);

            let decryptedArray = await window.crypto.subtle.decrypt(
                {
                    name: "AES-GCM",
                    iv: parts.iv
                },
                key,
                parts.data
            );
            let decryptedText = this.getdecodedText(decryptedArray)
            return decryptedText;
        },
        async decryptblob(key, buffer) {
            var parts = this.separateIvFromData32(buffer);

            let decryptedArray = await window.crypto.subtle.decrypt(
                {
                    name: "AES-GCM",
                    iv: parts.iv
                },
                key,
                parts.data
            );
            return decryptedArray
        },

        joinIvAndData32(iv, data) {
            var buf = new Uint8Array(iv.length + data.length);
            Array.prototype.forEach.call(iv, function (byte, i) {
                buf[i] = byte;
            });
            Array.prototype.forEach.call(data, function (byte, i) {
                buf[16 + i] = byte;
            });
            return buf;
        },

        joinIvAndData(iv, data) {
            var buf = new Uint8Array(iv.length + data.length);
            Array.prototype.forEach.call(iv, function (byte, i) {
                buf[i] = byte;
            });
            Array.prototype.forEach.call(data, function (byte, i) {
                buf[16 + i] = byte;
            });
            return buf;
        },
        separateIvFromData32(buf) {
            var iv = new Uint8Array(16);
            var data = new Uint8Array(buf.length - 16);
            Array.prototype.forEach.call(buf, function (byte, i) {
                if (i < 16) {
                    iv[i] = byte;
                } else {
                    data[i - 16] = byte;
                }
            });
            return { iv: iv, data: data };
        },
        separateIvFromData(buf) {
            var iv = new Uint8Array(16);
            var data = new Uint8Array(buf.length - 16);
            Array.prototype.forEach.call(buf, function (byte, i) {
                if (i < 16) {
                    iv[i] = byte;
                } else {
                    data[i - 16] = byte;
                }
            });
            return { iv: iv, data: data };
        },

        async sha1Hash(text) {
            var buffer = new TextEncoder("utf-8").encode(text);
            let sha1H = await crypto.subtle.digest("SHA-1", buffer).then(
                hash => { return this.hex(hash) });
            return sha1H;
        },
        async sha256Hash(text) {
            var buffer = new TextEncoder("utf-8").encode(text);
            let sha256H = await crypto.subtle.digest("SHA-256", buffer).then(
                hash => { return this.hex(hash) });
            return sha256H;
        },
        async sha512Hash(text) {
            var buffer = new TextEncoder("utf-8").encode(text);
            let sha512H = await crypto.subtle.digest("SHA-512", buffer).then(
                hash => { return this.hex(hash) });
            return sha512H;
        },
        hex(buffer) {
            var hexCodes = [];
            var view = new DataView(buffer);
            for (var i = 0; i < view.byteLength; i += 4) {
                // Using getUint32 reduces the number of iterations needed (we process 4 bytes each time)
                var value = view.getUint32(i)
                // toString(16) will give the hex representation of the number without padding
                var stringValue = value.toString(16)
                // We use concatenation and slice for padding
                var padding = '00000000'
                var paddedValue = (padding + stringValue).slice(-padding.length)
                hexCodes.push(paddedValue);
            }
            // Join all the hex strings into one

            return hexCodes.join("");
        }
    }
}