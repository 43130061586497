<template>
  <v-container fluid>
    <vault
      :loading="loading"
      :currentCredentials="current_credentials"
      :allCredentials="all_credentials"
      :closeDialog="close"
      :closeDeleteDialog="closeDelete"
      :serviceCredentials="true"
      credentialType="serviceaccount"
      @refresh="initialize"
      @save="storeOrDeleteCredentials"
    ></vault>
    <v-dialog v-model="dialogAlert" :max-width="dialogWidth">
      <v-alert :type="alertType">
        <v-row align="center">
          <v-col class="grow">{{ alert_message }}</v-col>
          <v-col class="shrink">
            <v-btn @click="closeAlert">Exit</v-btn>
          </v-col>
        </v-row>
      </v-alert>
    </v-dialog>
  </v-container>
</template>
<script>
import { mapGetters } from "vuex";
import Vault from "@/components/Vault.vue";
import axios from "axios";
export default {
  name: "VaultEnterprise",
  components: {
    vault: Vault,
  },
  computed: {
    ...mapGetters(["StateUser"]),
  },
  created() {
    this.initialize();
  },
  data: () => ({
    loading: false,
    current_credentials: [],
    all_credentials: [],
    close: false,
    closeDelete: false,
    dialogAlert: false,
    dialogWidth: "600px",
    alert_message: "",
    alertType: "",
    base_url_ent: process.env.VUE_APP_API_BASE_URL_ENTERPRISE,
  }),
  methods: {
    async initialize() {
      try {
        this.loading = true;
        var userId = this.StateUser.id;
        let payload = new URLSearchParams();
        payload.append("userId", userId);
        var response = await axios.post(
          this.base_url_ent + "GetCredentialsUsingCertificate"
        );

        if (response !== undefined && response.data !== undefined) {
          response.data.forEach((element) => {
            element.credentials = JSON.parse(element.credentials);
            element.shared_with = JSON.parse(element.shared_with);
          });
          var activeCreds = response.data.filter((x) => x.is_active);
          this.customSort(activeCreds);
          this.current_credentials = activeCreds;
          this.all_credentials = response.data;
          this.loading = false;
        }
      } catch (error) {
        this.loading = false;
        this.alert_message =
          "Error retreiving credentials. Please try again in some time";
        this.alertType = "error";
        this.dialogAlert = true;
      }
    },
    async storeOrDeleteCredentials({ item, isDelete }) {
      try {
        this.loading = true;
        var creds = JSON.stringify(item.credentials);
        if (typeof creds !== "string" || creds === "undefined") {
          alert("Invalid credentials json");
          return false;
        }
        var user_id = this.StateUser.id;
        let payload = new URLSearchParams();
        payload.append("user_id", user_id);
        payload.append("id", item.id);
        payload.append("credentials_id", item.credentials_id);
        payload.append("credentials", creds);
        payload.append("is_active", item.is_active);
        payload.append("version", item.version);
        payload.append("shared_with", JSON.stringify(item.shared_with));
        //epoch time in seconds for azure compatibility
        payload.append("epoch_time", Math.floor(Date.now() / 1000));

        var response = "";

        if (isDelete) {
          response = await axios.post(
            this.base_url_ent + "DeleteCredentialsEp",
            payload
          );
        } else {
          response = await axios.post(
            this.base_url_ent + "StoreCredentialsUsingCertificate",
            payload
          );
        }

        if (response) {
          this.alert_message = response.data.message;
          this.alertType = "success";
          this.dialogAlert = true;
        }
        this.loading = false;
        if (isDelete) {
          this.closeDelete = !this.closeDelete;
        } else {
          this.close = !this.close;
        }
      } catch (error) {
        this.loading = false;
        this.alert_message = "Error during save/delete" + "| " + error;
        this.alertType = "error";
        this.close = !this.close;
        this.dialogAlert = true;
      }
    },
    closeAlert() {
      this.dialogAlert = false;
      this.$nextTick(() => {
        this.alert_message = "";
      });
    },
    customSort(itemsArray) {
      itemsArray.sort(function (a, b) {
        var x = a.credentials.app_name.toLowerCase();
        var y = b.credentials.app_name.toLowerCase();
        if (x < y) {
          return -1;
        }
        if (x > y) {
          return 1;
        }
        return 0;
      });
    },
  },
};
</script>
