<template>
  <!-- <v-fade-transition mode="out-in"> -->
  <router-view />
  <!-- </v-fade-transition> -->
</template>

<script>
// @ is an alias to /src

// import { mapGetters } from "vuex";
export default {
  metaInfo: {
    title: "App",
    titleTemplate: "%s | FirstVault",
    htmlAttrs: { lang: "en" },
    meta: [
      { charset: "utf-8" },
      { name: "viewport", content: "width=device-width, initial-scale=1" },
    ],
  },
};
</script>
