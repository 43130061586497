<template>
  <div>
    <v-container fluid>
      <br />
      <br />
      <br />
      <br />
      <v-row justify="center">
        <v-col cols="12" sm="12" md="4" lg="4" xl="4">
          <!-- LOGIN FORM-->
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-card elevation="6" outlined tile :loading="loading">
              <template slot="progress">
                <v-progress-linear
                  color="deep-purple"
                  height="10"
                  indeterminate
                ></v-progress-linear>
              </template>
              <v-card-text>
                <v-text-field
                  v-model="user.email_id"
                  label="E-mail"
                  :rules="emailRules"
                  required
                >
                </v-text-field>
                <custom-password
                  :passwordRule="passwordRule"
                  @updatePassword="UpdatePassword($event)"
                ></custom-password>
              </v-card-text>
              <v-card-actions>
                <v-row>
                  <v-col cols="6">
                    <v-btn
                      :disabled="!valid"
                      block
                      color="#000000"
                      class="white--text"
                      @click="LoginUser"
                    >
                      Log In
                    </v-btn>
                  </v-col>
                  <!-- REGISTRATION FORM FOLLOWS. NOT NEEDED NOW -->
                  <v-col cols="6">
                    <v-btn block color="#bdbdbd" to="/pricing">
                      New User? Sign Up
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-actions>
            </v-card>
          </v-form>
        </v-col>
      </v-row>
      <v-dialog v-model="dialogAlert" :max-width="dialogWidth">
        <v-alert :type="alertType">
          <v-row align="center">
            <v-col class="grow">{{ alert_message }}</v-col>
            <v-col class="shrink">
              <v-btn @click="closeAlert">Exit</v-btn>
            </v-col>
          </v-row>
        </v-alert>
      </v-dialog>
      <v-dialog v-model="dialogTerms" :max-width="dialogWidth" persistent>
        <v-card elevation="3" outlined tile>
          <v-card-subtitle class="black white--text" align="center">
            <v-row>
              <h4>
                <span>
                  By signing up you agree to our
                  <a @click="dialogViewTerms = true"> Terms of Service </a>

                  and
                  <a @click="dialogViewPolicy = true"> Privacy Policy </a>
                </span>
              </h4>
            </v-row>
          </v-card-subtitle>
          <v-card-actions>
            <v-btn text @click="RegisterNewUser">
              <strong>Accept</strong>
            </v-btn>
            <v-btn text @click="dialogTerms = false">
              <strong>Reject</strong>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog
        v-model="dialogViewTerms"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
      >
        <v-card tile outlined elevation="3">
          <v-system-bar height="40" dark color="#000000" app>
            <v-spacer></v-spacer>
            <v-btn dark large icon @click="dialogViewTerms = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-system-bar>
          <terms></terms>
        </v-card>
      </v-dialog>
      <v-dialog
        v-model="dialogViewPolicy"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
      >
        <v-card tile outlined elevation="3">
          <v-system-bar height="40" dark color="#000000" app>
            <v-spacer></v-spacer>
            <v-btn dark icon @click="dialogViewPolicy = false">
              <v-icon large>mdi-close</v-icon>
            </v-btn>
          </v-system-bar>
          <policy></policy>
        </v-card>
      </v-dialog>
    </v-container>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import axios from "axios";
//import QrCode from "../components/QrCode.vue";
import cryptoHelper from "../services/webcrypto.js";
import Terms from "../components/Terms.vue";
import Policy from "../components/PrivacyPolicy.vue";
import CustomPassword from "../components/Custom/CustomPassword.vue";
export default {
  name: "Login",
  components: {
    // qrcode: QrCode,
    terms: Terms,
    policy: Policy,
    "custom-password": CustomPassword,
  },
  props: {
    SubscriptionType: { type: String, default: "Basic" },
  },
  data() {
    return {
      dialog: false,
      valid: true,
      validNew: true,
      loading: false,
      SubscriptionTypes: ["Basic", "Premium", "Platinum"],
      dialogWidth: "600px",
      alertType: "",
      dialogAlert: false,
      dialogViewPolicy: false,
      dialogViewTerms: false,
      dialogTerms: false,
      alert_message: "",
      cardTitleRegistration: "Sign Up! It's quick and easy.",
      mfa_uri: "No Uri Retreived",
      showMfa: false,
      showRegistrationForm: false,
      showDialogVerifyCode: false,
      radioGroupValue: 2,
      passwordRule: [
        (v) => !!v || "Required",
        (v) =>
          /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z])[a-zA-Z0-9!@#$%^&*]{15,50}$/.test(
            v
          ) || "Password criteria do not match",
      ],
      validatePasswordRule: [
        (v) => !!v || "Required",
        (v) => (v && v === this.user.password) || "password do not match",
      ],
      nameRules: [
        (v) => !!v || "Required",
        (v) => (v && v.length <= 50) || "Must be less than 50 characters",
      ],
      numberRules: [
        (v) => !!v || "Required",
        (v) => /^[0-9]+$/.test(v) || "only numbers are allowed",
        (v) => (v && v.length == 10) || "Must be 10 digit mobile number",
      ],
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],
    };
  },
  mixins: [cryptoHelper],
  computed: {
    ...mapGetters([
      "StateUser",
      "StateAuthenticated",
      "StateAuthenticationMessage",
      "StateEnterprise",
    ]),
    mfaDisabled: function () {
      if (!this.user.email_id) return true;
      return false;
    },
    user: function () {
      return this.StateUser;
    },
    facebookAuthUrl: function () {
      return process.env.VUE_APP_API_AUTH_URL + "Auth/ReAuthorizeUrl/facebook";
    },
    googleAuthUrl: function () {
      return process.env.VUE_APP_API_AUTH_URL + "Auth/ReAuthorizeUrl/google";
    },
    authCallbackUrl: function () {
      //console.log(process.env.VUE_APP_API_AUTH_URL);
      return process.env.VUE_APP_API_AUTH_URL + "Access/Callback";
    },
  },
  created() {
    //console.log("created");
    var params = new URLSearchParams(window.location.search);
    var code = params.get("code");
    var state = params.get("state");
    if (code !== null) {
      this.GetAuthenticationData(code, state);
    }
  },
  methods: {
    ...mapActions(["LogIn", "MutatePassword", "MutateCryptoKey"]),
    Authenticate(authUrl) {
      this.loading = true;
      axios.get(authUrl).then(function (response) {
        if (response !== undefined && response.data !== undefined) {
          window.location.replace(response.data);
        }
      });
    },
    async RegisterNewUser() {
      try {
        this.dialogTerms = false;
        if (this.$refs.formNew.validate()) {
          this.validNew = false;
          this.loading = true;
          let hashedPass = await this.sha512Hash(this.user.password);
          const NewUser = new URLSearchParams();
          NewUser.append("first_name", this.user.first_name);
          NewUser.append("last_name", this.user.last_name);
          NewUser.append("mobile_number", this.user.mobile_number);
          NewUser.append("email_id", this.user.email_id);
          NewUser.append("password", hashedPass);
          NewUser.append("authentication_app", this.user.authentication_app);
          NewUser.append(
            "authentication_app_id",
            this.user.authentication_app_id
          );
          NewUser.append("picture", this.user.picture);
          NewUser.append("email_verified", this.user.email_verified);
          NewUser.append("locale", this.user.locale);
          NewUser.append("enable_mfa", this.user.enable_mfa);
          NewUser.append("mfa_key", this.user.mfa_key);
          let responseData = await axios
            .post("Register", NewUser)
            .then(function (response) {
              if (response !== undefined && response.data !== undefined) {
                return response.data; //response.data.message_type + "! " + response.data.message;
              }
            });
          this.cardTitleRegistration =
            responseData.message_type + "! " + responseData.message;
          if (this.user.enable_mfa) {
            this.mfa_uri = responseData.mfa_uri;
          }
          this.validNew = true;
          this.loading = false;
        }
      } catch (error) {
        this.validNew = true;
        this.cardTitleRegistration = error;
        this.loading = false;
      }
    },
    async LoginUser() {
      try {
        if (this.$refs.form.validate()) {
          this.valid = false;
          this.loading = true;
          var testPass = this.user.password;
          let hashedPass = await this.sha512Hash(testPass);
          const User = new URLSearchParams();
          User.append("email_id", this.user.email_id);
          User.append("password", hashedPass);

          let responseData = await axios
            .post("Login", User)
            .then(function (response) {
              if (response !== undefined && response.data !== undefined)
                return response.data;
              else
                return {
                  password_verified: false,
                  salt: "",
                  message_type: "Error",
                  message: "Services appears to be down",
                };
            });
          this.valid = true;
          //Set User State
          await this.LogIn(responseData);
          // if user has enabled for MFA then go for 2FA
          if (responseData.userProfile.enable_mfa) {
            this.$router.push("/verifycode");
          }
          //else log user in
          else this.Login();
        }
      } catch (error) {
        this.valid = true;
        this.loading = false;
        this.alert_message =
          "Fatal Error Occured. Please retry after sometime. " + error;
        this.alertType = "error";
        this.dialogAlert = true;
      }
    },
    async Login() {
      if (this.StateAuthenticated) {
        await this.setCryptoKey();
        await this.MutatePassword();
        this.loading = false;
        this.$router.push("/vault");
      } else {
        this.alert_message = this.StateAuthenticationMessage;
        this.alertType = "error";
        this.dialogAlert = true;
      }
    },
    async ShowMFADialog() {
      try {
        this.loading = true;
        const NewUser = new URLSearchParams();
        NewUser.append("email_id", this.user.email_id);
        let response = await axios
          .post("GetQrUri", NewUser)
          .then(function (response) {
            if (response !== undefined && response.data !== undefined) {
              return response.data;
            }
          });
        this.mfa_uri = response.mfa_uri;
        this.user.mfa_key = response.userProfile.mfa_key;
        this.loading = false;
        this.showMfa = true;
      } catch (error) {
        this.cardTitleRegistration = error;
        this.loading = false;
      }
    },
    async onQrCodeSubmit(verificationCode) {
      this.loading = true;
      const code = new URLSearchParams();
      code.append("verification_code", verificationCode);
      code.append("mfa_key", this.user.mfa_key);
      try {
        let response = await axios
          .post("ValidateRegistrationOtp", code)
          .then(function (response) {
            if (response !== undefined) {
              return response.data;
            }
          });
        if (response === true) {
          this.cardTitleRegistration = "OTP Validated. please continue";
          this.showMfa = false;
          this.loading = false;
        } else {
          this.cardTitleRegistration =
            "Error validating OTP. Please try again or enable later";
          this.onCloseQrDialog();
          this.loading = false;
        }
      } catch (error) {
        this.cardTitleRegistration =
          "Error in verifying OTP. please try again or enable later";
        this.onCloseQrDialog();
        this.user.mfa_key = "";
        this.loading = false;
      }
    },
    onCloseQrDialog() {
      this.mfa_uri = "";
      this.showMfa = false;
      this.user.enable_mfa = false;
    },
    async GetAuthenticationData(code, state) {
      this.dialog = true;
      this.loading = true;
      let resMessage = await axios
        .get(this.authCallbackUrl, {
          params: {
            code: code,
            state: state,
          },
        })
        .then(function (response) {
          return response.data;
        });
      this.user.email_id = resMessage.email_id;
      this.user.first_name = resMessage.first_name;
      this.user.last_name = resMessage.last_name;
      this.user.authentication_app_id = resMessage.authentication_app_id;
      this.user.authentication_app = resMessage.authentication_app;
      this.user.picture = resMessage.picture;
      this.user.email_verified = resMessage.email_verified;
      this.user.locale = resMessage.locale;
      this.loading = false;
      this.showRegistrationForm = true;
    },
    closeDialog() {
      this.cardTitleRegistration = "Sign Up! It's quick and easy.";
      this.loading = false;
      this.dialog = false;
      this.showRegistrationForm = false;
      this.$router.push("/login");
      //window.location.search = "";
    },
    async setCryptoKey() {
      let cryptokey = await this.getKeyUsingPBKDF2(
        this.user.password,
        this.StateUser.salt
      );
      await this.MutateCryptoKey(cryptokey);
    },
    closeAlert() {
      this.dialogAlert = false;
      this.loading = false;
      this.$nextTick(() => {
        this.alert_message = "";
      });
    },
    UpdatePassword(updatedPassword) {
      this.user.password = updatedPassword;
    },
  },
};
</script>
<style scoped>
.centerAlign {
  margin: auto;
  width: 50%;
  padding: 10px;
}
</style>