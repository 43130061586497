<template>
  <v-card :loading="loading">
    <v-container>
      <v-card-title
        ><h3>
          Breaches your email appeared in: {{ breachCount }}
        </h3></v-card-title
      >
      <v-card-subtitle align="left"
        ><h4>
          A "breach" is an incident where data has been unintentionally exposed
          to the public. Using the First Vault password manager helps you ensure
          all your passwords are strong and unique such that a breach of one
          service doesn't put your other services at risk.
        </h4>
        <br />
        <h4 v-if="breachCount > 0" class="red--text">
          We recommend you immediately change password of accounts appearing in
          below list and update your wallet accordingly (You can generate
          cryptographically save passwords from Password Wallet).
        </h4>
      </v-card-subtitle>

      <v-row dense>
        <v-col v-for="(item, i) in items" :key="i" cols="12">
          <v-card :color="color">
            <div class="d-flex flex-no-wrap">
              <v-avatar class="ma-3" size="100" tile>
                <v-img :src="item.LogoPath"></v-img>
              </v-avatar>
              <div>
                <v-card-title align="left">
                  <h4>{{ item.Title }}</h4>
                </v-card-title>
                <v-card-subtitle
                  v-html="formatData(item.Description)"
                  align="left"
                ></v-card-subtitle>
                <v-card-text
                  v-html="compromisedData(item.DataClasses)"
                  align="left"
                >
                </v-card-text>
              </div>
            </div>
          </v-card>
        </v-col>
      </v-row>

      <v-card-actions>
        <a
          target="_blank"
          href="https://haveibeenpwned.com"
          rel="noopener"
          align="right"
          ><h6>Courtsey: Have I Been Pwned</h6>
        </a>
      </v-card-actions>
    </v-container>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";
export default {
  name: "AmIPwned",
  computed: {
    ...mapGetters(["StateUser"]),
    breachCount() {
      return this.items.length;
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    compromisedData(val) {
      var title = "Compromised Data:".bold();
      return title + val.join(",");
    },
    formatData(dataValue) {
      return '<a class="my-font">' + dataValue + "</a>";
    },
    async initialize() {
      try {
        if (this.StateUser.id !== "") {
          this.loading = true;
          var url = "GetPwnedSitesForUser/" + this.StateUser.email_id;

          var data = await axios.get(url).then((resp) => {
            if (resp && resp.data) return resp.data;
          });
          this.items = data;
          this.loading = false;
        }
      } catch {
        this.loading = false;
      }
    },
  },
  data: () => ({
    color: "#EFEBE9",
    loading: false,
    items: [],
  }),
};
</script>
<style scoped>
div >>> .my-font {
  font-family: "Roboto", sans-serif;
  color: #263238;
}
</style>
