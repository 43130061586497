<template>
  <div>
    <v-text-field
      v-model="password"
      label="Password"
      :type="textType"
      required
      :rules="passwordRule"
      @change="passwordChanged"
    >
      <template v-slot:append-outer> </template>
      <template v-slot:append>
        <span v-if="toggleShow">
          <v-icon small @click="show">mdi-eye</v-icon>
        </span>
        <span v-else>
          <v-icon small @click="show">mdi-eye-off</v-icon>
        </span>
        <v-divider vertical class="mx-1"></v-divider>
        <v-tooltip bottom color="white">
          <template v-slot:activator="{ on, attrs }">
            <v-icon small v-bind="attrs" v-on="on">
              mdi-information-outline
            </v-icon>
          </template>
          <p class="my-0 py-0">
            Password criteria:
            <br />> Password must contain following At least one numeric digit
            (0-9). <br />> At least one special character from ! @ # $ % ^ & *
            <br />> At least one lower (a-z) case letter. <br />> At least one
            upper (A-Z) case letter. <br />> Length must be between 15 to 50
            characters
          </p>
        </v-tooltip>
      </template>
    </v-text-field>

    <p v-if="password && !validcontainsNumber" class="my-0 py-0">
      > Password must contain at least one numeric digit (0-9)
    </p>
    <p v-if="password && !validcontainsSpecial" class="my-0 py-0">
      > Password must contain atleast one special character from ! @ # $ % ^ & *
    </p>
    <p v-if="password && !validcontainsLowercase" class="my-0 py-0">
      > Passwordmust contain At least one lower (a-z) case letter.
    </p>
    <p v-if="password && !validUpperCase" class="my-0 py-0">
      > Password must contain At least one upper (A-Z) case letter.
    </p>
    <p v-if="password && !minLength" class="my-0 py-0">
      > Password must be minimum 15 characters
    </p>
    <p v-if="password && !maxLength" class="my-0 py-0">
      > Password must be maximum 50 characters
    </p>
  </div>
</template>

<script>
export default {
  props: {
    passwordRule: { type: Array, default: () => [] },
  },
  computed: {
    validUpperCase: function () {
      const containsUppercase = /[A-Z]/.test(this.password);
      return containsUppercase;
    },
    validcontainsLowercase: function () {
      const containsLowercase = /[a-z]/.test(this.password);
      return containsLowercase;
    },
    validcontainsNumber: function () {
      const containsNumber = /[0-9]/.test(this.password);
      return containsNumber;
    },
    validcontainsSpecial: function () {
      const containsSpecial = /[#?!@$%^&*-]/.test(this.password);
      return containsSpecial;
    },
    minLength: function () {
      const len = this.password.length;
      if (len < 15) return false;
      else return true;
    },
    maxLength: function () {
      const len = this.password.length;
      if (len > 50) return false;
      else return true;
    },
  },
  data() {
    return {
      toggleShow: false,
      textType: "password",
      password: "",
    };
  },
  methods: {
    show() {
      this.toggleShow = !this.toggleShow;
      if (this.toggleShow) {
        this.textType = "text";
      } else {
        this.textType = "password";
      }
    },
    passwordChanged() {
      this.$emit("updatePassword", this.password);
    },
  },
};
</script>
<style scoped>
p {
  text-align: left;
  color: red;
  font-size: 0.675rem;
  font-family: Roboto;
  font-weight: 500;
  letter-spacing: 0.0071428571em;
}
</style>
