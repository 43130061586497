<template>
  <v-theme-provider :dark="dark">
    <div>
      <base-img
        :src="require('@/assets/firstvaultgreybg.png')"
        contain
        max-width="128"
        min-height="68"
        width="100%"
      />

      <base-title size="text-body-1" space="4" weight="regular" />

      <base-body class="">
        FirstVault is a 'Zero-Trust, Zero-Knowledge' data privacy solutions
        company which provides Enterprises and Individuals to effectively fulfil
        their Data privacy and security needs through easy-to-use customizable
        solutions. FirstVault's Enterprise data privacy vault enables companies
        and application to quickly attain finest data privacy and security
        standards via a simple and elegant API. FirstVault aims to radically
        transform the way companies handle their user's financial and other
        personal data without having to develop their own infrastructure from
        the scratch.
      </base-body>

      <!-- <base-btn
        class="mb-12"
        color="white"
        outlined
      >
        More Info
      </base-btn> -->
    </div>
  </v-theme-provider>
</template>

<script>
export default {
  name: "BaseInfo",

  props: {
    title: String,
    dark: Boolean,
  },

  data: () => ({
    business: [
      {
        icon: "mdi-map-marker-outline",
        title: "Address",
        text: "603, GM IT Park, Sector 142, Noida, Uttar Pradesh 201305",
      },
      {
        icon: "mdi-cellphone",
        title: "Phone",
        text: "01 (800) 433 744<br>01 (800) 433 633",
      },
      {
        icon: "mdi-email",
        title: "Email",
        text: "john@vuetifyjs.com<br>heather@vuetifyjs.com",
      },
    ],
  }),
};
</script>
<style scoped>
</style>
