import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import router from './router'
import store from './store';
import axios from 'axios';
import Toasted from 'vue-toasted';
import './plugins'

//Plugins
Vue.use(Toasted)
Vue.config.productionTip = false

window.onunload = () => {
  localStorage.clear();
}

let toastOptions = {
  // you can pass a single action as below
  action: {
    text: 'Exit',
    onClick: (e, toastObject) => {
      toastObject.goAway(0);
    }
  },
  position: 'top-center',
  duration: 10000,
  keepOnHover: true,
  iconPack: 'mdi',
  type: 'error',
  icon: 'mdi-alert-rhombus'

};

//this will ensure tha cookies are passed by axios.
//this an instruction to Axios to send all requests with credentials such as; authorization headers, TLS client certificates, or cookies
axios.defaults.withCredentials = true
//set default url to your api root url
axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL

// If token is expired, then user need to re-login
// axios.interceptors.response.use(undefined, function (error) {
//   if (error) {
//     const originalRequest = error.config;
//     if (error.response.status === 401 && !originalRequest._retry) {

//         originalRequest._retry = true;
//         store.dispatch('LogOut')
//         return router.push('/login')
//     }
//   }
// });

// Add a request interceptor
axios.interceptors.request.use(function (config) {
  // Do something before request is sent
  return config;
}, function (error) {
  // Do something with request error
  return Promise.reject(error);
});

axios.interceptors.response.use(
  //Success handler: if request suceeded return the server response
  function (response) {
    if (response.data !== undefined && response.data.error_description !== undefined) {
      Vue.toasted.show(response.data.error_description, toastOptions);
      //Promise.reject(response);
    } else {
      return response;
    }
  },
  //Error handler: if request suceeded return the server response
  function (error) {
    // handle error
    if (error.response) {
      Vue.toasted.show(error.response.data.status_code + ": " + error.response.data.error_description, toastOptions);
    } else if (error.resquest) {
      //Vue.toasted.show(error.response, toastOptions);
      Vue.toasted.show("Backend services not respnding... We are working on it." + error, toastOptions);
    } else {
      Vue.toasted.show(error, toastOptions);
    }
  });

const firstvault = new Vue({
  store,
  vuetify,
  router,
  render: h => h(App)
}).$mount('#app')
export { firstvault }