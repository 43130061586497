<template>
  <v-container fluid class="bottomPadding">
    <vault
      :loading="loading"
      :currentCredentials="current_credentials"
      :allCredentials="all_credentials"
      :closeDialog="close"
      :closeDeleteDialog="closeDelete"
      credentialType="password"
      @refresh="refreshItems"
      @save="storeOrDeleteCredentials"
    ></vault>
    <v-dialog v-model="dialogAlert" :max-width="dialogWidth">
      <v-alert :type="alertType">
        <v-row align="center">
          <v-col class="grow">{{ alert_message }}</v-col>
          <v-col class="shrink">
            <v-btn @click="closeAlert">Exit</v-btn>
          </v-col>
        </v-row>
      </v-alert>
    </v-dialog>
  </v-container>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import Vault from "@/components/Vault.vue";
import axios from "axios";
import cryptoHelper from "../services/webcrypto.js";
import common from "../services/common.js";

export default {
  name: "VaultRetail",
  mixins: [cryptoHelper, common],
  components: {
    vault: Vault,
  },
  computed: {
    ...mapGetters([
      "StateUser",
      "StateCryptoKey",
      "StateCryptoKeyInstanceVersion",
      "StateCredentialsRetail",
    ]),
  },
  watch: {
    StateCryptoKeyInstanceVersion: function (newV, oldV) {
      if (oldV !== newV && newV > 1) {
        this.refreshItems();
      }
    },
    StateCredentialsRetail: function (newV, oldV) {
      if (oldV !== newV) {
        this.initialize();
      }
    },
  },
  created() {
    this.refreshItems();
  },
  data: () => ({
    loading: false,
    current_credentials: [],
    all_credentials: [],
    close: false,
    closeDelete: false,
    dialogAlert: false,
    dialogWidth: "600px",
    alert_message: "",
    alertType: "",
  }),
  methods: {
    ...mapActions(["MutateCredentialsRetail"]),
    async initialize() {
      try {
        this.loading = true;
        var credsArray = JSON.parse(this.StateCredentialsRetail);
        var activeCreds = credsArray.filter(
          (x) => x.is_active && x.credential_type === "password"
        );

        this.customSort(activeCreds);
        this.current_credentials = activeCreds;

        this.all_credentials = credsArray.filter(
          (x) => x.credential_type === "password"
        );

        this.loading = false;
      } catch (error) {
        this.loading = false;
        this.alert_message =
          "Error retreiving credentials. Please try again in some time. " +
          error;
        this.alertType = "error";
        this.dialogAlert = true;
      }
    },
    async storeOrDeleteCredentials({ item, isDelete }) {
      try {
        this.loading = true;
        var user_id = this.StateUser.id;
        let payload = new URLSearchParams();
        payload.append("user_id", user_id);
        payload.append("id", item.id);
        payload.append("credential_type", item.credential_type);
        payload.append("credentials_id", item.credentials_id);

        payload.append("is_active", item.is_active);
        payload.append("version", item.version);
        //epoch time in seconds for azure compatibility
        payload.append("epoch_time", Math.floor(Date.now() / 1000));
        payload.append("shared_with", item.shared_with);
        var response = "";
        if (isDelete) {
          response = await axios.post("DeleteCredentials", payload);
        } else {
          // this is only in case of storing the password
          var creds = JSON.stringify(item.credentials);
          if (!this.tryParseJSON(creds)) {
            alert("Invalid credentials json");
            return false;
          }

          var encryptedCreds = await this.encrypt(this.StateCryptoKey, creds);
          payload.append("credentials", encryptedCreds);

          response = await axios.post("StoreCredentialsUsingPBKDF2", payload);
        }
        await this.refreshItems();
        if (response) {
          this.alert_message = response.data.message;
          this.alertType = "success";
          this.dialogAlert = true;
        }
        this.loading = false;
        if (isDelete) {
          this.closeDelete = !this.closeDelete;
        } else {
          this.close = !this.close;
        }
      } catch (error) {
        this.loading = false;
        this.alert_message = "Error during save/delete" + "| " + error;
        this.alertType = "error";
        this.close = !this.close;
        this.dialogAlert = true;
      }
    },
    async refreshItems() {
      try {
        this.loading = true;
        var userId = this.StateUser.id;
        let payload = new URLSearchParams();
        payload.append("userId", userId);
        var response = await axios.post("GetCredentialsUsingPBKDF2", payload);

        if (response !== undefined && response.data !== undefined) {
          if (
            response.data.error_type !== undefined &&
            response.data.error_type === "AuthError"
          ) {
            this.alert_message = response.data.error_message;
            this.alertType = "error";
            this.dialogAlert = true;
            this.loading = false;
            return;
          }

          for (const element of response.data) {
            let decrypted = await this.decrypt(
              this.StateCryptoKey,
              element.credentials
            );
            element.credentials = JSON.parse(decrypted);
          }
          await this.MutateCredentialsRetail(JSON.stringify(response.data));
          await this.initialize();
          this.loading = false;
        }
      } catch (error) {
        this.loading = false;
        this.alert_message =
          "Error retreiving credentials. Please try again in some time. " +
          error;
        this.alertType = "error";
        this.dialogAlert = true;
      }
    },
    closeAlert() {
      this.dialogAlert = false;
      this.$nextTick(() => {
        this.alert_message = "";
      });
    },
    customSort(itemsArray) {
      itemsArray.sort(function (a, b) {
        var x = a.credentials.app_name.toLowerCase();
        var y = b.credentials.app_name.toLowerCase();
        if (x < y) {
          return -1;
        }
        if (x > y) {
          return 1;
        }
        return 0;
      });
    },
  },
};
</script>

<style scoped>
.bottomPadding {
  padding-bottom: 75px;
}
</style>