<script>
// Extensions
import View from "@/views/View";

// Mixins
import LoadSections from "@/mixins/load-sections";

export default {
  name: "Resources",

  metaInfo: { title: "Resources" },

  extends: View,

  mixins: [LoadSections(["space", "resources", "newsletter", "info-alt"])],

  props: {
    id: {
      type: String,
      default: "resources",
    },
  },
};
</script>
